import { ForwardedRef, forwardRef, HTMLAttributes } from "react";
import { cn } from "@/lib/utils";

const NativeSelect = forwardRef(
  (
    { className, children, ...props }: HTMLAttributes<HTMLSelectElement>,
    ref: ForwardedRef<HTMLSelectElement>,
  ) => {
    return (
      <select
        ref={ref}
        className={cn(
          "native-select block h-11 w-full rounded-lg border border-input bg-card px-3 py-2 pr-10 text-base ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className,
        )}
        {...props}
      >
        {children}
      </select>
    );
  },
);

NativeSelect.displayName = "NativeSelect";

export default NativeSelect;
