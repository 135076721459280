import { Table } from "@tanstack/react-table";
import Pagination from "@/components/ui/pagination";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

export default function TablePagination<T>({ table }: { table: Table<T> }) {
  const {
    pagination: { pageIndex, pageSize },
  } = table.getState();

  const totalPages = table.getPageCount();

  return (
    <Pagination
      pageIndex={pageIndex}
      totalPages={totalPages}
      canGetPrevious={table.getCanPreviousPage()}
      onPrevious={table.previousPage}
      canGetNextPage={table.getCanNextPage()}
      onNext={table.nextPage}
    >
      <Select
        value={String(pageSize)}
        onValueChange={(v) =>
          table.setPagination({ pageIndex: 0, pageSize: Number(v) })
        }
      >
        <SelectTrigger className="h-10 text-sm">
          <SelectValue placeholder="Per Page" />
        </SelectTrigger>
        <SelectContent>
          {[10, 25, 50, 100, 250].map((value) => (
            <SelectItem key={value} value={String(value)}>
              <div className="mr-1.5">
                {value}
                <span className="hidden lg:inline"> per page</span>
              </div>
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </Pagination>
  );
}
