import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

export function usePayRequest() {
  return useMutation({
    mutationFn: ({
      invoices,
      amount,
    }: {
      invoices: string[];
      amount?: number;
    }) =>
      axios
        .post<{ url: string }>(`/orders/pay`, {
          return_to: window.location.href.split("?")[0],
          invoices,
          amount,
        })
        .then(({ data }) => data),
    onSuccess: (d) => {
      window.location.href = d.url;
    },
  });
}

export function useChargeRequest() {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: { invoices: number[]; payment_method: string }) =>
      axios
        .post<{ async: boolean }>(`/orders/charge`, payload)
        .then(({ data }) => {
          if (!data.async) {
            queryClient.invalidateQueries(["orders"]);
            queryClient.invalidateQueries(["order"]);
          }
          return data;
        }),
  );
}
