import { useAppState } from "@/context/AppContext";
import { useMemberships } from "@/api/membership";

export const useCanLeave = () => {
  const { user, membership } = useAppState();
  const { data: memberships = [] } = useMemberships();

  return (
    membership.role === "avail" ||
    memberships.some((m) => m.user.id !== user.id && m.role === "admin")
  );
};
