import { Button } from "@/components/ui/button";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useAppContext } from "@/context/AppContext";
import { CardContent, CardFooter } from "@/components/ui/card";
import { Alert } from "@/components/ui/alert";
import AuthRedirect from "@/components/auth/AuthRedirect";
import AlreadyLoggedInRedirect from "@/components/auth/AlreadyLoggedInRedirect";
import CardPage from "@/layouts/CardPage";

export default function VerifyEmail() {
  const context = useAppContext();

  const resendRequest = useMutation(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return axios.post(
      `/email/verification-notification?${searchParams.toString()}`,
    );
  });

  if (!context.user) {
    return <AuthRedirect to="/login" />;
  }

  if (context.user.email_verified_at) {
    return <AlreadyLoggedInRedirect data={context} />;
  }

  return (
    <CardPage
      title="Please verify your email"
      description="We sent you an email with a link to verify your email address. Please click the link in the email to continue."
    >
      {resendRequest.status === "success" && (
        <CardContent>
          <Alert variant="success">
            Successfully resent verification email.
          </Alert>
        </CardContent>
      )}
      <CardFooter className="flex items-center justify-center">
        <Button
          onClick={() => resendRequest.mutate()}
          isLoading={resendRequest.isLoading}
        >
          Resend Email
        </Button>
      </CardFooter>
    </CardPage>
  );
}
