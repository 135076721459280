import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { ReactNode } from "react";

export default function ConfirmationButton({
  children,
  title = "Confirm Delete",
  message = "Are you sure? This action cannot be undone",
  isLoading,
  onClick,
  buttonLabel = "Delete",
  variant = "destructive",
  ...other
}: {
  children: ReactNode;
  className?: string;
  title?: string;
  message?: string;
  isLoading: boolean;
  onClick: () => void;
  buttonLabel?: string;
  variant?:
    | "link"
    | "default"
    | "destructive"
    | "outline"
    | "secondary"
    | "ghost"
    | "success"
    | null
    | undefined;
}) {
  return (
    <AlertDialog>
      <AlertDialogTrigger {...other}>{children}</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{message}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button variant={variant} isLoading={isLoading} onClick={onClick}>
            {buttonLabel}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
