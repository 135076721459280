import { HTMLAttributes, ReactNode } from "react";
import { cn } from "@/lib/utils";

interface ToolbarProps extends HTMLAttributes<HTMLDivElement> {
  actions?: ReactNode;
}

export default function Toolbar({
  children,
  actions,
  className,
  ...other
}: ToolbarProps) {
  return (
    <div
      className={cn(
        "flex flex-wrap items-center justify-between gap-4",
        className,
      )}
      {...other}
    >
      <div className="flex-grow">{children}</div>
      <div className="flex flex-wrap items-center gap-2">{actions}</div>
    </div>
  );
}
