import { useState } from "react";
import { curr } from "@/lib/curr";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Table,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
} from "@/components/ui/table";
import { Loader } from "lucide-react";
import { useGetSageProductDetails, useSelectSageProduct } from "@/api/sage";
import ProductDescription from "@/components/products/ProductDescription";
import ProductImages from "@/components/products/ProductImages";
import { Alert } from "@/components/ui/alert";
import { ProjectOptionPayload } from "avail-types";

export default function SageProductDetails({
  productId,
  projectItemId,
  onSelect,
  setOpen,
}: {
  productId: number;
  projectItemId: number;
  onSelect: (
    payload: ProjectOptionPayload,
    product_type: "custom" | "database",
  ) => void;
  setOpen: (val: boolean) => void;
}) {
  const [selectedColor, setSelectedColor] = useState<string | undefined>();

  const {
    data: selectedProduct,
    isLoading,
    isError,
  } = useGetSageProductDetails(productId);
  const selectSageProduct = useSelectSageProduct();

  const getDecorationMethod = (method: string) => {
    const methodArray = method.split(",").filter((method) => method);
    return methodArray.length > 1 ? methodArray.join(", ") : methodArray[0];
  };

  const getColors = (colors: string) => {
    return colors.split(", ");
  };

  if (isLoading) {
    return <Loader className="m-auto size-10 animate-spin" />;
  }

  if (isError) {
    return <Alert variant="destructive">Error loading product details</Alert>;
  }

  if (!selectedProduct) {
    return null;
  }

  return (
    <Card className="flex flex-grow flex-col overflow-y-auto p-4">
      <div className="grid grid-cols-2 gap-4">
        <ProductImages
          key={selectedProduct.prodEid}
          images={selectedProduct.pics.map((i) => i.url)}
        />
        <div className="flex flex-col">
          <h2 className="mb-2 text-xl font-bold">{selectedProduct.prName}</h2>

          <Table className="w-auto">
            <TableBody>
              <TableRow>
                <TableCell>
                  <Label className="pr-2 pt-1 font-bold">Item #</Label>
                </TableCell>
                <TableCell>
                  <div>{selectedProduct.itemNum}</div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Label className="pr-2 pt-1 font-bold">Supplier</Label>
                </TableCell>
                <TableCell>
                  <div className="flex flex-col">
                    <div>{selectedProduct.supplier.coName}</div>
                    <div>{selectedProduct.supplier.contactName}</div>
                    <div>{selectedProduct.supplier.email}</div>
                    <div>{selectedProduct.supplier.tollFreeTel}</div>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Label className="pr-2 pt-1 font-bold">SPC</Label>
                </TableCell>
                <TableCell>
                  <div>{selectedProduct.spc}</div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <ProductDescription
            description={selectedProduct.description}
            className="mb-6 mt-6 pr-5"
          />

          {getColors(selectedProduct.colors).length ? (
            <div className="w-48">
              <Select
                onValueChange={setSelectedColor}
                value={selectedColor || ""}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select a color" />
                </SelectTrigger>
                <SelectContent>
                  {getColors(selectedProduct.colors).map((color) => (
                    <SelectItem value={color}>{color}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          ) : null}
          <Button
            type="button"
            className="mt-6 w-48"
            onClick={() => {
              selectSageProduct
                .mutateAsync({
                  color: selectedColor || null,
                  prodEId: selectedProduct.prodEid,
                  project_item_id: projectItemId,
                })
                .then((po) => {
                  onSelect(po, "custom");
                  setOpen(false);
                });
            }}
          >
            Choose This Product
          </Button>
        </div>
      </div>

      <div className="flex-start ml-6 flex flex-col pt-6">
        <Label className="text-left text-lg font-bold">Pricing</Label>
        <Table className="w-[90vh]">
          <TableHeader>
            <TableRow>
              <TableHead className="font-bold">Qty</TableHead>
              {selectedProduct.qty
                .filter((qty) => parseFloat(qty))
                .map((qty: string) => (
                  <TableHead>{qty}</TableHead>
                ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell className="font-bold">Price</TableCell>
              {selectedProduct.prc
                .filter((prc) => prc)
                .map((price: string) => (
                  <TableCell>{curr(parseFloat(price))}</TableCell>
                ))}
            </TableRow>
            <TableRow>
              <TableCell className="font-bold">Net Cost</TableCell>
              {selectedProduct.net
                .filter((prc) => prc)
                .map((net: string) => (
                  <TableCell>{curr(parseFloat(net))}</TableCell>
                ))}
            </TableRow>
            <TableRow>
              <TableCell className="font-bold">Profit</TableCell>
              {selectedProduct.prc
                .filter((prc) => prc)
                .map((price: string, idx: number) => (
                  <TableCell>
                    {curr(
                      parseFloat(price) -
                        parseFloat(selectedProduct.net[idx] || "0"),
                    )}
                  </TableCell>
                ))}
            </TableRow>
          </TableBody>
        </Table>
      </div>

      <div className="flex-start ml-6 flex flex-col pt-10">
        <Label className="text-left text-lg font-bold">Details</Label>
        <Table className="w-auto">
          <TableBody>
            <TableRow>
              <TableCell>
                <Label className="pr-2 pt-1 font-bold">Production Time</Label>
              </TableCell>
              <TableCell>
                <div>{selectedProduct.prodTime}</div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Label className="pr-2 pt-1 font-bold">
                  Decoration Options
                </Label>
              </TableCell>
              <TableCell>
                <div>
                  {getDecorationMethod(selectedProduct.decorationMethod)}
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Label className="pr-2 pt-1 font-bold">Shipping</Label>
              </TableCell>
              <TableCell>
                <div>
                  Ships From {selectedProduct.shipPointZip}{" "}
                  {selectedProduct.shipPointCountry}
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Card>
  );
}
