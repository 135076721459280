import { useProject } from "@/context/ProjectContext";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import InviteTeamMemberForm from "@/components/account/InviteTeamMemberForm";

export default function ProjectUserInvite({
  open,
  onClose,
  userQuery,
}: {
  open: boolean;
  onClose: () => void;
  userQuery: string;
}) {
  const project = useProject();

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Invite User To Project</DialogTitle>
        </DialogHeader>
        <InviteTeamMemberForm
          projectId={project.id}
          userQuery={userQuery}
          onInvited={onClose}
        />
      </DialogContent>
    </Dialog>
  );
}
