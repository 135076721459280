import { ExternalLink } from "lucide-react";
import { AllHTMLAttributes } from "react";
import { cn } from "@/lib/utils";

export default function ImageAnchor({
  children,
  target = "_blank",
  className,
  onClick,
  ...other
}: AllHTMLAttributes<HTMLAnchorElement>) {
  return (
    <a
      target={target}
      className={cn("group/imageanchor relative", className)}
      onClick={
        onClick
          ? (e) => {
              e.preventDefault();
              onClick(e);
            }
          : undefined
      }
      {...other}
    >
      {children}
      <div className="absolute inset-0 flex items-center justify-center bg-foreground/20 opacity-0 transition-opacity group-hover/imageanchor:opacity-100">
        <ExternalLink className="size-6 text-white" />
      </div>
    </a>
  );
}
