import { Link } from "react-router-dom";
import { Badge, BadgeProps } from "@/components/ui/badge";
import { Flag, GroupIcon, ShapesIcon, TagIcon, TreesIcon } from "lucide-react";
import { ProjectOption } from "avail-types";
import { useProject } from "@/context/ProjectContext";
import { cn } from "@/lib/utils";

export function Tag({
  Icon,
  label,
  color,
  expanded,
}: {
  Icon: React.ComponentType<{ className?: string }>;
  label: string;
  color: BadgeProps["variant"];
  expanded?: boolean;
}) {
  return (
    <Badge variant={color} className="group/tag space-x-1">
      <Icon className="size-4" />
      <span className={cn(!expanded && "hidden group-hover/tag:inline")}>
        {label}
      </span>
    </Badge>
  );
}

export default function ProjectOptionTags({
  option,
  expanded,
}: {
  option: ProjectOption;
  expanded?: boolean;
}) {
  const { id } = useProject();
  return (
    <>
      {option.tags?.sustainable && (
        <Tag
          Icon={TreesIcon}
          label="Sustainable"
          color="green"
          expanded={expanded}
        />
      )}
      {option.tags?.diverse_supplier && (
        <Tag
          Icon={ShapesIcon}
          label="Diverse Supplier"
          color="purple"
          expanded={expanded}
        />
      )}
      {option.tags?.made_in_usa && (
        <Tag Icon={Flag} label="Made in USA" color="red" expanded={expanded} />
      )}
      {option.tags?.companion && (
        <Tag
          Icon={GroupIcon}
          label="Has Companion Style"
          color="pink"
          expanded={expanded}
        />
      )}
      {option.sample_order && (
        <Link
          to={`/projects/${id}/orders/${option.sample_order.code}`}
          onClick={(e) => e.stopPropagation()}
        >
          <Tag
            Icon={TagIcon}
            color="blue"
            label="Sample Ordered"
            expanded={expanded}
          />
        </Link>
      )}
    </>
  );
}
