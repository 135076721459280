import { ReactNode, useEffect } from "react";
import { useAppContext } from "@/context/AppContext";
import { ALLOWS_SIGNUP } from "@/constants/services";

export default function ThemeProvider({ children }: { children: ReactNode }) {
  const context = useAppContext();

  useEffect(() => {
    if (context.membership && context.settings.color) {
      document.body.style.setProperty(
        "--primary",
        context.settings.color.join(" "),
      );
      document.body.style.setProperty(
        "--primary-foreground",
        context.settings.foreground_color.join(" "),
      );
    } else {
      document.body.style.removeProperty("--primary");
      document.body.style.removeProperty("--primary-foreground");
    }

    if (!ALLOWS_SIGNUP) {
      document.body.style.setProperty("--drawer", "241 245 249");
      document.body.style.setProperty("--drawer-foreground", "17 24 39");
    } else if (context.membership && context.settings.drawer_color) {
      document.body.style.setProperty(
        "--drawer",
        context.settings.drawer_color.join(" "),
      );
      document.body.style.setProperty(
        "--drawer-foreground",
        context.settings.drawer_foreground_color.join(" "),
      );
    } else {
      document.body.style.removeProperty("--drawer");
      document.body.style.removeProperty("--drawer-foreground");
    }
  }, [context]);

  return children;
}
