import { cn } from "@/lib/utils";
import last from "lodash/last";
import SimpleTooltip from "@/components/ui/simple-tooltip";
import { Info } from "lucide-react";

export default function StatusProgress({
  steps,
  currentStatus,
}: {
  steps: { title: string; status: string; description?: string }[];
  currentStatus: string;
}) {
  const currentIndex = steps.findIndex((step) => step.status === currentStatus);

  return (
    <div aria-hidden="true">
      <div className="overflow-hidden rounded-full bg-muted">
        <div
          className="h-2 rounded-full bg-primary"
          style={{
            width:
              currentStatus === last(steps)?.status
                ? "100%"
                : `calc((${currentIndex} * 2 + 1) / ${
                    steps.length * 2
                  } * 100%)`,
          }}
        />
      </div>
      <div
        className="mt-4 hidden text-sm font-medium text-muted-foreground sm:grid"
        style={{
          gridTemplateColumns: `repeat(${steps.length}, minmax(0, 1fr))`,
        }}
      >
        {steps.map((step, index) => (
          <div
            key={step.status}
            className={cn(
              index <= currentIndex ? "text-primary" : "",
              "flex items-center gap-2",
              {
                "justify-start": index === 0,
                "justify-center": index > 0 && index < steps.length - 1,
                "justify-end": index === steps.length - 1,
              },
            )}
          >
            {step.title}
            {step.description && (
              <SimpleTooltip tooltip={step.description}>
                <Info className="size-4" />
              </SimpleTooltip>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
