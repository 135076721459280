import { Outlet } from "react-router-dom";
import BreadcrumbsFromRoutes from "@/layouts/BreadcrumbsFromRoutes";

export default function WithBreadcrumbs({
  homeHref = "/",
}: {
  homeHref?: string;
}) {
  return (
    <div className="flex-1">
      <BreadcrumbsFromRoutes homeHref={homeHref} />
      <Outlet />
    </div>
  );
}
