import { useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { AppState, EmptyState, NoCustomerState } from "@/context/AppContext";

export function getCustomerId(): string | null {
  return (
    new URLSearchParams(window.location.search).get("customer_id") ||
    window.localStorage.getItem("customer_id")
  );
}

export function setCustomerId(customerId: string) {
  localStorage.setItem("customer_id", customerId);
  axios.defaults.headers.common["X-Customer-ID"] = customerId;
}

export function initializeCustomerId() {
  const customerId = getCustomerId();
  if (customerId) {
    setCustomerId(customerId);
  }
}

export function getMe() {
  return axios
    .get<AppState | NoCustomerState | EmptyState>("/me")
    .then(({ data }) => data)
    .catch(() => ({
      user: null,
      membership: null,
      countries: [],
    }));
}

export function getUrlWithExistingSearch(
  path: string,
  params: Record<string, string> = {},
) {
  const searchParams = new URLSearchParams(window.location.search);
  Object.entries(params).forEach(([key, value]) => {
    searchParams.set(key, value);
  });
  return `${path}?${searchParams.toString()}`;
}

export function getUrlForAuthRedirect(path: string) {
  return getUrlWithExistingSearch(path, {
    redirect_to: window.location.pathname,
  });
}

export function useAfterLogin() {
  const queryClient = useQueryClient();

  return useCallback(
    () =>
      getMe().then((data) => {
        queryClient.setQueryData(["me"], data);
      }),
    [queryClient],
  );
}

export function useAfterLogOut() {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.setQueryData<EmptyState>(["me"], {
      user: null,
      membership: null,
      countries: [],
    });
    queryClient.invalidateQueries();
  }, [queryClient]);
}
