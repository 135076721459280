import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";
import Dashboard from "@/pages/Dashboard";
import Orders from "@/pages/Orders";
import Projects from "@/pages/Projects";
import { useQuery } from "@tanstack/react-query";
import AppContext from "@/context/AppContext";
import Webstores from "@/pages/Webstores";
import Closet from "@/pages/Closet";
import Collections from "@/pages/Collections";
import ProjectDetail from "@/pages/ProjectDetail";
import ProjectCreate from "@/pages/ProjectCreate";
import OrderDetail from "@/pages/OrderDetail";
import Account from "@/pages/Account";
import Login from "@/pages/Login";
import Register from "@/pages/Register";
import ForgotPassword from "@/pages/ForgotPassword";
import ResetPassword from "@/pages/ResetPassword";
import SplashScreen from "@/pages/SplashScreen";
import VerifyEmail from "@/components/auth/VerifyEmail";
import Approval from "@/pages/Approval";
import Inventory from "@/pages/Inventory";
import GuestRoot from "@/layouts/GuestRoot";
import AppRoot from "@/layouts/AppRoot";
import FindOrder from "@/pages/FindOrder";
import FulfillmentOrders from "@/pages/FulfillmentOrders";
import InventoryReturns from "@/pages/InventoryReturns";
import FulfillmentOrderCreate from "@/pages/FulfillmentOrderCreate";
import Shop from "@/pages/Shop";
import ProductDetail from "@/pages/ProductDetail";
import ShopProducts from "@/components/shop/ShopProducts";
import ShopDecoration from "@/components/shop/ShopDecoration";
import ShopVariants from "@/components/shop/ShopVariants";
import ResumeShopping from "@/components/shop/ResumeShopping";
import ShopCheckout from "@/components/shop/ShopCheckout";
import Onboarding from "@/pages/Onboarding";
import ThemeProvider from "@/ThemeProvider";
import ProjectItemEdit from "@/components/projects/ProjectItemEdit";
import RedirectIfGuest from "@/components/roles/RedirectIfGuest";
import { getMe } from "@/lib/auth";
import LaravelEcho from "laravel-echo";
import axios from "axios";
import memoize from "lodash/memoize";
import { useEffect, useState } from "react";
import "yet-another-react-lightbox/styles.css";
import FulfillmentOrderDetail from "@/pages/FulfillmentOrderDetail";
import InventoryReturnDetail from "@/pages/InventoryReturnDetail";
import CollectionDetail from "@/pages/CollectionDetail";
import WithBreadcrumbs from "@/layouts/WithBreadcrumbs";
import ProjectBreadcrumb from "@/components/projects/ProjectBreadcrumb";
import FulfillmentOrderBreadcrumb from "@/components/fulfillmentOrders/FulfillmentOrderBreadcrumb";
import InventoryReturnBreadcrumb from "@/components/inventory/InventoryReturnBreadcrumb";
import OrderBreadcrumb from "@/components/orders/OrderBreadcrumb";
import ApprovalBreadcrumb from "@/components/approvals/ApprovalBreadcrumb";
import CollectionBreadcrumb from "@/components/collections/CollectionBreadcrumb";
import ProductBreadcrumb from "@/components/products/ProductBreadcrumb";
import * as Sentry from "@sentry/react";
import ErrorBoundary from "@/ErrorBoundary";
import trimEnd from "lodash/trimEnd";
import { identify } from "@/lib/analytics";
import ProjectSubmit from "@/pages/ProjectSubmit";
import ProjectCreateLanding from "@/pages/ProjectCreateLanding";
import GuestErrorRoot from "@/layouts/GuestErrorRoot";
import AddToCartForm from "@/components/cart/AddToCartForm";
import ProjectCheckout from "@/pages/ProjectCheckout";
import Invoices from "@/pages/Invoices";
import ChooseProductButton from "@/components/shop/ChooseProductButton";
import CollectionWrapper from "@/pages/CollectionWrapper";
import AdSpecialityAddToCartForm from "@/components/cart/AdSpecialityAddToCartForm";
import AppLayout from "@/layouts/AppLayout";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { QueryParamProvider } from "use-query-params";
import ProjectLayout from "@/components/projects/ProjectLayout";
import QuoteDetail from "@/pages/QuoteDetail";
import { ALLOWS_SIGNUP } from "@/constants/services";
import * as Frigade from "@frigade/react";
import pick from "lodash/pick";
import Brand from "@/pages/Brand";
import WebstoreCreate from "@/pages/WebstoreCreate";

function Index() {
  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <ScrollRestoration />
      <Outlet />
    </QueryParamProvider>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
      {
        path: "/verify-email",
        element: <VerifyEmail />,
      },
      {
        path: "/guest/:business",
        element: <FindOrder />,
        errorElement: <GuestErrorRoot />,
      },
      {
        path: "/onboarding",
        element: <Onboarding />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/guest/",
        element: <GuestRoot />,
        errorElement: <GuestErrorRoot />,
        children: [
          {
            path: "approvals/:code",
            element: <Approval />,
          },
          {
            path: "orders/:code",
            element: <OrderDetail />,
          },
          {
            path: "quotes/:code",
            element: <QuoteDetail />,
          },
        ],
      },
      {
        path: "/create-project",
        element: <ProjectCreateLanding />,
        errorElement: <GuestErrorRoot />,
      },
      {
        path: "/projects/create",
        element: <ProjectCreate />,
        errorElement: <GuestErrorRoot />,
      },
      {
        path: "/webstores/create",
        element: <WebstoreCreate />,
        errorElement: <GuestErrorRoot />,
      },
      {
        path: "/projects/checkout",
        element: <ProjectCheckout />,
        errorElement: <GuestErrorRoot />,
      },
      {
        path: "/collections",
        element: <CollectionWrapper />,
        errorElement: <GuestErrorRoot />,
        children: [
          {
            index: true,
            element: <Collections />,
          },
          {
            path: ":collection",
            handle: {
              crumb: <CollectionBreadcrumb />,
            },
            children: [
              {
                index: true,
                element: <CollectionDetail />,
              },
              {
                path: ":product",
                handle: {
                  crumb: <ProductBreadcrumb />,
                },
                element: (
                  <ProductDetail>
                    {(p) =>
                      p.decoration_method === "ad_specialty" ? (
                        <AdSpecialityAddToCartForm product={p} />
                      ) : (
                        <AddToCartForm product={p} />
                      )
                    }
                  </ProductDetail>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "/",
        element: <AppRoot />,
        errorElement: (
          <AppLayout>
            <ErrorBoundary />
          </AppLayout>
        ),
        children: [
          {
            path: "/",
            element: <Dashboard />,
          },
          {
            path: "quotes/:code",
            element: <QuoteDetail />,
          },
          {
            path: "orders",
            element: <WithBreadcrumbs />,
            handle: {
              crumb: "Orders",
            },
            children: [
              {
                index: true,
                element: (
                  <RedirectIfGuest>
                    <Orders />
                  </RedirectIfGuest>
                ),
              },
              {
                path: ":code",
                element: <OrderDetail />,
              },
            ],
          },
          {
            path: "invoices",
            element: <WithBreadcrumbs />,
            handle: {
              crumb: "Invoices",
            },
            children: [
              {
                index: true,
                element: (
                  <RedirectIfGuest>
                    <Invoices />
                  </RedirectIfGuest>
                ),
              },
              {
                path: ":code",
                handle: {
                  crumb: <OrderBreadcrumb />,
                },
                element: <OrderDetail />,
              },
            ],
          },
          {
            path: "projects",
            element: <WithBreadcrumbs />,
            handle: {
              crumb: "Projects",
            },
            children: [
              {
                index: true,
                element: <Projects />,
              },
              { path: "submit", element: <ProjectSubmit /> },
              {
                path: ":id",
                element: <ProjectLayout />,
                handle: {
                  crumb: <ProjectBreadcrumb />,
                },
                children: [
                  {
                    index: true,
                    element: <ProjectDetail />,
                    handle: {
                      collapsed: true,
                    },
                  },
                  {
                    path: "add-item",
                    handle: {
                      crumb: "Add Category",
                    },
                    element: <ProjectItemEdit />,
                  },
                  {
                    path: "edit-item/:item",
                    handle: {
                      crumb: "Edit Category",
                    },
                    element: <ProjectItemEdit />,
                  },
                  {
                    path: "orders/:code",
                    handle: {
                      crumb: <OrderBreadcrumb />,
                    },
                    element: <OrderDetail />,
                  },
                  {
                    path: "approvals/:code",
                    handle: {
                      crumb: <ApprovalBreadcrumb />,
                    },
                    element: <Approval />,
                  },
                ],
              },
            ],
          },
          {
            path: "/webstores",
            element: (
              <RedirectIfGuest>
                <Webstores />
              </RedirectIfGuest>
            ),
          },
          {
            path: "/send",
            element: (
              <RedirectIfGuest>
                <FulfillmentOrderCreate />
              </RedirectIfGuest>
            ),
          },
          {
            path: "fulfillment-orders",
            element: <WithBreadcrumbs />,
            handle: {
              crumb: "Fulfillment Orders",
            },
            children: [
              {
                index: true,
                element: (
                  <RedirectIfGuest>
                    <FulfillmentOrders />
                  </RedirectIfGuest>
                ),
              },
              {
                path: ":id",
                handle: {
                  crumb: <FulfillmentOrderBreadcrumb />,
                },
                element: (
                  <RedirectIfGuest>
                    <FulfillmentOrderDetail />
                  </RedirectIfGuest>
                ),
              },
            ],
          },
          {
            path: "inventory-returns",
            element: <WithBreadcrumbs />,
            handle: {
              crumb: "Inventory Returns",
            },
            children: [
              {
                index: true,
                element: (
                  <RedirectIfGuest>
                    <InventoryReturns />
                  </RedirectIfGuest>
                ),
              },
              {
                path: ":id",
                handle: {
                  crumb: <InventoryReturnBreadcrumb />,
                },
                element: (
                  <RedirectIfGuest>
                    <InventoryReturnDetail />
                  </RedirectIfGuest>
                ),
              },
            ],
          },
          {
            path: "/closet",
            element: <Closet />,
          },
          {
            path: "/brand",
            element: (
              <RedirectIfGuest>
                <Brand />
              </RedirectIfGuest>
            ),
          },
          {
            path: "/account",
            element: <Account />,
          },
          {
            path: "/inventory",
            element: (
              <RedirectIfGuest>
                <Inventory />
              </RedirectIfGuest>
            ),
          },
          {
            path: "/shop/",
            element: (
              <RedirectIfGuest>
                <Shop />
              </RedirectIfGuest>
            ),
            children: [
              {
                path: "",
                element: <ResumeShopping />,
              },
              {
                path: "products",
                element: <WithBreadcrumbs homeHref={undefined} />,
                handle: {
                  crumb: "Products",
                },
                children: [
                  {
                    index: true,
                    element: <ShopProducts />,
                  },
                  {
                    path: ":product",
                    handle: {
                      crumb: <ProductBreadcrumb />,
                    },
                    element: (
                      <ProductDetail>
                        {(p) => <ChooseProductButton product={p} />}
                      </ProductDetail>
                    ),
                  },
                ],
              },
              {
                path: "decoration",
                element: <ShopDecoration />,
              },
              {
                path: "variants",
                element: <ShopVariants />,
              },
              {
                path: "checkout",
                element: <ShopCheckout />,
              },
            ],
          },
          {
            path: "/approvals/:code",
            element: <Approval />,
          },
        ],
      },
    ],
  },
]);

const getEcho = memoize(
  (config: { key: string; host: string; scheme: "https" | "http" }) =>
    new LaravelEcho({
      broadcaster: "reverb",
      key: config.key,
      wsHost: config.host,
      wsPort: 8080,
      wssPort: 8080,
      forceTLS: config.scheme === "https",
      enabledTransports: ["ws", "wss"],
      authorizer: (channel: { name: string }) => ({
        authorize: (
          socketId: string,
          callback: (error: boolean, data: any) => void,
        ) => {
          axios
            .post("/broadcasting/auth", {
              socket_id: socketId,
              channel_name: channel.name,
            })
            .then((response) => {
              callback(false, response.data);
            })
            .catch((error) => {
              callback(true, error);
            });
        },
      }),
    }),
);

export default function App() {
  const [showSplash, setShowSplash] = useState(false);
  const {
    data = {
      user: null,
      membership: null,
      countries: [],
    },
    isLoading,
  } = useQuery(["me"], getMe);

  const context = data.membership
    ? { ...data, Echo: getEcho(data.reverb) }
    : data;

  useEffect(() => {
    if (ALLOWS_SIGNUP) {
      window.Beacon("init", "1a97d7c0-27b9-4475-9bfe-63561576a1b1");
    }
  }, []);

  useEffect(() => {
    const { user, membership } = data;

    if (user) {
      const userProps: Record<string, any> = {
        id: user.id,
        name: user.name,
        email: user.email,
      };
      if (membership) {
        userProps.company = membership.customer;
      }
      identify(user.id.toString(), userProps);
      Sentry.setUser(userProps);
      if (ALLOWS_SIGNUP) {
        window.Beacon("identify", userProps);
      }
    }

    if (
      "portal_ui_url" in data &&
      !window.location.href.startsWith(data.portal_ui_url)
    ) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set("customer_id", String(data.membership.customer.id));
      window.location.href = [
        trimEnd(data.portal_ui_url, "/"),
        window.location.pathname,
        "?",
        urlParams.toString(),
      ].join("");
    }
  }, [data]);

  if (isLoading || showSplash) {
    return <SplashScreen />;
  }

  return (
    <AppContext.Provider value={{ ...context, setShowSplash }}>
      <ThemeProvider>
        <Frigade.Provider
          apiKey={
            ALLOWS_SIGNUP
              ? "api_public_Ub3gPT87L3hbd5qEZ2YiQjsY3v58TgbJg7uOBc4WlFfDLab1pqoruWlLdunsfg8Q"
              : "lob"
          }
          userId={data.user ? String(data.user.id) : undefined}
          userProperties={
            data.user
              ? pick(data.user, ["name", "email", "created_at"])
              : undefined
          }
          groupId={
            data.membership ? String(data.membership.customer.id) : undefined
          }
          groupProperties={
            data.membership
              ? pick(data.membership.customer, ["name", "website"])
              : undefined
          }
          navigate={(url, target) => {
            if (url.startsWith("https://")) {
              window.open(url, target);
            } else {
              router.navigate(url);
            }
          }}
        >
          <RouterProvider router={router} />
        </Frigade.Provider>
      </ThemeProvider>
    </AppContext.Provider>
  );
}
