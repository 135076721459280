import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { CustomerUser } from "avail-types";
import { useProject } from "@/context/ProjectContext";
import { useCallback } from "react";

export function useGetProjectUsers() {
  const projectId = useProject().id;
  return useQuery(["project", projectId, "users"], () =>
    axios
      .get<{ data: CustomerUser[] }>(`projects/${projectId}/users`, {
        params: {
          sort: "name",
        },
      })
      .then(({ data }) => data.data),
  );
}

export function useSetProjectUsers() {
  const project = useProject();
  const queryClient = useQueryClient();

  return useCallback(
    (users: CustomerUser[]) => {
      queryClient.setQueryData<CustomerUser[]>(
        ["project", project.id, "users"],
        users,
      );
      queryClient.invalidateQueries(["project", project.id, "users"]);
    },
    [project.id, queryClient],
  );
}

export function useAddProjectUser() {
  const projectId = useProject().id;
  const setProjectUsers = useSetProjectUsers();
  return useMutation((user: CustomerUser) =>
    axios
      .post<{ data: CustomerUser[] }>(`projects/${projectId}/users/${user.id}`)
      .then(({ data }) => {
        setProjectUsers(data.data);
      }),
  );
}

export function useRemoveProjectUser() {
  const projectId = useProject().id;
  const setProjectUsers = useSetProjectUsers();
  return useMutation((user: CustomerUser) =>
    axios
      .delete<{ data: CustomerUser[] }>(
        `projects/${projectId}/users/${user.id}`,
      )
      .then(({ data }) => {
        setProjectUsers(data.data);
      }),
  );
}
