import { imageUrl } from "@/lib/image";
import { cn } from "@/lib/utils";

interface ProductImageTilesProps extends React.HTMLAttributes<HTMLDivElement> {
  images: string[];
  onImageSelected: (image: string, index: number) => void;
  selectedImage: string | undefined;
}

export default function ProductImageTiles({
  images,
  onImageSelected,
  selectedImage,
}: ProductImageTilesProps) {
  return (
    <>
      {images.length > 1 && (
        <div className="flex flex-wrap gap-2">
          {images.map((image, idx) => (
            <button
              key={image}
              type="button"
              onClick={() => onImageSelected(image, idx)}
              className={cn(
                "size-20 rounded border p-1 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2",
                { "border-primary": selectedImage === image },
              )}
            >
              <img
                src={imageUrl(image, {
                  w: 100,
                  h: 100,
                  fit: "contain",
                })}
                className="h-full w-full object-cover"
                alt=""
              />
            </button>
          ))}
        </div>
      )}
    </>
  );
}
