import { Loader, ShoppingCart } from "lucide-react";
import { useGetCart } from "@/api/cart";
import { Badge } from "@/components/ui/badge";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { useEffect, useRef, useState } from "react";
import CartItems from "@/components/cart/CartItems";
import CartActions from "@/components/cart/CartActions";

export default function CartDrawer({ checkoutUrl }: { checkoutUrl: string }) {
  const { data: cart, isLoading } = useGetCart();
  const [open, setOpen] = useState(false);

  const prevNumItems = useRef<number>();

  useEffect(
    function openAfterNewItem() {
      if (cart) {
        if (
          prevNumItems.current != null &&
          prevNumItems.current < cart.items.length
        ) {
          if (!window.location.pathname.includes("checkout")) {
            setOpen(true);
          }
        }

        prevNumItems.current = cart.items.length;
      }
    },
    [cart],
  );

  if (!cart || isLoading) {
    return <Loader className="size-6 animate-spin text-muted-foreground" />;
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger className="inline-flex items-start">
        <ShoppingCart className="size-6" />
        <Badge variant="primary">{cart.items.length}</Badge>
      </SheetTrigger>
      <SheetContent className="flex flex-col overflow-y-auto">
        <SheetHeader>
          <SheetTitle>Cart</SheetTitle>
        </SheetHeader>

        <CartItems items={cart.items} />

        <CartActions cart={cart} checkoutUrl={checkoutUrl} />
      </SheetContent>
    </Sheet>
  );
}
