import { ReactNode, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useDuplicateProject } from "@/api/project";
import { Button } from "../ui/button";
import { useProject } from "@/context/ProjectContext";

const formSchema = z.object({
  name: z.string().min(1),
});

export default function ProjectDuplicateForm({
  children,
}: {
  children?: ReactNode;
}) {
  const project = useProject();
  const duplicateProject = useDuplicateProject(project.id);
  const [open, setOpen] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: `${project.name} (Copy)`,
    },
  });

  const handleDuplicateProject = (values: z.infer<typeof formSchema>) =>
    duplicateProject.mutateAsync(values).then(() => {
      form.reset();
      setOpen(false);
    });

  return (
    <Dialog open={open} onOpenChange={() => setOpen(!open)}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Duplicate Project</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          Are you sure you want to duplicate this project?
        </DialogDescription>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleDuplicateProject)}
            className="space-y-5"
          >
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>New Project Name</FormLabel>
                  <FormControl>
                    <Input autoFocus type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button variant="outline" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button type="submit" isLoading={form.formState.isSubmitting}>
                Duplicate
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
