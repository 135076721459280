import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ReactNode } from "react";

export default function SimpleTooltip({
  children,
  tooltip,
  asChild,
}: {
  children: ReactNode;
  tooltip: ReactNode;
  asChild?: boolean;
}) {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={100}>
        <TooltipTrigger type="button" tabIndex={-1} asChild={asChild}>
          {children}
        </TooltipTrigger>
        <TooltipContent className="max-w-lg">{tooltip}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
