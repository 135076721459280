import { useProject } from "@/context/ProjectContext";
import ProjectItemRow from "@/components/projects/ProjectItemRow";
import { Alert } from "@/components/ui/alert";
import startCase from "lodash/startCase";
import { curr } from "@/lib/curr";
import ProjectAction from "@/components/projects/ProjectAction";
import FloatingBar from "@/components/ui/floating-bar";
import { Link } from "react-router-dom";
import { Plus } from "lucide-react";

export default function DraftStatus() {
  const project = useProject();

  return (
    <div>
      <Alert variant="info">
        Congratulations on your new project! Please review the details and
        product categories below. When you’re ready for us to get started,
        &quot;Request Options&quot; below.
      </Alert>

      <ul className="mt-4 divide-y">
        {project.items.map((item) => (
          <li key={item.id} className="py-5">
            <ProjectItemRow item={item} />
          </li>
        ))}
      </ul>

      <div className="mt-4">
        <Link
          to="./add-item"
          className="inline-flex items-center text-sm font-medium text-primary"
        >
          Add Category <Plus className="ml-2 size-4" />
        </Link>
      </div>

      <FloatingBar
        action={
          <ProjectAction
            status="options_requested"
            label="Request Options"
            direction="next"
            description="This will send a request to our team, and they will begin sourcing options for this project. Please ensure the project details are accurate & confirm your budget before proceeding as our team will use that information to provide relevant options and accurate pricing."
          />
        }
      >
        <div>
          {startCase(project.budget_type)} Budget:{" "}
          <span className="font-semibold">{curr(project.budget_amount)}</span>
        </div>
        <div>
          {project.budget_type === "total"
            ? "Estimated Quantities: "
            : "Number of People: "}
          <span className="font-semibold">
            {project.budget_qty.toLocaleString()}
          </span>
        </div>
      </FloatingBar>
    </div>
  );
}
