import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { CustomerInventoryReturn } from "avail-types";

export function useGetInventoryReturn(id: number) {
  return useQuery(["inventoryReturn", id], () =>
    axios
      .get<CustomerInventoryReturn>(`/inventory-returns/${id}`)
      .then(({ data }) => data),
  );
}

function downloadBlobAsPDF(blobData: Blob, fileName: string) {
  const url = URL.createObjectURL(blobData);
  const link = document.createElement("a");

  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  URL.revokeObjectURL(url);
}

export function useDownloadLabel() {
  return useMutation(
    ({ returnId, shipmentId }: { returnId: number; shipmentId: number }) =>
      axios
        .get<Blob>(`/inventory-returns/${returnId}/pdf/${shipmentId}`, {
          responseType: "blob",
        })
        .then(({ data }) => {
          downloadBlobAsPDF(data, `shipment-${shipmentId}-label.pdf`);
        }),
  );
}
