import AuthLayout from "@/layouts/AuthLayout";
import ResetPasswordForm from "@/components/auth/ResetPasswordForm";
import { useTitle } from "@/lib/title";
import { useAppContext } from "@/context/AppContext";
import AlreadyLoggedInRedirect from "@/components/auth/AlreadyLoggedInRedirect";

export default function ResetPassword() {
  useTitle("Reset Password");

  const context = useAppContext();
  if (context.user) {
    return <AlreadyLoggedInRedirect data={context} />;
  }

  return (
    <AuthLayout
      title="Reset your password"
      description="Choose a new password below"
    >
      <ResetPasswordForm />
    </AuthLayout>
  );
}
