import {
  AnalyticsBrowser,
  EventProperties,
  UserTraits,
} from "@segment/analytics-next";
import { ALLOWS_SIGNUP, APP_ENV } from "@/constants/services";

export const analytics = AnalyticsBrowser.load({
  writeKey:
    ALLOWS_SIGNUP && APP_ENV === "production"
      ? "BU0AX0K5Dyuy0S71mQA7U2XFud4J2zBv"
      : "1ZxKSx5LSPBAYfh1ZFbkbSVaSjR1ZVTy",
});

export const track: (
  event: string,
  properties: EventProperties,
) => Promise<unknown> = (event, properties) => {
  if (ALLOWS_SIGNUP) {
    return analytics.track(event, properties);
  }
  return Promise.resolve();
};

export const page: (name: string) => Promise<unknown> = (name) => {
  if (ALLOWS_SIGNUP) {
    return analytics.page(name);
  }
  return Promise.resolve();
};

export const identify: (
  userId: string,
  traits: UserTraits,
) => Promise<unknown> = (userId, traits) => {
  if (ALLOWS_SIGNUP) {
    return analytics.identify(userId, traits);
  }
  return Promise.resolve();
};
