import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useDebounce from "@/hooks/useDebounce";
import { useSetBusiness } from "@/context/BusinessContext";
import {
  CustomerOrder,
  OrderItem,
  PurchaseOrderStatus,
  Proof,
  Project,
  Shipment,
} from "avail-types";
import { track } from "@/lib/analytics";
import { UploadPurchaseOrderPayload } from "@/api/project";

interface OrderWithDetails extends CustomerOrder {
  items: OrderItem[];
  shipments: Shipment[];
  vendor_statuses: PurchaseOrderStatus[];
  proofs: Proof[];
}

export function useGetOrder(code: string) {
  const setBusiness = useSetBusiness();
  return useQuery(
    ["order", code],
    () =>
      axios.get<OrderWithDetails>(`/orders/${code}`).then(({ data }) => {
        setBusiness(data.business);
        return data;
      }),
    {
      refetchOnWindowFocus: true,
    },
  );
}

export interface PaymentResponse {
  balance: number;
  ach_credit_transfer: {
    account_number: string;
    bank_name: string;
    routing_number: string;
    fingerprint: string;
    swift_code: string;
  };
  bank_accounts: object[];
  card: {
    session_url: string;
  };
}

export function useUploadPurchaseOrder(orderCode: string) {
  const queryClient = useQueryClient();

  return useMutation((payload: UploadPurchaseOrderPayload) => {
    const formData = new FormData();
    formData.append("number", payload.number);
    if (payload.pdf) {
      formData.append("pdf", payload.pdf);
    }
    return axios
      .post<CustomerOrder>(`/orders/${orderCode}/purchase-order`, formData)
      .then(({ data }) => {
        queryClient.setQueryData(["order", orderCode], data);
        return data;
      });
  });
}

export function useReorder() {
  const navigate = useNavigate();

  return useMutation((payload: { items: { id: number; qty: number }[] }) => {
    return axios.post<Project>("/reorder", payload).then(({ data }) => {
      track("Project Created", {
        name: data.name,
        budget_type: data.budget_type,
        shipping_mode: data.shipping_mode,
        self_service: data.status === "proofs_requested",
      });
      navigate(`/projects/${data.id}?created=1`);
      return data;
    });
  });
}

export function useSearchOrders(query: string) {
  const debouncedQuery = useDebounce(query, 500);

  return useQuery(
    ["searchOrders", debouncedQuery],
    () =>
      axios
        .get<{
          data: CustomerOrder[];
        }>(`/orders?query=${debouncedQuery}`)
        .then(({ data }) => data.data),
    {
      enabled: !!debouncedQuery,
    },
  );
}
