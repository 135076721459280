import { NavLink } from "react-router-dom";
import { useGetCollections } from "@/api/collection";
import { collectionHasProducts } from "@/lib/products";
import SidenavItem from "@/components/navigation/SidenavItem";
import * as collectionIcons from "@/assets/collection-icons";
import camelCase from "lodash/camelCase";

export default function CollectionVerticalNavigation() {
  const { data: collections = [] } = useGetCollections();

  return (
    <ul>
      {collections.map((collection) => (
        <li key={collection.id}>
          <h4 className="text-base font-semibold">{collection.name}</h4>

          {collection.children && (
            <ul className="-mx-2 mb-6 mt-3 pl-1">
              {collection.children
                .filter(collectionHasProducts)
                .map((child) => (
                  <li key={child.id}>
                    <NavLink to={`./${child.slug}`}>
                      {({ isActive }) => (
                        <SidenavItem
                          isActive={isActive}
                          className="fill-current font-normal"
                          activeClassName="bg-primary text-primary-foreground"
                          hoverClassName="hover:bg-background"
                          // @ts-ignore
                          Icon={collectionIcons[camelCase(child.slug)]}
                          label={child.name}
                        />
                      )}
                    </NavLink>
                  </li>
                ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
}
