import Title from "@/components/ui/title";
import { useGetCollection } from "@/api/collection";
import { Link, useParams } from "react-router-dom";
import ProductCard, {
  ProductCardSkeleton,
} from "@/components/products/ProductCard";
import { useTitle } from "@/lib/title";
import { Skeleton } from "@/components/ui/skeleton";
import Grid from "@/components/ui/grid";
import Description from "@/components/ui/description";
import NeedHelpBanner from "@/components/projects/NeedHelpBanner";

export default function CollectionDetail() {
  const { collection: slug } = useParams<{ collection: string }>();
  const { data: collection, isLoading } = useGetCollection(slug!);

  useTitle(collection?.name, "Collection");

  if (isLoading) {
    return <CollectionDetailSkeleton />;
  }

  if (!collection) {
    return null;
  }

  return (
    <div className="flex-1 space-y-4">
      <div>
        <Title>{collection.name}</Title>
        <Description className="text-xs md:text-sm">
          {collection.description}
        </Description>
      </div>

      {collection.products.length > 0 ? (
        <Grid>
          {collection.products.map((p) => (
            <Link key={p.id} to={`./${p.slug}`} className="focus:outline-none">
              <ProductCard product={p} />
            </Link>
          ))}
        </Grid>
      ) : (
        <div className="text-center text-muted-foreground">
          There are no products in this category.
        </div>
      )}

      <NeedHelpBanner />
    </div>
  );
}

export function CollectionDetailSkeleton() {
  return (
    <div className="flex-1 space-y-4">
      <div>
        <Skeleton className="h-8 w-1/2" />
        <Skeleton className="mb-1 mt-2 h-3 w-full" />
        <Skeleton className="h-3 w-full" />
      </div>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
        <ProductCardSkeleton />
        <ProductCardSkeleton />
        <ProductCardSkeleton />
        <ProductCardSkeleton />
        <ProductCardSkeleton />
      </div>
    </div>
  );
}
