import { ChangeEvent, SyntheticEvent, useState } from "react";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Alert } from "@/components/ui/alert";

export default function BulkInviteForm({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const queryClient = useQueryClient();
  const [error, setError] = useState<string>("");
  const [file, setFile] = useState<File | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const resetForm = () => {
    setOpen(false);
    setFile(null);
    setMessage("");
    setLoading(false);
    setError("");
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const f = e.target.files?.[0];
    setError("");
    if (!f) return;
    setFile(f);
  };

  function onSubmit(e: SyntheticEvent) {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    if (!file) {
      setError("Please select a file");
      setLoading(false);
      return;
    }

    formData.append("file", file);
    formData.append("message", message);

    return axios
      .post(`/invites/bulk-request`, formData)
      .then(() => {
        resetForm();
        queryClient.invalidateQueries(["invites"]);
      })
      .catch((e) => {
        setLoading(false);
        setError(
          e.response.data.message ||
            "An error occurred. Please try again later.",
        );
      });
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Upload Multiple New Users</DialogTitle>
          <DialogDescription>
            You can bulk upload team members with an excel sheet. Make sure the
            first column is their full name, the second their email address, and
            the third is the role you would like to assign to the user (admin,
            member, or guest).
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={onSubmit} className="space-y-4">
          <div>
            <Label className="mb-1">Custom Message</Label>
            <Textarea
              value={message}
              name="message"
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <Input
            type="file"
            onChange={handleFileUpload}
            accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
          {error && <Alert variant="destructive">{error}</Alert>}
          <DialogFooter>
            <Button variant="outline" onClick={() => resetForm()}>
              Cancel
            </Button>
            <Button isLoading={loading} type="submit">
              Upload
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
