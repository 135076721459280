import { ReactNode } from "react";
import { ColumnDef, Table } from "@tanstack/react-table";
import { DataTable } from "@/components/ui/data-table";
import { CustomerOrder } from "avail-types";
import { curr } from "@/lib/curr";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { QueryData, TableState, useManualTable } from "@/lib/table";
import { UseQueryResult } from "@tanstack/react-query";
import { Badge } from "@/components/ui/badge";
import { ArrowRight } from "lucide-react";
import { formatDate } from "@/lib/dates";

const COLUMNS: ColumnDef<CustomerOrder>[] = [
  {
    accessorKey: "increment_id",
    header: "#",
    cell: ({ getValue, row }) => (
      <Link to={`/orders/${row.original.code}`} className="font-medium">
        {getValue<string>()}
      </Link>
    ),
  },
  {
    accessorKey: "placed_at",
    header: "Date",
    cell: ({ getValue }) => formatDate(getValue<string>()),
  },
  {
    accessorKey: "description",
    header: "Description",
  },
  {
    accessorKey: "order_type.name",
    header: "Order Type",
  },
  {
    accessorKey: "status",
    header: "Status",
    enableSorting: false,
    cell: ({ getValue }) => <Badge>{getValue<string>()}</Badge>,
  },
  {
    accessorKey: "total",
    header: "Total",
    cell: ({ getValue }) => curr(getValue<number>()),
  },
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => (
      <Button asChild variant="link" size="sm">
        <Link to={`/orders/${row.original.code}`}>
          <ArrowRight className="size-5 text-muted-foreground" />
        </Link>
      </Button>
    ),
  },
];

export default function OrdersTable({
  header,
  tableState,
  query,
}: {
  header?: (table: Table<CustomerOrder>) => ReactNode;
  tableState: TableState;
  query: UseQueryResult<QueryData<CustomerOrder>>;
}) {
  const table = useManualTable(tableState, query, COLUMNS);

  return (
    <DataTable
      header={header && header(table)}
      table={table}
      isLoading={query.isLoading}
    />
  );
}
