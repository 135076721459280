import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@/components/ui/popover";
import { Loader, SmilePlus } from "lucide-react";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { useAddReaction } from "@/api/project-activity";
import { useState } from "react";

export default function AddReactionButton({
  activityId,
  className,
}: {
  activityId: number;
  className: string;
}) {
  const [open, setOpen] = useState(false);
  const addReaction = useAddReaction(activityId);

  const onEmojiSelect = (emoji: { native: string }) => {
    addReaction.mutate(emoji.native);
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger className={className}>
        {addReaction.isLoading ? (
          <Loader className="size-4 animate-spin" />
        ) : (
          <SmilePlus className="size-4" />
        )}
      </PopoverTrigger>
      <PopoverContent className="w-auto border-none p-0">
        <Picker data={data} onEmojiSelect={onEmojiSelect} />
      </PopoverContent>
    </Popover>
  );
}
