import { useState } from "react";
import { useProject } from "@/context/ProjectContext";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  UploadPurchaseOrderPayload,
  useUploadPurchaseOrder,
} from "@/api/project";
import { canEdit } from "@/lib/projects";
import EditableField from "@/components/projects/EditableField";
import PurchaseOrderForm from "@/components/orders/PurchaseOrderForm";

export default function ProjectPurchaseOrderField() {
  const [open, setOpen] = useState(false);
  const project = useProject();
  const poRequest = useUploadPurchaseOrder(project.id);

  const handleSubmit = (values: UploadPurchaseOrderPayload) =>
    poRequest.mutateAsync(values).then(() => {
      setOpen(false);
    });

  return (
    <>
      <EditableField
        label="Purchase Order"
        onEdit={() => setOpen(true)}
        disabled={!canEdit(project, true)}
      >
        {project.customer_po || "(none)"}
      </EditableField>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Update Project Purchase Order</DialogTitle>
          </DialogHeader>
          <PurchaseOrderForm
            onSubmit={handleSubmit}
            initialValues={{ number: project.customer_po || "" }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
