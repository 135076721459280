import { createContext, useContext } from "react";
import { CustomerBusiness } from "avail-types";

const BusinessContext = createContext<{
  business: CustomerBusiness | null;
  setBusiness?: (business: CustomerBusiness | null) => void;
}>({
  business: null,
});

export function useBusiness() {
  return useContext(BusinessContext).business;
}

export function useSetBusiness() {
  return useContext(BusinessContext).setBusiness || (() => null);
}

export default BusinessContext;
