import { ReactNode } from "react";
import image from "@/assets/promo-items.jpg";
import { Link } from "react-router-dom";
import { ALLOWS_SIGNUP } from "@/constants/services";
import Logo from "@/components/auth/Logo";
import Container from "@/components/ui/container";

export default function AuthLayout({
  title,
  description,
  children,
}: {
  title: string;
  description: ReactNode;
  children: ReactNode;
}) {
  return (
    <div className="flex min-h-full flex-1 flex-col">
      <div className="flex min-h-full flex-1">
        <Container className="flex flex-1 flex-col justify-between gap-8 py-8">
          <Link to="/login">
            <Logo className="h-12 w-auto" />
          </Link>

          <div className="mx-auto w-full max-w-md">
            <h2 className="text-2xl font-bold leading-9 tracking-tight">
              {title}
            </h2>
            <p className="mt-2 text-sm leading-6 text-muted-foreground">
              {description}
            </p>
            <div className="mt-10">{children}</div>
          </div>

          <div className="text-xs text-muted-foreground">
            &copy; 2023 Avail Labs, LLC. All rights reserved.
          </div>
        </Container>
        {ALLOWS_SIGNUP && (
          <div className="relative hidden w-1/2 flex-1 overflow-hidden rounded-l-3xl px-16 py-12 text-white xl:flex xl:flex-col xl:justify-end">
            <img
              className="absolute inset-0 h-full w-full object-cover object-right"
              src={image}
              alt=""
            />
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/50" />

            <footer className="relative">
              <p className="mb-8 text-3xl">
                &ldquo;Avail is a game changer for keeping my projects in one
                place. How quickly I can communicate with my colleagues is a
                HUGE help!&rdquo;
              </p>
              <p>Madison Mize</p>
              <p className="mt-2 text-sm">Visual Brand Manager</p>
            </footer>
          </div>
        )}
      </div>
    </div>
  );
}
