import GuestLayout from "@/layouts/GuestLayout";
import ErrorBoundary from "@/ErrorBoundary";

export default function GuestErrorRoot() {
  return (
    <GuestLayout>
      <ErrorBoundary />
    </GuestLayout>
  );
}
