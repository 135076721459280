import { useEffect, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "@/components/ui/data-table";
import { CustomerInventoryReturn, Shipment } from "avail-types";
import {
  useManualTable,
  useManualTableQuery,
  useManualTableState,
} from "@/lib/table";
import InventoryReturnStatus from "@/components/inventory/InventoryReturnStatus";
import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";
import { DateRange } from "react-day-picker";
import { DataTableActions } from "@/components/ui/data-table-actions";
import { formatDate } from "@/lib/dates";

const COLUMNS: ColumnDef<CustomerInventoryReturn>[] = [
  {
    accessorKey: "number",
    header: "Return Number",
    cell: ({ getValue, row }) => (
      <Link
        to={`/inventory-returns/${row.original.id}`}
        className="font-medium"
      >
        {getValue<number>()}
      </Link>
    ),
  },
  {
    accessorKey: "created_at",
    header: "Date Created",
    cell: ({ getValue }) => <span>{formatDate(getValue<string>())}</span>,
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ getValue }) => {
      return (
        <InventoryReturnStatus
          status={getValue<CustomerInventoryReturn["status"]>()}
        />
      );
    },
  },
  {
    accessorKey: "shipments",
    header: "Tracking",
    cell: ({ getValue }) => {
      const shipments = getValue<Shipment[]>();
      return (
        <>
          {shipments?.map((s) => (
            <a
              className="block text-primary hover:underline"
              key={s.id}
              href={s.tracking_url!}
              target="_blank"
              rel="noopener noreferrer"
            >
              {s.tracking_number}
            </a>
          ))}
        </>
      );
    },
    enableSorting: false,
  },
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => (
      <div className="space-x-2">
        <Link to={`/inventory-returns/${row.original.id}`}>
          <ArrowRight className="size-5 text-muted-foreground" />
        </Link>
      </div>
    ),
  },
];

export default function InventoryReturnTable() {
  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const tableState = useManualTableState(
    [
      {
        id: "created_at",
        desc: true,
      },
    ],
    {
      filter: {
        created_at: {
          gte: dateRange?.from,
          lte: dateRange?.to,
        },
      },
    },
  );

  useEffect(() => {
    tableState.setFilters((prev) => ({
      ...prev,
      filter: {
        created_at: {
          gte: dateRange?.from,
          lte: dateRange?.to,
        },
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const query = useManualTableQuery<CustomerInventoryReturn>(
    "/inventory-returns",
    tableState,
  );

  const table = useManualTable(tableState, query, COLUMNS);

  return (
    <DataTable
      table={table}
      isLoading={query.isLoading}
      header={
        <DataTableActions
          dateRange={dateRange}
          setDateRange={setDateRange}
          filters={tableState.filters}
          setFilters={tableState.setFilters}
          placeholder="Search by Return Number"
          setQuery={tableState.setQuery}
          table={table}
        />
      }
    />
  );
}
