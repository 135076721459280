import { AllHTMLAttributes } from "react";
import { cn } from "@/lib/utils";

interface LinkProps extends AllHTMLAttributes<HTMLAnchorElement> {
  children: string;
}

export default function Link({ className, children, ...props }: LinkProps) {
  return (
    <a
      onClick={(e) => e.stopPropagation()}
      className={cn("text-primary hover:underline", className)}
      {...props}
    >
      {children.length > 40 ? (
        <>
          {children.slice(0, 20)}
          {"..."}
          {children.slice(-20)}
        </>
      ) : (
        children
      )}
    </a>
  );
}
