import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import * as Sentry from "@sentry/react";
import NotFound from "@/pages/NotFound";
import ClientError from "@/pages/ClientError";
import { APP_ENV } from "@/constants/services";

export default function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <NotFound />;
    }
  }

  if (APP_ENV !== "local") {
    Sentry.captureException(error as Error);
  }

  return <ClientError />;
}
