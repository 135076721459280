import { Button } from "@/components/ui/button";
import { Pencil } from "lucide-react";

export default function EditableField({
  label,
  children,
  onEdit,
  disabled,
}: {
  label?: string;
  children: React.ReactNode;
  onEdit: () => void;
  disabled?: boolean;
}) {
  const inner = label ? (
    <div className="flex w-full items-center gap-x-2 py-1 text-left">
      <div className="w-2/5 truncate text-sm font-medium">{label}</div>
      <div className="w-3/5 truncate">{children}</div>
    </div>
  ) : (
    <div>{children}</div>
  );

  if (disabled) return inner;

  return (
    <div className="group relative block w-full text-left">
      {inner}
      <Button
        size="icon"
        variant="outline"
        className="absolute right-0 top-1/2 hidden size-9 -translate-y-1/2 group-hover:inline-flex"
        onClick={onEdit}
      >
        <Pencil className="h-4 w-4" />
      </Button>
    </div>
  );
}
