import { HTMLAttributes } from "react";

export default function BrandCardGrid({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className="grid gap-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"
      {...props}
    >
      {children}
    </div>
  );
}
