import Title from "@/components/ui/title";
import Description from "@/components/ui/description";
import { useState } from "react";
import ItemChooser from "@/components/collections/ItemChooser";
import Toolbar from "@/components/ui/toolbar";
import WizardStep from "@/components/wizard/WizardStep";
import WizardSubmitButton from "@/components/wizard/WizardSubmitButton";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import ShopSwag from "@/assets/graphics/shop-swag.svg?react";
import FooterCard from "@/components/ui/footer-card";
import { ProjectCreatePayload, ProjectType } from "avail-types";

export default function ProjectItems({
  defaultValues,
  onSubmit,
  projectType = "project",
}: {
  defaultValues: Partial<Pick<ProjectCreatePayload, "items">>;
  onSubmit: (v: Pick<ProjectCreatePayload, "items">) => Promise<void>;
  projectType?: ProjectType;
}) {
  const [items, setItems] = useState<ProjectCreatePayload["items"]>(
    defaultValues.items || [],
  );

  return (
    <WizardStep className="md:pb-20">
      <Toolbar>
        <Title>Choose your categories for this {projectType}</Title>
        <Description>
          What kind of items are you looking for? You can add more later.
        </Description>
      </Toolbar>

      <ItemChooser
        onSelect={(collection) => {
          setItems((prev) => {
            if (prev.find((i) => i.collection_id === collection.id)) {
              return prev.filter((i) => i.collection_id !== collection.id);
            }
            return [...prev, { collection_id: collection.id, notes: "" }];
          });
        }}
        selected={
          items.map((i) => i.collection_id).filter((i) => i != null) as number[]
        }
      />

      {projectType === "project" && (
        <FooterCard
          Image={ShopSwag}
          title="Already have an idea of what you want?"
          description=" Dive into our self-service option, giving you the freedom to browse
            and select from our top-rated swag collection at your own pace."
          button={
            <Button asChild>
              <Link to={`/collections`}>Shop Swag</Link>
            </Button>
          }
        />
      )}

      <WizardSubmitButton
        onClick={() => onSubmit({ items })}
        disabled={items.length === 0}
        forceMobile
      >
        Next
      </WizardSubmitButton>
    </WizardStep>
  );
}
