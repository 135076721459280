import { CheckIcon } from "@heroicons/react/20/solid";
import { cn } from "@/lib/utils";
import { HTMLAttributes, JSX, ReactNode, cloneElement } from "react";
import { Link } from "react-router-dom";

export interface StepProps {
  name: ReactNode;
  description: ReactNode;
  status: "complete" | "current" | "upcoming";
  href?: string;
  index?: number;
  length?: number;
}

interface StepsProps extends HTMLAttributes<HTMLDivElement> {
  children: JSX.Element[];
}

export function Steps({ children, ...other }: StepsProps) {
  return (
    <nav aria-label="Progress" {...other}>
      <ol role="list" className="overflow-hidden">
        {children.map((c, i) =>
          cloneElement(c, {
            length: children.length,
            index: i,
            key: c.props.name,
          }),
        )}
      </ol>
    </nav>
  );
}

function StepItem({
  children,
  href,
  ...other
}: HTMLAttributes<HTMLElement> & { href?: string }) {
  if (href) {
    return (
      <Link to={href} {...other}>
        {children}
      </Link>
    );
  }

  return <div {...other}>{children}</div>;
}

export function Step({
  name,
  description,
  length = 1,
  index = 0,
  status,
  href,
}: StepProps) {
  return (
    <li className={cn(index !== length - 1 ? "pb-10" : "", "relative")}>
      {status === "complete" ? (
        <>
          {index !== length - 1 ? (
            <div
              className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-primary"
              aria-hidden="true"
            />
          ) : null}
          <StepItem href={href} className="group relative flex items-center">
            <span className="flex h-9 items-center">
              <span className="relative z-10 flex size-8 items-center justify-center rounded-full bg-primary group-hover:bg-primary/80">
                <CheckIcon className="size-5 text-white" aria-hidden="true" />
              </span>
            </span>
            <span className="ml-4 flex min-w-0 flex-col">
              <span className="text-sm font-medium">{name}</span>
              {description ? (
                <span className="text-sm opacity-80">{description}</span>
              ) : null}
            </span>
          </StepItem>
        </>
      ) : status === "current" ? (
        <>
          {index !== length - 1 ? (
            <div
              className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-slate-300"
              aria-hidden="true"
            />
          ) : null}
          <StepItem
            href={href}
            className="group relative flex items-center"
            aria-current="step"
          >
            <span className="flex h-9 items-center" aria-hidden="true">
              <span className="relative z-10 flex size-8 items-center justify-center rounded-full border-2 border-primary bg-white">
                <span className="h-2.5 w-2.5 rounded-full bg-primary" />
              </span>
            </span>
            <span className="ml-4 flex min-w-0 flex-col">
              <span className="text-sm font-medium text-primary">{name}</span>
              {description ? (
                <span className="text-sm opacity-80">{description}</span>
              ) : null}
            </span>
          </StepItem>
        </>
      ) : (
        <>
          {index !== length - 1 ? (
            <div
              className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-slate-300"
              aria-hidden="true"
            />
          ) : null}
          <StepItem href={href} className="group relative flex items-center">
            <span className="flex h-9 items-center" aria-hidden="true">
              <span className="relative z-10 flex size-8 items-center justify-center rounded-full border-2 border-slate-300 bg-white group-hover:border-slate-400">
                <span className="size-2.5 rounded-full bg-transparent group-hover:bg-slate-300" />
              </span>
            </span>
            <span className="ml-4 flex min-w-0 flex-col">
              <span className="text-sm font-medium opacity-80">{name}</span>
              {description ? (
                <span className="text-sm opacity-80">{description}</span>
              ) : null}
            </span>
          </StepItem>
        </>
      )}
    </li>
  );
}
