import { cn } from "@/lib/utils";
import { HTMLAttributes } from "react";

export default function BrandCard({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        "group relative flex aspect-square items-center justify-center overflow-hidden rounded border p-2",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}
