import {
  useDeleteCustomerBrandColor,
  useGetCustomerBrandColors,
} from "@/api/brand";
import { Loader, Pencil, PlusIcon, X } from "lucide-react";
import CreateColorDialog from "@/components/brand/CreateColorDialog";
import BrandCard from "@/components/brand/BrandCard";
import BrandCardActions from "@/components/brand/BrandCardActions";
import BrandCardAction from "@/components/brand/BrandCardAction";
import UpdateColorDialog from "@/components/brand/UpdateColorDialog";
import BrandCardGrid from "@/components/brand/BrandCardGrid";
import BrandCardLabel from "@/components/brand/BrandCardLabel";
import { Badge } from "@/components/ui/badge";

export default function BrandColors() {
  const { data: colors = [], isLoading } = useGetCustomerBrandColors();
  const deleteColorRequest = useDeleteCustomerBrandColor();

  return (
    <div>
      <h3 className="mb-1 text-lg font-semibold">Colors</h3>

      {isLoading ? (
        <Loader />
      ) : (
        <BrandCardGrid>
          {colors.map((color) => (
            <BrandCard style={{ backgroundColor: color.hex! }}>
              <BrandCardLabel className="flex flex-wrap items-center justify-center gap-1.5">
                {color.rgb && <Badge>rgb({color.rgb})</Badge>}
                {color.hex && <Badge>{color.hex}</Badge>}
                {color.ink_color && <Badge>Ink: {color.ink_color}</Badge>}
                {color.thread_color && (
                  <Badge>Thread: {color.thread_color}</Badge>
                )}
              </BrandCardLabel>

              <BrandCardActions>
                <UpdateColorDialog color={color}>
                  <BrandCardAction>
                    <Pencil className="size-4" />
                  </BrandCardAction>
                </UpdateColorDialog>
                <BrandCardAction
                  onClick={() => {
                    if (
                      confirm("Are you sure you want to delete this color?")
                    ) {
                      deleteColorRequest.mutate(color.id);
                    }
                  }}
                >
                  <X className="size-4" />
                </BrandCardAction>
              </BrandCardActions>
            </BrandCard>
          ))}

          <CreateColorDialog>
            <button type="button">
              <BrandCard className="bg-muted">
                <PlusIcon className="size-6" />
              </BrandCard>
            </button>
          </CreateColorDialog>
        </BrandCardGrid>
      )}
    </div>
  );
}
