import Title, { SectionTitle } from "@/components/ui/title";
import { useParams } from "react-router-dom";
import { Skeleton } from "@/components/ui/skeleton";
import Toolbar from "@/components/ui/toolbar";
import Description from "@/components/ui/description";
import AddressBlock from "@/components/addresses/AddressBlock";
import NotFound from "@/pages/NotFound";
import { useTitle } from "@/lib/title";
import { Card } from "@/components/ui/card";
import { useGetQuote } from "@/api/quotes";
import QuoteItems from "@/components/quotes/QuoteItems";
import QuoteActions from "@/components/quotes/QuoteActions";

export default function QuoteDetail() {
  const { code } = useParams<{ code: string }>();
  const { data: quote, isLoading } = useGetQuote(code!);
  useTitle(quote?.increment_id, "Quote");

  if (isLoading) {
    return <Skeleton className="h-8 w-[250px]" />;
  }

  if (!quote) {
    return <NotFound />;
  }

  return (
    <div className="flex-1 space-y-8">
      <Toolbar actions={<QuoteActions quote={quote} />}>
        <Title>{quote.increment_id}</Title>
        <Description>{quote.description}</Description>
      </Toolbar>

      <Card className="p-5">
        <div className="grid grid-cols-1 gap-6 xl:grid-cols-3">
          <div>
            <SectionTitle>Ordered By</SectionTitle>
            <p>
              {quote.contact_name && <div>{quote.contact_name}</div>}
              {quote.contact_email && <div>{quote.contact_email}</div>}
              {quote.contact_phone && <div>{quote.contact_phone}</div>}
            </p>
          </div>
          <div>
            <SectionTitle>Shipping Address</SectionTitle>
            {quote.shipping_address && (
              <AddressBlock address={quote.shipping_address} />
            )}
          </div>
          <div>
            <SectionTitle>Billing Address</SectionTitle>
            {quote.billing_address && (
              <AddressBlock address={quote.billing_address} />
            )}
          </div>
        </div>
      </Card>

      <QuoteItems quote={quote} items={quote.items} />
    </div>
  );
}
