import { Button } from "@/components/ui/button";
import { ReactNode } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";

export default function Pagination({
  pageIndex,
  totalPages,
  canGetPrevious,
  onPrevious,
  canGetNextPage,
  onNext,
  children,
}: {
  pageIndex: number;
  totalPages: number;
  canGetPrevious: boolean;
  onPrevious: () => void;
  canGetNextPage: boolean;
  onNext: () => void;
  children?: ReactNode;
}) {
  if (totalPages === 0) {
    return null;
  }

  return (
    <div className="flex items-center justify-between space-x-2 py-4">
      <div className="text-sm text-muted-foreground">
        Page {pageIndex + 1} of {totalPages}
      </div>

      <div>{children}</div>

      <div className="flex items-center space-x-2">
        <Button
          variant="outline"
          size="sm"
          onClick={() => onPrevious()}
          disabled={!canGetPrevious}
        >
          <ChevronLeftIcon className="mr-1.5 size-4" />
          <span className="hidden md:inline">Previous</span>
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={() => onNext()}
          disabled={!canGetNextPage}
        >
          <span className="hidden md:inline">Next</span>
          <ChevronRightIcon className="ml-1.5 size-4" />
        </Button>
      </div>
    </div>
  );
}
