import { z } from "zod";
import Title from "@/components/ui/title";
import Description from "@/components/ui/description";
import { RadioGroup } from "@headlessui/react";
import startCase from "lodash/startCase";
import { RadioOption } from "@/components/ui/radio-option";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { curr } from "@/lib/curr";
import Toolbar from "@/components/ui/toolbar";
import WizardStep from "@/components/wizard/WizardStep";
import WizardSubmitButton from "@/components/wizard/WizardSubmitButton";
import CurrencyInput from "@/components/ui/currency-input";

const formSchema = z.object({
  budget_type: z.enum(["per_person", "total"]),
  budget_amount: z.coerce.number().min(1),

  budget_qty: z.coerce.number().min(1),
});
type FormShape = z.infer<typeof formSchema>;

const BUDGET_TYPES: FormShape["budget_type"][] = ["per_person", "total"];

export default function ProjectBudget({
  defaultValues,
  onSubmit,
}: {
  defaultValues: Partial<FormShape>;
  onSubmit: (v: FormShape) => Promise<void>;
}) {
  const form = useForm<FormShape>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const budgetType = form.watch("budget_type");
  const budgetAmount = form.watch("budget_amount");
  const budgetQty = form.watch("budget_qty");

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <WizardStep>
          <Toolbar>
            <Title>How would you like to budget?</Title>
            <Description>
              This will help our team get the most relevant options for your
              budget.
            </Description>
          </Toolbar>

          <FormField
            control={form.control}
            name="budget_type"
            render={({ field }) => (
              <FormItem>
                <RadioGroup value={field.value} onChange={field.onChange}>
                  <RadioGroup.Label className="sr-only">
                    Select a budgeting style
                  </RadioGroup.Label>

                  <div className="mt-4 grid grid-cols-1 gap-3 sm:grid-cols-2 sm:gap-6">
                    {BUDGET_TYPES.map((budgetType) => (
                      <RadioOption
                        key={budgetType}
                        value={budgetType}
                        label={startCase(budgetType)}
                        description={
                          budgetType === "per_person" ? (
                            <span>
                              Great for when you have a set number of recipients
                            </span>
                          ) : (
                            <span>
                              This method considers your "all-in" budget for the
                              project
                            </span>
                          )
                        }
                      />
                    ))}
                  </div>
                </RadioGroup>
                <FormMessage />
              </FormItem>
            )}
          />

          {budgetType && (
            <>
              <FormField
                control={form.control}
                name="budget_amount"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {budgetType === "per_person"
                        ? "Amount per Person"
                        : "Total Budget"}
                    </FormLabel>
                    <FormControl>
                      <CurrencyInput {...field} autoFocus />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="budget_qty"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {budgetType === "total"
                        ? "Estimated Quantity"
                        : "Number of People"}
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        type="number"
                        inputMode="numeric"
                        step={1}
                      />
                    </FormControl>
                    {budgetType === "total" && (
                      <FormDescription>
                        How many of <i>each</i> item are you looking for?
                      </FormDescription>
                    )}
                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          )}

          {budgetType === "per_person" && budgetQty && budgetAmount ? (
            <div>
              <span>Total Budget: </span>
              <span className="text-xl">{curr(budgetAmount * budgetQty)}</span>
            </div>
          ) : null}

          {budgetType === "total" && budgetQty && budgetAmount ? (
            <div>
              <span>Budget per Unit: </span>
              <span className="text-xl">{curr(budgetAmount / budgetQty)}</span>
            </div>
          ) : null}

          <WizardSubmitButton>Next</WizardSubmitButton>
        </WizardStep>
      </form>
    </Form>
  );
}
