import { HTMLAttributes } from "react";
import { cn } from "@/lib/utils";

export default function BrandCardAction({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      type="button"
      className={cn("rounded-full bg-white p-1 shadow", className)}
      {...props}
    >
      {children}
    </button>
  );
}
