import AccountDropdownMenu from "@/components/navigation/AccountDropdownMenu";
import UserAvatar from "@/components/account/UserAvatar";
import SidenavCount from "@/components/navigation/SidenavCount";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useAppState } from "@/context/AppContext";

export default function AccountNavItem({
  isCollapsed,
}: {
  isCollapsed?: boolean;
}) {
  const { user, membership, memberships } = useAppState();

  const otherCustomerNotificationCount = memberships
    .filter((m) => m.id !== membership.id)
    .reduce((acc, m) => acc + (m.unread_notification_count || 0), 0);

  return (
    <AccountDropdownMenu>
      <button
        type="button"
        className="flex h-16 w-full items-center gap-x-4 px-4 text-left text-sm leading-5 text-drawer-foreground hover:bg-drawer-foreground/10"
      >
        <UserAvatar user={user} className="size-8" />
        {!isCollapsed && (
          <>
            <div className="flex flex-grow flex-col overflow-hidden">
              <span className="truncate font-semibold">{user.name}</span>
              <span className="truncate text-xs opacity-75">
                {membership.customer.name}
              </span>
            </div>

            <div className="ml-auto flex items-center gap-2">
              {otherCustomerNotificationCount > 0 && (
                <SidenavCount>{otherCustomerNotificationCount}</SidenavCount>
              )}
              <ChevronUpDownIcon className="size-5 text-drawer-foreground/75" />
            </div>
          </>
        )}
      </button>
    </AccountDropdownMenu>
  );
}
