import ImageUploadField from "@/components/images/ImageUploadField";
import { useState } from "react";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import ImageEditor from "@/components/images/ImageEditor";
import { useDesignLayout } from "@/api/designs";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import { useGetCustomerBrandFiles } from "@/api/brand";
import { brandFileToAttachment } from "@/lib/attachments";

export default function ProjectOptionImagesField({
  images: images,
  onChange,
  designLayoutId,
}: {
  images: string[];
  onChange: (i: string[]) => void;
  designLayoutId?: number | null;
}) {
  const [editing, setEditing] = useState<number>();
  const { data: files = [] } = useGetCustomerBrandFiles();
  const { data: designLayout } = useDesignLayout(designLayoutId);

  const handleChange = (i: number) => (value: string) => {
    const newImages = [...images];
    newImages[i] = value;
    onChange(newImages);
    return Promise.resolve();
  };

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = images.findIndex((o) => o === active.id);
      const newIndex = images.findIndex((o) => o === over.id);
      onChange(arrayMove(images, oldIndex, newIndex));
    }
  };

  return (
    <>
      <DndContext onDragEnd={onDragEnd}>
        <div className="grid grid-cols-4 gap-2">
          <SortableContext items={images}>
            {images.map((image, i) => (
              <ImageUploadField
                key={image}
                image={image}
                onChange={handleChange(i)}
                onDelete={() => onChange(images.filter((_, j) => j !== i))}
                onEdit={() => setEditing(i)}
                sortable
              />
            ))}
          </SortableContext>
          <ImageUploadField
            image={undefined}
            onChange={handleChange(images.length)}
          />
        </div>
      </DndContext>
      <Dialog
        open={editing !== undefined}
        onOpenChange={() => setEditing(undefined)}
      >
        <DialogContent className="sm:max-w-6xl">
          {editing != null && images[editing] ? (
            <ImageEditor
              image={images[editing]!}
              onSave={(v) => {
                handleChange(editing)(v);
                setEditing(undefined);
              }}
              designImages={[
                ...files
                  .filter((f) => f.type === "logo")
                  .filter((f) => f.image)
                  .map(brandFileToAttachment),
                ...(designLayout?.attachments || []),
              ]}
            />
          ) : null}
        </DialogContent>
      </Dialog>
    </>
  );
}
