import Title from "@/components/ui/title";
import { Table } from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { PlusCircleIcon } from "lucide-react";
import Toolbar from "@/components/ui/toolbar";
import OrdersTable from "@/components/orders/OrdersTable";
import { useManualTableQuery, useManualTableState } from "@/lib/table";
import { CustomerInvoice, CustomerOrder } from "avail-types";
import { useTitle } from "@/lib/title";
import { DataTableActions } from "@/components/ui/data-table-actions";
import { Link } from "react-router-dom";

export default function Orders() {
  useTitle("Open Orders");
  const tableState = useManualTableState(
    [
      {
        id: "placed_at",
        desc: true,
      },
    ],
    {
      bucket: "open",
    },
  );
  const { setQuery } = tableState;

  const result = useManualTableQuery<CustomerInvoice>("/orders", tableState);

  return (
    <div className="flex-1 space-y-6">
      <Toolbar
        actions={
          <Button asChild>
            <Link to="/shop">
              <PlusCircleIcon className="mr-2 size-4" /> New Order
            </Link>
          </Button>
        }
      >
        <Title>Open Orders</Title>
      </Toolbar>

      <OrdersTable
        header={(table: Table<CustomerOrder>) => (
          <DataTableActions
            placeholder="Search by number or description"
            setQuery={setQuery}
            table={table}
          />
        )}
        tableState={tableState}
        query={result}
      />
    </div>
  );
}
