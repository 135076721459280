import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { curr } from "@/lib/curr";
import { Input } from "@/components/ui/input";
import { useShopState } from "@/context/ShopContext";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Navigate, useNavigate } from "react-router-dom";
import OrderPreviewTable from "@/components/shop/OrderPreviewTable";
import omit from "lodash/omit";
import { AddToCartPayload, useAddItem, useCartPreview } from "@/api/cart";

export default function ShopVariants() {
  const { product, designLayout, resetState } = useShopState();
  const [variants, setVariants] = useState<Record<number, number>>({});
  const navigate = useNavigate();
  const addToCartRequest = useAddItem();

  const payload: AddToCartPayload = {
    product_id: product?.id || 0,
    variants: Object.entries(variants).map(([variant_id, qty]) => ({
      variant_id: Number(variant_id),
      qty,
    })),
    design_layout_id: designLayout?.id,
  };
  const canGetPreview = Boolean(
    payload.product_id && Object.values(variants).filter((q) => q > 0).length,
  );
  const previewQuery = useCartPreview(payload, canGetPreview);

  const handleAddToCart = (checkout: boolean) => {
    addToCartRequest.mutateAsync(payload).then(() => {
      resetState();
      if (checkout) {
        navigate("/shop/checkout");
      } else {
        navigate("/shop/products");
      }
    });
  };

  if (!product) {
    return <Navigate to="/shop/products" replace />;
  }

  return (
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
      <div>
        <div className="mb-2 text-xl font-semibold">Your Selection</div>

        <Card className="mb-6 flex max-w-sm flex-col items-center p-4">
          {product.image && (
            <img
              src={product.image}
              className="mb-3 size-48 max-w-full object-contain"
            />
          )}
          <div className="font-medium">{product.name}</div>
        </Card>

        {designLayout && (
          <Card className="flex max-w-sm flex-col items-center p-4">
            {Boolean(designLayout.images.length) && (
              <img
                src={designLayout.images[0]}
                alt=""
                className="mb-4 size-48 max-w-full object-contain"
              />
            )}
            <div className="font-medium">{designLayout.name}</div>
          </Card>
        )}
      </div>
      <div className="lg:col-span-2">
        <div className="mb-2 text-xl font-semibold">Choose Quantities</div>

        <Card className="mb-4">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Size</TableHead>
                <TableHead>Your Price</TableHead>
                <TableHead>Inventory</TableHead>
                <TableHead>Qty</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {product.variants.map((variant) => (
                <TableRow>
                  <TableCell>{variant.size}</TableCell>
                  <TableCell>{curr(variant.price)}</TableCell>
                  <TableCell>
                    <div title={`Last Updated ${variant.stock_updated_at}`}>
                      {variant.vendor_inventory_qty?.toLocaleString()}
                    </div>
                  </TableCell>
                  <TableCell className="px-4 py-2">
                    <Input
                      onChange={(e) => {
                        if (e.target.value) {
                          setVariants((prev) => ({
                            ...prev,
                            [variant.id]: Math.min(
                              variant.vendor_inventory_qty || 0,
                              Number(e.target.value),
                            ),
                          }));
                        } else {
                          setVariants((prev) => omit(prev, variant.id));
                        }
                      }}
                      value={variants[variant.id]}
                      type="number"
                      name={`variants[${variant.id}]`}
                      className="block w-full rounded-md border-slate-300 py-1 shadow-sm focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
                      step={1}
                      min={0}
                      max={variant.vendor_inventory_qty || 0}
                      disabled={!variant.vendor_inventory_qty}
                      title={
                        (variant.vendor_inventory_qty || 0) <= 0
                          ? "No Inventory Available"
                          : ""
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </div>
      <div className="lg:col-span-3">
        <div className="mb-2 mt-6 text-xl font-semibold">Order Preview</div>

        {canGetPreview ? (
          <OrderPreviewTable order={previewQuery.data} />
        ) : (
          <div className="text-muted-foreground">
            Please enter quantities above in order to see pricing.
          </div>
        )}

        {canGetPreview && (
          <div className="mt-6 flex justify-end space-x-2">
            <Button
              type="button"
              variant="outline"
              onClick={() => handleAddToCart(false)}
              isLoading={addToCartRequest.isLoading}
            >
              Add to Cart &amp; Continue Shopping
            </Button>

            <Button
              type="button"
              onClick={() => handleAddToCart(true)}
              isLoading={addToCartRequest.isLoading}
            >
              Add to Cart &amp; Checkout
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
