import { HTMLAttributes } from "react";
import { cn } from "@/lib/utils";

export const CONTAINER_PADDING = "px-4 md:px-6 lg:px-8";

export default function Container({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn("container", CONTAINER_PADDING, className)} {...props}>
      {children}
    </div>
  );
}
