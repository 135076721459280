import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { DateRange } from "react-day-picker";

import { cn } from "@/lib/utils";
import {
  PopoverContent,
  Popover,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Calendar } from "@/components/ui/calendar";
import { Button } from "@/components/ui/button";
import { HTMLAttributes } from "react";
import { INPUT_CLASSES } from "@/components/ui/input";

interface DateRangePickerProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "selected" | "onSelect"> {
  selected: DateRange | undefined;
  onSelect: (value: DateRange | undefined) => void;
}

export function DateRangePicker({
  className,
  selected,
  onSelect,
  ...other
}: DateRangePickerProps) {
  return (
    <div className={cn("grid gap-2", className)} {...other}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-[260px] justify-start text-left font-normal",
              !selected && "text-muted-foreground",
              INPUT_CLASSES,
            )}
          >
            <CalendarIcon className="mr-2 size-4" />
            {selected?.from ? (
              selected.to ? (
                <>
                  {format(selected.from, "LLL dd, y")} -{" "}
                  {format(selected.to, "LLL dd, y")}
                </>
              ) : (
                format(selected.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date range</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="end">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={selected?.from}
            selected={selected}
            onSelect={onSelect}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
