import { curr } from "@/lib/curr";
import { cn } from "@/lib/utils";
import pluralize from "pluralize";
import { ProjectOption } from "avail-types";

export function Price({
  label,
  value,
  size = "md",
  reversed,
}: {
  label: string;
  value: number;
  size?: "md" | "lg";
  reversed?: boolean;
}) {
  const baseClass = cn("font-medium", size === "lg" ? "text-lg" : "text-base");
  const smallClass = size === "lg" ? "text-sm" : "text-xs";

  const components = [
    <span className={baseClass} key="value">
      {curr(value)}
    </span>,
    <span key="space">&nbsp;</span>,
    <span className={smallClass} key="label">
      {label}
    </span>,
  ];

  return <div>{reversed ? components.reverse() : components}</div>;
}

export default function PriceDetails({
  unitPrice,
  numUnits,
  isStartingAt,
  fees,
  size = "md",
}: {
  unitPrice: number;
  numUnits?: number;
  isStartingAt?: boolean;
  fees?: ProjectOption["fees"];
  size?: "md" | "lg";
}) {
  let label = "per unit";
  if (numUnits) {
    if (isStartingAt) {
      label = "starting at";
    } else {
      label = `each for ${pluralize("unit", numUnits, true)}`;
    }
  }

  return (
    <div>
      <Price
        size={size}
        label={label}
        value={unitPrice}
        reversed={label === "starting at"}
      />

      {fees?.map((fee) => (
        <Price
          key={fee.name}
          size={size}
          label={fee.name.replace("_", " ")}
          value={fee.price * (fee.qty || 1)}
        />
      ))}
    </div>
  );
}
