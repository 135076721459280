import { useState } from "react";
import { z } from "zod";
import { Form } from "@/components/ui/form";
import { PlusIcon } from "lucide-react";
import { useAddAddress } from "@/api/addresses";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { addressPayloadSchema } from "avail-types";
import { useToast } from "@/components/ui/use-toast";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import AddressFields from "@/components/addresses/AddressFields";
import { handleLaravelErrors } from "@/lib/form";

const formSchema = z.object({
  address: addressPayloadSchema,
});

export const DEFAULT_ADDRESS = {
  name: "",
  company: null,
  address_1: "",
  address_2: null,
  city: "",
  state: null,
  zip: "",
  country: "US",
  is_residential: false,
  save_address: false,
};

export default function AddressNewDialog() {
  const [open, setOpen] = useState(false);
  const { toast } = useToast();
  const addAddress = useAddAddress();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      address: DEFAULT_ADDRESS,
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    const { address } = values;
    return addAddress
      .mutateAsync(address)
      .then(() => {
        form.reset();
        toast({ title: "Successfully added address", variant: "success" });
        setOpen(false);
      })
      .catch(handleLaravelErrors(form, "address"));
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="flex-grow flex-col" asChild>
        <Button variant="default" type="button" className="flex-row">
          New Address <PlusIcon className="ml-1.5 size-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-2xl">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
            <DialogHeader>
              <DialogTitle>Add New Address</DialogTitle>
            </DialogHeader>
            <AddressFields form={form} />
            <DialogFooter>
              <Button
                type="submit"
                variant="default"
                isLoading={form.formState.isSubmitting}
              >
                Save
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
