import { Step, StepProps, Steps } from "@/components/ui/steps-vertical";
import { ReactNode } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useMediaQuery, useSessionStorage } from "usehooks-ts";
import { AxiosError } from "axios";
import { getUrlWithExistingSearch } from "@/lib/auth";
import Lottie from "lottie-react";
import loadingJson from "@/assets/lottie/loading.json";
import { useAppContext } from "@/context/AppContext";
import { track } from "@/lib/analytics";
import { cn } from "@/lib/utils";
import DyanmicLayout from "@/layouts/DyanmicLayout";

export interface WizardStep<T extends Record<string, any>> {
  name: string;
  description: ((v: Partial<T>) => ReactNode) | ReactNode;
  isComplete: (v: Partial<T>) => boolean;
  component: (props: {
    defaultValues: Partial<Partial<T>>;
    onSubmit: (v: Partial<T>) => Promise<void>;
    isLoading: boolean;
  }) => ReactNode;
}

interface WizardProps<T extends Record<string, any>> {
  sessionStorageKey: string;
  isLoading: boolean;
  onSubmit: (v: T) => Promise<unknown>;
  steps: WizardStep<T>[];
  defaultValues?: Partial<T>;
  title?: string;
}

export default function WizardPage<T extends Record<string, any>>({
  sessionStorageKey,
  isLoading,
  onSubmit,
  steps,
  defaultValues = {},
  title,
}: WizardProps<T>) {
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const { membership } = useAppContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [values, setValues] = useSessionStorage<Partial<T>>(
    `${sessionStorageKey}.${membership ? membership.id : "anon"}`,
    defaultValues,
  );
  const currentStep = Number(searchParams.get("step") || 0);

  const performSubmit = (values: T) =>
    onSubmit(values)
      .then(() => {
        setValues({});
      })
      .catch((e: AxiosError) => {
        // @ts-ignore
        alert(e.response?.data?.message || "Something went wrong");
      });

  const getStatusForStep = (step: number): StepProps["status"] => {
    if (step < currentStep) {
      return "complete";
    }
    if (step > currentStep) {
      return "upcoming";
    }
    return "current";
  };

  const getStepBody = () => {
    const step = steps[currentStep];

    if (!step) {
      setSearchParams((prev) => {
        prev.set("step", "0");
        return prev;
      });
      return null;
    }

    if (currentStep > 0) {
      const previousStep = steps[currentStep - 1];
      if (!previousStep!.isComplete(values)) {
        setSearchParams((prev) => {
          prev.set("step", String(currentStep - 1));
          return prev;
        });
        return null;
      }
    }

    return (
      <step.component
        defaultValues={values}
        isLoading={isLoading}
        onSubmit={(newValues) => {
          track("Wizard Step Completed", {
            step: currentStep,
            name: step.name,
          });
          setValues((prev) => ({ ...prev, ...newValues }));

          if (currentStep === steps.length - 1) {
            return performSubmit({
              ...values,
              ...newValues,
            } as T);
          }

          setSearchParams((prev) => {
            prev.set("step", String(currentStep + 1));
            return prev;
          });
          return Promise.resolve();
        }}
      />
    );
  };

  return (
    <DyanmicLayout>
      {({ top, left }) => (
        <>
          {isMobile ? (
            <div className="fixed inset-x-4 bottom-4 z-10 flex gap-x-2">
              {steps.map((step, index) => (
                <Link
                  key={step.name}
                  title={step.name}
                  to={getUrlWithExistingSearch("", {
                    step: String(index),
                  })}
                  className={cn(
                    "z-20 block h-2 flex-1 rounded-lg",
                    currentStep >= index ? "bg-primary" : "bg-slate-200",
                  )}
                />
              ))}
            </div>
          ) : (
            <div
              className={cn(
                "flex-0 fixed bottom-0 flex h-screen w-96 flex-col justify-between border-r bg-white",
                top,
                left,
              )}
            >
              <div className="p-6 py-4">
                {title && <h2 className="text-2xl font-bold">{title}</h2>}

                <Steps className="mt-8 flex-grow">
                  {steps.map((step, index) => (
                    <Step
                      key={step.name}
                      name={step.name}
                      description={
                        typeof step.description === "function"
                          ? step.description(values)
                          : step.description
                      }
                      status={getStatusForStep(index)}
                      href={getUrlWithExistingSearch("", {
                        step: String(index),
                      })}
                    />
                  ))}
                </Steps>
              </div>

              <footer className="p-6 text-xs">
                &copy; 2023 Avail Labs, LLC. All rights reserved.
              </footer>
            </div>
          )}
          <div className="container px-1 lg:pl-[26rem]">
            {isLoading ? (
              <div className="flex h-screen w-full items-center justify-center">
                <Lottie animationData={loadingJson} loop />
              </div>
            ) : (
              getStepBody()
            )}
          </div>
        </>
      )}
    </DyanmicLayout>
  );
}
