import RegisterForm from "@/components/auth/RegisterForm";
import AuthLayout from "@/layouts/AuthLayout";
import { Link } from "react-router-dom";
import SocialLogins from "@/components/auth/SocialLogins";
import { getUrlWithExistingSearch } from "@/lib/auth";
import { useTitle } from "@/lib/title";
import { useAppContext } from "@/context/AppContext";
import AlreadyLoggedInRedirect from "@/components/auth/AlreadyLoggedInRedirect";
import { Alert } from "@/components/ui/alert";
import OrDivider from "@/components/ui/or-divider";
import { useState } from "react";

export default function Register() {
  useTitle("Register");

  const context = useAppContext();
  const [error, setError] = useState("");

  if (context.user) {
    return <AlreadyLoggedInRedirect data={context} />;
  }

  return (
    <AuthLayout
      title="Create an account"
      description={
        <>
          <span>Already have an account? </span>
          <Link
            to={getUrlWithExistingSearch("/login")}
            className="font-medium text-primary"
          >
            Log in now
          </Link>
        </>
      }
    >
      {error ? (
        <Alert variant="destructive" className="-mt-4 mb-4">
          {error}
        </Alert>
      ) : null}
      <SocialLogins verb="Sign up" onError={setError} />
      <OrDivider />
      <RegisterForm />
    </AuthLayout>
  );
}
