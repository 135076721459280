import { SVGProps } from "react";
import { ALLOWS_SIGNUP } from "@/constants/services";
import { default as AvailLogo } from "@/assets/avail.svg?react";
import { default as AvailCircle } from "@/assets/avail-circle.svg?react";

import { useBusiness } from "@/context/BusinessContext";
import pick from "lodash/pick";
import { useAppContext } from "@/context/AppContext";

export default function Logo({
  useIcon,
  ...props
}: SVGProps<SVGSVGElement> & {
  useIcon?: boolean;
}) {
  let business = useBusiness();
  const context = useAppContext();

  if (!business && "business" in context) {
    business = context.business;
  }

  const image = useIcon && business?.icon ? business?.icon : business?.logo;
  const Svg = useIcon ? AvailCircle : AvailLogo;

  if (ALLOWS_SIGNUP) {
    return <Svg {...props} />;
  }

  if (business && image) {
    return (
      <img
        src={image}
        alt={business.name}
        {...pick(props, ["style", "className"])}
      />
    );
  }

  return <Svg {...props} />;
}
