import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import axios from "axios";
import { Link } from "react-router-dom";
import { handleLaravelErrors, maybeRenderRootError } from "@/lib/form";
import { useAfterLogin } from "@/lib/auth";
import { track } from "@/lib/analytics";
import pick from "lodash/pick";

const formSchema = z.object({
  email: z.string().email(),
  password: z.string().min(8),
  remember: z.boolean(),
});

export default function LoginForm() {
  const afterLogin = useAfterLogin();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      remember: true,
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    const searchParams = new URLSearchParams(window.location.search);
    return axios
      .post(`/login`, {
        ...values,
        token: searchParams.get("token"),
      })
      .then(() => {
        track("Logged In", pick(values, ["email"]));
        return afterLogin();
      })
      .catch(handleLaravelErrors(form));
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        {maybeRenderRootError(form)}

        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input type="email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input type="password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex items-center justify-between">
          <FormField
            control={form.control}
            name="remember"
            render={({ field }) => (
              <label className="inline-flex items-center">
                <input
                  id="remember-me-checkbox"
                  name={field.name}
                  type="checkbox"
                  className="size-4 rounded border-slate-300 text-primary focus:ring-primary"
                  checked={field.value}
                  onChange={field.onChange}
                />
                <span className="ml-2">Remember me</span>
              </label>
            )}
          />

          <Button asChild variant="link" className="pr-0">
            <Link to="/forgot-password">Forgot your password?</Link>
          </Button>
        </div>

        <Button
          type="submit"
          isLoading={form.formState.isSubmitting}
          className="w-full rounded-lg"
        >
          Log In
        </Button>
      </form>
    </Form>
  );
}
