import { useShopState } from "@/context/ShopContext";
import { Navigate } from "react-router-dom";
import { Loader } from "lucide-react";

export default function ResumeShopping() {
  const state = useShopState();

  if (!state.product) {
    return <Navigate to="/shop/products" />;
  }
  if (!state.designLayout && !state.skipDecoration) {
    return <Navigate to="/shop/deecoration" />;
  }
  if (!state.variants) {
    return <Navigate to="/shop/variants" />;
  }

  return <Loader className="size-12 animate-spin" />;
}
