import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import { AttachmentPayload, ProjectActivity } from "avail-types";
import { useProject } from "@/context/ProjectContext";
import { useAppState } from "@/context/AppContext";
import { useCallback, useEffect } from "react";

interface ProjectActivityRes {
  data: ProjectActivity[];
  meta: { next_cursor: string | null };
}

export function useGetProjectActivity() {
  const project = useProject();
  return useInfiniteQuery(
    ["projectActivity", project.id],
    ({ pageParam }) =>
      axios
        .get<ProjectActivityRes>(`projects/${project.id}/activities`, {
          params: {
            cursor: pageParam,
            count: 7,
          },
        })
        .then(({ data }) => data),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.meta.next_cursor;
      },
      refetchOnMount: false,
    },
  );
}

export function useOnProjectActivityCreated() {
  const queryClient = useQueryClient();
  const projectId = useProject().id;

  return useCallback(
    (activity: ProjectActivity) => {
      queryClient.setQueryData<InfiniteData<ProjectActivityRes>>(
        ["projectActivity", projectId],
        (prev) => {
          if (!prev || prev.pages[0]?.data.some((a) => a.id === activity.id)) {
            return prev;
          }
          return {
            ...prev,
            pages: prev.pages.map((p, i) => {
              if (i === 0) {
                return {
                  ...p,
                  data: [activity, ...p.data],
                };
              }
              return p;
            }),
          };
        },
      );
    },
    [queryClient, projectId],
  );
}

export function useOnProjectActivityDeleted() {
  const queryClient = useQueryClient();
  const projectId = useProject().id;

  return useCallback(
    (id: number) => {
      queryClient.setQueryData<InfiniteData<ProjectActivityRes>>(
        ["projectActivity", projectId],
        (prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            pages: prev.pages.map((p) => ({
              ...p,
              data: p.data.filter((a) => a.id !== id),
            })),
          };
        },
      );
    },
    [queryClient, projectId],
  );
}

export function useOnProjectActivityUpdated() {
  const queryClient = useQueryClient();
  const projectId = useProject().id;

  return useCallback(
    (data: ProjectActivity) => {
      queryClient.setQueryData<InfiniteData<ProjectActivityRes>>(
        ["projectActivity", projectId],
        (prev) => {
          if (!prev) {
            return prev;
          }
          return {
            ...prev,
            pages: prev.pages.map((p) => {
              return {
                ...p,
                data: p.data.map((a) => {
                  if (a.id === data.id) {
                    return data;
                  }
                  return a;
                }),
              };
            }),
          };
        },
      );
    },
    [queryClient, projectId],
  );
}

export function useCreateProjectActivity() {
  const projectId = useProject().id;
  const onCreated = useOnProjectActivityCreated();

  return useMutation(
    (payload: { message: string; attachments: AttachmentPayload[] }) =>
      axios
        .post<ProjectActivity>(`projects/${projectId}/activities`, payload)
        .then(({ data }) => {
          onCreated(data);
          return data;
        }),
  );
}

export function useDeleteProjectActivity() {
  const projectId = useProject().id;
  const onDeleted = useOnProjectActivityDeleted();

  return useMutation((id: number) =>
    axios.delete(`projects/${projectId}/activities/${id}`).then(() => {
      onDeleted(id);
    }),
  );
}

export function useWatchForNewActivity() {
  const { Echo } = useAppState();
  const projectId = useProject().id;
  const onCreated = useOnProjectActivityCreated();
  const onUpdated = useOnProjectActivityUpdated();
  const onDeleted = useOnProjectActivityDeleted();
  const queryClient = useQueryClient();

  useEffect(() => {
    const channel = Echo.private(`projects.${projectId}`);

    channel.listen(
      "ProjectActivityCreated",
      (e: { activity: ProjectActivity }) => {
        onCreated(e.activity);
        queryClient.invalidateQueries(["project", projectId]);
        queryClient.invalidateQueries(["itemOptions"]);
      },
    );

    channel.listen(
      "ProjectActivityUpdated",
      (e: { activity: ProjectActivity }) => {
        onUpdated(e.activity);
      },
    );

    channel.listen("ProjectActivityDeleted", (e: { activityId: number }) => {
      onDeleted(e.activityId);
    });

    return () => {
      channel.stopListening("ProjectActivityCreated");
      channel.stopListening("ProjectActivityUpdated");
      channel.stopListening("ProjectActivityDeleted");
    };
  }, [Echo, projectId, onCreated, onUpdated, onDeleted, queryClient]);
}

export function useAddReaction(activityId: number) {
  const project = useProject();
  const onUpdated = useOnProjectActivityUpdated();

  return useMutation((reaction: string) =>
    axios
      .post<ProjectActivity>(
        `projects/${project.id}/activities/${activityId}/reactions`,
        {
          reaction,
        },
      )
      .then(({ data }) => {
        onUpdated(data);
        return data;
      }),
  );
}

export function useRemoveReaction(activityId: number) {
  const project = useProject();
  const onUpdated = useOnProjectActivityUpdated();

  return useMutation((reactionId: number) =>
    axios
      .delete<ProjectActivity>(
        `projects/${project.id}/activities/${activityId}/reactions/${reactionId}`,
      )
      .then(({ data }) => {
        onUpdated(data);
        return data;
      }),
  );
}
