import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

const formSchema = z.object({
  target_margin: z.coerce.number().min(0).max(100),
});

type FormShape = z.infer<typeof formSchema>;

export default function MarginFormDialog({
  initialValue,
  onSubmit,
}: {
  initialValue: number;
  onSubmit: (margin: number) => void;
}) {
  const [open, setOpen] = useState(false);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      target_margin: initialValue,
    },
  });

  const handleSubmit = (values: FormShape) => {
    onSubmit(values.target_margin);
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>({initialValue}%)</DialogTrigger>
      <DialogContent>
        <form
          onSubmit={(e) => {
            e.stopPropagation();
            return form.handleSubmit(handleSubmit)(e);
          }}
        >
          <DialogHeader>
            <DialogTitle>Change Target Margin</DialogTitle>
          </DialogHeader>

          <div className="w-full py-6">
            <FormField
              control={form.control}
              name="target_margin"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input type="number" step={1} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <DialogFooter>
            <Button variant="outline" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button type="submit">Submit</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
