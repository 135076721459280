import Title from "@/components/ui/title";
import { Loader } from "lucide-react";
import { useGetCollections } from "@/api/collection";
import CollectionLink from "@/components/collections/CollectionLink";
import { useTitle } from "@/lib/title";
import Grid from "@/components/ui/grid";
import { collectionHasProducts } from "@/lib/products";
import NeedHelpBanner from "@/components/projects/NeedHelpBanner";

export default function Collections() {
  useTitle("Explore Categories", "Collections");
  const { data: collections = [], isLoading } = useGetCollections();

  return (
    <div className="flex-1 space-y-4">
      <Title>Explore Categories</Title>

      <div className="relative space-y-8">
        {isLoading && <Loader className="size-10 animate-spin" />}

        {collections.map((collection) => {
          return (
            <div key={collection.id}>
              <h3 className="mb-2 text-xl font-semibold">{collection.name}</h3>
              {collection.children && (
                <Grid>
                  {collection.children
                    .filter(collectionHasProducts)
                    .map((child) => (
                      <CollectionLink
                        key={child.id}
                        collection={child}
                        to={`./${child.slug}`}
                      />
                    ))}
                </Grid>
              )}
            </div>
          );
        })}
      </div>

      <NeedHelpBanner />
    </div>
  );
}
