import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { PlusCircle } from "lucide-react";
import FooterCard from "@/components/ui/footer-card";
import { useSettings } from "@/context/AppContext";
import SwagSpecialist from "@/assets/graphics/swag-specialist.svg?react";

export default function NeedHelpBanner() {
  const settings = useSettings();

  if (!settings.projects_enabled) {
    return null;
  }

  return (
    <FooterCard
      Image={SwagSpecialist}
      title="Need some help finding the right swag?"
      description={
        <>
          We have access to thousands of brands and millions of products. Let
          one of our{" "}
          <b className="font-semibold text-primary">Swag Specialists</b> provide
          you with curated options.
        </>
      }
      button={
        <Button asChild>
          <Link to={`/projects/create`}>
            <PlusCircle className="mr-2 size-4" />
            Create Project
          </Link>
        </Button>
      }
    />
  );
}
