import Title, { SectionTitle } from "@/components/ui/title";
import { useParams } from "react-router-dom";
import { Skeleton } from "@/components/ui/skeleton";
import Toolbar from "@/components/ui/toolbar";
import Description from "@/components/ui/description";
import AddressBlock from "@/components/addresses/AddressBlock";
import InvoiceActions from "@/components/orders/InvoiceActions";
import OrderItems from "@/components/orders/OrderItems";
import ShipmentsTable from "@/components/orders/ShipmentsTable";
import VendorStatusTable from "@/components/orders/VendorStatusTable";
import ProofsCard from "@/components/proofs/ProofsCard";
import { useGetOrder, useUploadPurchaseOrder } from "@/api/orders";
import OrderStatusSection from "@/components/orders/OrderStatusSection";
import NotFound from "@/pages/NotFound";
import { useTitle } from "@/lib/title";
import { Card } from "@/components/ui/card";
import PurchaseOrderCard from "@/components/orders/PurchaseOrderCard";
import { formatDate } from "@/lib/dates";

export default function OrderDetail() {
  const { code } = useParams<{ code: string }>();
  const { data: order, isLoading } = useGetOrder(code!);
  const uploadPoRequest = useUploadPurchaseOrder(code!);
  useTitle(order?.increment_id, "Order");

  if (isLoading) {
    return <Skeleton className="h-8 w-[250px]" />;
  }

  if (!order) {
    return <NotFound />;
  }

  return (
    <div className="flex-1 space-y-8">
      <Toolbar
        actions={
          order.order_approved_at ? <InvoiceActions invoice={order} /> : null
        }
      >
        <Title>{order.increment_id}</Title>
        <Description>{order.description}</Description>
      </Toolbar>

      <OrderStatusSection order={order} />

      {order.waiting_on_po && (
        <PurchaseOrderCard onSubmit={(v) => uploadPoRequest.mutateAsync(v)} />
      )}

      <Card className="p-5">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 2xl:grid-cols-4">
          <div>
            <SectionTitle>Ordered By</SectionTitle>
            <p>
              {order.contact_name && <div>{order.contact_name}</div>}
              {order.contact_email && <div>{order.contact_email}</div>}
              {order.contact_phone && <div>{order.contact_phone}</div>}
            </p>
          </div>
          <div>
            <SectionTitle>Payment</SectionTitle>
            {order.terms && <p>Terms: {order.terms.name}</p>}
            {order.customer_po && <p>Purchase Order: {order.customer_po}</p>}
            {order.invoiced_at && (
              <p>Date Invoiced: {formatDate(order.invoiced_at)}</p>
            )}
            {order.due_date && <p>Date Due: {formatDate(order.due_date)}</p>}
          </div>
          <div>
            <SectionTitle>Shipping Address</SectionTitle>
            {order.shipping_address && (
              <AddressBlock address={order.shipping_address} />
            )}
          </div>
          <div>
            <SectionTitle>Billing Address</SectionTitle>
            {order.billing_address && (
              <AddressBlock address={order.billing_address} />
            )}
          </div>
        </div>
      </Card>

      <OrderItems order={order} items={order.items} />

      <ProofsCard proofs={order.proofs} />

      <ShipmentsTable shipments={order.shipments} />

      <VendorStatusTable statuses={order.vendor_statuses} />
    </div>
  );
}
