import { HTMLAttributes } from "react";
import { cn } from "@/lib/utils";

export default function StatusLight({
  className,
  ...other
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <span
      className={cn(
        "flex size-4 items-center justify-center rounded-full bg-opacity-25",
        className,
      )}
      aria-hidden="true"
      {...other}
    >
      <span className={cn("size-2 rounded-full", className)} />
    </span>
  );
}
