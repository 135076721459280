import { useState } from "react";
import { useMemberships } from "@/api/membership";
import { useCanInvite } from "@/hooks/useCanInvite";
import {
  useAddProjectUser,
  useGetProjectUsers,
  useRemoveProjectUser,
} from "@/api/project-users";
import { CustomerMembership } from "avail-types";
import { CustomerUser } from "avail-types";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import UserAvatar from "@/components/account/UserAvatar";
import ProjectUserInvite from "@/components/projects/ProjectUserInvite";

export default function ProjectUsers() {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const [userQuery, setUserQuery] = useState<string>("");
  const { data: memberships = [] } = useMemberships();
  const { data: users = [] } = useGetProjectUsers();
  const addUserRequest = useAddProjectUser();
  const removeUserRequest = useRemoveProjectUser();
  const canInvite = useCanInvite();

  const onCheckedChange = ({
    checked,
    user,
  }: {
    checked: string | boolean;
    user: CustomerUser;
  }) =>
    checked ? addUserRequest.mutate(user) : removeUserRequest.mutate(user);

  const compareNames = (m: CustomerMembership) => {
    return m.user.name.toLowerCase().includes(userQuery.toLowerCase());
  };

  const closeDialogAndPopover = () => {
    setOpenDialog(false);
    setOpenPopover(false);
    setUserQuery("");
  };

  return (
    <Popover open={openPopover} onOpenChange={setOpenPopover}>
      <PopoverTrigger className="flex items-center -space-x-2">
        {users.map((u) => (
          <UserAvatar className="size-8" user={u} key={u.id} />
        ))}
      </PopoverTrigger>
      <PopoverContent
        side="left"
        align="start"
        className="max-h-96 overflow-y-auto p-0"
      >
        <Command>
          <CommandInput
            placeholder="Change project users..."
            value={userQuery}
            onValueChange={setUserQuery}
          />
          <CommandEmpty>
            {"No users found"}
            {canInvite && (
              <div className="pt-3">
                <Button
                  onClick={() => setOpenDialog(!openDialog)}
                  variant="link"
                >
                  Invite {userQuery}?
                </Button>
              </div>
            )}
          </CommandEmpty>
          <CommandGroup>
            {memberships.filter(compareNames).map((m) => (
              <CommandItem key={m.id} value={m.user.name}>
                <Checkbox
                  checked={users.some((u) => u.id === m.user.id)}
                  onCheckedChange={(checked) =>
                    onCheckedChange({ checked, user: m.user })
                  }
                />
                <UserAvatar className="ml-3 mr-2 size-6" user={m.user} />
                <span>{m.user.name}</span>
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
      <ProjectUserInvite
        open={openDialog}
        onClose={closeDialogAndPopover}
        userQuery={userQuery}
      />
    </Popover>
  );
}
