import { useState } from "react";
import { MoreVerticalIcon, Upload } from "lucide-react";
import BulkInviteForm from "@/components/account/BulkInviteForm";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

export default function InviteActions() {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button size="icon" variant="outline" className="flex-shrink-0">
            <MoreVerticalIcon className="h-5 w-5" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onClick={() => setOpen(!open)}>
            <Upload className="mr-2 h-4 w-4" />
            Bulk Upload
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <BulkInviteForm open={open} setOpen={setOpen} />
    </>
  );
}
