import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Pencil } from "lucide-react";
import EditSkuForm from "@/components/inventory/EditSkuForm";
import { CustomerSku } from "avail-types";

export default function EditSkuButton({ sku }: { sku: CustomerSku }) {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Pencil className="size-5" />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit SKU</DialogTitle>
        </DialogHeader>
        <EditSkuForm sku={sku} onSuccess={() => setOpen(false)} />
      </DialogContent>
    </Dialog>
  );
}
