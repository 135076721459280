import { ComponentType, ReactNode } from "react";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { CONTAINER_PADDING } from "@/components/ui/container";

export default function FooterCard({
  Image,
  title,
  description,
  button,
}: {
  Image: ComponentType<{ className?: string }>;
  title: ReactNode;
  description: ReactNode;
  button: ReactNode;
}) {
  return (
    <Card
      className={cn(
        "flex flex-col items-center gap-4 py-4 md:flex-row",
        CONTAINER_PADDING,
      )}
    >
      <Image className="flex-0 mx-auto my-0 size-32 text-primary md:-my-4" />
      <CardHeader className="flex-1 items-start space-y-2">
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <div>{button}</div>
    </Card>
  );
}
