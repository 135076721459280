import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function findLowestNonZeroNumber(arr: string[]) {
  const numbers = arr.map(Number);
  const nonZeroNumbers = numbers.filter((num) => !isNaN(num) && num !== 0);
  const lowestNonZeroNumber = Math.min(...nonZeroNumbers);

  return lowestNonZeroNumber;
}
