import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import NativeSelect from "@/components/ui/native-select";
import uniq from "lodash/uniq";
import { Input } from "@/components/ui/input";
import { CartProduct } from "avail-types";
import { useFormContext } from "react-hook-form";

export default function DecorationFields({
  product,
  valuesKey,
}: {
  product: CartProduct;
  valuesKey?: string;
}) {
  const form = useFormContext();
  const values = (valuesKey ? form.watch(valuesKey) : form.watch()) || {};

  const prefix = valuesKey ? `${valuesKey}.` : "";
  const productDecorations = product.decorations || [];
  const validDecorations = productDecorations.filter((d) => {
    return (
      (!values.location || d.location === values.location) &&
      (!values.decoration || d.decoration === values.decoration)
    );
  });
  const selectedDecoration =
    validDecorations.length === 1 ? validDecorations[0]! : null;

  return (
    <>
      <FormField
        name={`${prefix}location`}
        control={form.control}
        render={({ field }) => (
          <FormItem>
            <FormLabel>Imprint Location</FormLabel>
            <FormControl>
              <NativeSelect {...field}>
                <option value="">-- Select --</option>
                {uniq(productDecorations.map((d) => d.location)).map((l) => (
                  <option value={l} key={l}>
                    {l}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        name={`${prefix}decoration`}
        control={form.control}
        render={({ field }) => (
          <FormItem>
            <FormLabel>Decoration Method</FormLabel>
            <FormControl>
              <NativeSelect {...field}>
                <option value="">-- Select --</option>
                {productDecorations
                  .filter((d) => d.location === values.location)
                  .map((d) => (
                    <option value={d.decoration} key={d.decoration}>
                      {d.decoration}
                    </option>
                  ))}
              </NativeSelect>
            </FormControl>
          </FormItem>
        )}
      />

      {selectedDecoration?.decoration_unit === "colors" && (
        <FormField
          name={`${prefix}complexity`}
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Logo Colors</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  value={field.value || ""}
                  type="number"
                  inputMode="numeric"
                  min={1}
                  max={selectedDecoration.decoration_units_max || 8}
                  step={1}
                  disabled={!selectedDecoration}
                  onFocus={(e) => e.target.select()}
                />
              </FormControl>
              {selectedDecoration.decoration_units_max ? (
                <FormDescription>
                  Maximum Colors: {selectedDecoration.decoration_units_max}
                </FormDescription>
              ) : null}
            </FormItem>
          )}
        />
      )}
    </>
  );
}
