import { HTMLAttributes } from "react";
import { cn } from "@/lib/utils";

export default function Title({
  children,
  className,
  ...other
}: HTMLAttributes<HTMLHeadingElement>) {
  return (
    <h2
      className={cn("text-2xl font-bold tracking-tight md:text-3xl", className)}
      {...other}
    >
      {children}
    </h2>
  );
}

export function SectionTitle({
  children,
  className,
  ...other
}: HTMLAttributes<HTMLHeadingElement>) {
  return (
    <h3
      className={cn(
        "mb-2 text-sm font-semibold leading-none tracking-tight",
        className,
      )}
      {...other}
    >
      {children}
    </h3>
  );
}
