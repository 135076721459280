import { useTitle } from "@/lib/title";
import Title from "@/components/ui/title";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

import { ComponentType, ReactNode, SVGProps } from "react";
import ShopSwag from "@/assets/graphics/shop-swag.svg?react";
import SwagSpecialist from "@/assets/graphics/swag-specialist.svg?react";
import Webstore from "@/assets/graphics/webstore.svg?react";
import { Link } from "react-router-dom";
import DyanmicLayout from "@/layouts/DyanmicLayout";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";

function FeatureCard({
  title,
  description,
  color,
  Image,
  recommended = false,
}: {
  title: ReactNode;
  description: ReactNode;
  color: string;
  Image: ComponentType<SVGProps<SVGSVGElement>>;
  recommended?: boolean;
}) {
  return (
    <Card
      className={cn(
        "flex h-full max-w-md flex-col rounded-xl hover:shadow-md",
        recommended && "scale-105",
      )}
    >
      <div
        className={cn(
          "h-40 w-full overflow-hidden bg-primary/5 pt-5 lg:h-56 lg:pt-10",
          color,
        )}
      >
        <Image className="h-full w-full object-contain" />
      </div>
      <CardHeader className="px-5 py-4 lg:space-y-4 lg:py-6">
        <CardTitle className="text-xl md:text-2xl">{title}</CardTitle>
        <CardDescription className="text-sm lg:text-base">
          {description}
        </CardDescription>
        <div>
          {recommended && (
            <Button variant="default">
              Get Started <ArrowRight className="ml-2 h-4 w-4" />
            </Button>
          )}
        </div>
      </CardHeader>
    </Card>
  );
}

export default function ProjectCreateLanding() {
  useTitle("Create Project Landing");

  return (
    <DyanmicLayout isCollapsed={false}>
      <div className="mx-auto w-full max-w-6xl py-4 text-center lg:py-12">
        <Title>How would you like to shop swag today?</Title>

        <div className="mt-5 grid justify-center gap-x-8 gap-y-4 lg:mt-12 xl:grid-cols-3">
          <Link to="/collections">
            <FeatureCard
              title={
                <>
                  I'd like to <span className="text-violet-400">Shop Swag</span>{" "}
                  myself
                </>
              }
              description="Dive into our self-service option. Select from our top-rated swag collection, all at your own pace."
              color="text-violet-400"
              Image={ShopSwag}
            />
          </Link>

          <Link to="/projects/create">
            <FeatureCard
              title={
                <>
                  Work with a
                  <span className="text-primary"> Swag Specialist</span>
                </>
              }
              description="Save time and have us handpick the best items to meet your projects unique needs."
              color="text-primary"
              Image={SwagSpecialist}
              recommended
            />
          </Link>

          <Link to="/webstores/create">
            <FeatureCard
              title={
                <>
                  Start a <span className="text-indigo-700">Swag Store</span>
                </>
              }
              description="We'll help you set up a branded store where employees and clients can order your swag."
              color="text-violet-700"
              Image={Webstore}
            />
          </Link>
        </div>
      </div>
    </DyanmicLayout>
  );
}
