import { UseQueryResult } from "@tanstack/react-query";
import PriceDetails, { Price } from "@/components/products/PriceDetails";
import { OrderCreatePayload } from "avail-types";
import sumBy from "lodash/sumBy";

function getUnitPriceFromLineItems(
  lineItems: { price: number; qty: number }[],
  totalQty: number,
) {
  return (
    lineItems.reduce((acc, item) => acc + item.price * item.qty, 0) / totalQty
  );
}

export default function AddToCartPreview({
  previewQuery,
}: {
  previewQuery: UseQueryResult<OrderCreatePayload>;
}) {
  if (previewQuery.isFetching) {
    return <div className="h-4 w-32 animate-pulse bg-muted" />;
  }

  if (previewQuery.data) {
    return (
      <div className="flex items-start justify-between">
        <PriceDetails
          unitPrice={getUnitPriceFromLineItems(
            previewQuery.data.items!.filter((i) => !i.fee),
            previewQuery.data.total_qty!,
          )}
          fees={previewQuery.data
            .items!.filter((i) => i.fee)
            .map((l) => ({
              name: l.description,
              price: l.price * l.qty,
            }))}
          size="lg"
        />
        <Price
          label="total"
          value={sumBy(previewQuery.data.items!, (i) => i.qty * i.price)}
          size="lg"
        />
      </div>
    );
  }

  return null;
}
