import { useGetCart } from "@/api/cart";
import { Navigate } from "react-router-dom";
import CheckoutForm from "@/components/shop/CheckoutForm";
import { Loader } from "lucide-react";

export default function ShopCheckout() {
  const { data: cart, isFetching } = useGetCart();

  const cartIsLoaded = cart && !isFetching;

  if (cartIsLoaded) {
    if (cart.items.length === 0) {
      return <Navigate to="/shop" />;
    }

    if (cart.items.length > 0) {
      return <CheckoutForm cart={cart} />;
    }
  }

  return <Loader className="size-12 animate-spin" />;
}
