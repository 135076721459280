import { ReactNode } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";

export default function StatCard({
  title,
  value,
  isLoading,
}: {
  title: ReactNode;
  value: ReactNode;
  isLoading?: boolean;
}) {
  return (
    <Card>
      <CardHeader>
        {isLoading ? (
          <Skeleton className="h-4 w-[100px]" />
        ) : (
          <CardTitle className="text-sm font-medium">{title}</CardTitle>
        )}
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <Skeleton className="h-6" />
        ) : (
          <div className="text-2xl font-bold">{value}</div>
        )}
      </CardContent>
    </Card>
  );
}
