import Title from "@/components/ui/title";
import Toolbar from "@/components/ui/toolbar";
import ApprovalsCard from "@/components/dashboard/ApprovalsCard";
import ActivityCard from "@/components/dashboard/ActivityCard";
import ProjectsSummaryCard from "@/components/dashboard/ProjectsSummaryCard";
import { useSettings } from "@/context/AppContext";
import { useTitle } from "@/lib/title";
import * as Frigade from "@frigade/react";
import { ALLOWS_SIGNUP } from "@/constants/services";

export default function Dashboard() {
  const settings = useSettings();
  useTitle("Dashboard");

  return (
    <div className="flex-1 space-y-4">
      <Toolbar>
        <Title>Dashboard</Title>
      </Toolbar>

      <div className="grid gap-6 lg:grid-cols-2">
        <ApprovalsCard />
        {settings.projects_enabled && <ProjectsSummaryCard />}
        <ActivityCard className="lg:col-span-2" />
      </div>

      {ALLOWS_SIGNUP && (
        <Frigade.Announcement flowId="flow_fnHeoc9X" dismissible />
      )}
    </div>
  );
}
