import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { FEES } from "@/constants/project";
import startCase from "lodash/startCase";
import CurrencyInput from "@/components/ui/currency-input";
import { X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Input } from "@/components/ui/input";

export default function FeeTable() {
  const { control } = useFormContext<{
    fees: {
      name: string;
      price: number;
      cost?: number;
      qty?: number;
    }[];
  }>();

  const feesField = useFieldArray({
    control,
    name: "fees",
  });

  return (
    <div className="-mx-6">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="pl-6">Fee</TableHead>
            <TableHead>Price</TableHead>
            <TableHead>Cost</TableHead>
            <TableHead>Qty</TableHead>
            <TableHead />
          </TableRow>
        </TableHeader>
        <TableBody>
          {feesField.fields.map((f, i) => (
            <TableRow key={f.id}>
              <TableCell className="w-2/5 pl-6 pr-2">
                <FormField
                  control={control}
                  name={`fees.${i}.name`}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Select
                          value={field.value}
                          onValueChange={field.onChange}
                        >
                          <SelectTrigger className="[&>span]:truncate">
                            <SelectValue placeholder="Select a fee" />
                          </SelectTrigger>
                          <SelectContent className="max-h-[20rem] overflow-y-auto">
                            {FEES.map((fee) => (
                              <SelectItem key={fee} value={fee}>
                                {startCase(fee)}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </TableCell>
              <TableCell className="w-1/5 px-2">
                <FormField
                  control={control}
                  name={`fees.${i}.price`}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <CurrencyInput {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </TableCell>
              <TableCell className="w-1/5 px-2">
                <FormField
                  control={control}
                  name={`fees.${i}.cost`}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <CurrencyInput {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </TableCell>
              <TableCell className="w-1/5">
                <FormField
                  control={control}
                  name={`fees.${i}.qty`}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          type="number"
                          inputMode="numeric"
                          step={1}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </TableCell>
              <TableCell>
                <button type="button" onClick={() => feesField.remove(i)}>
                  <X className="size-4 text-muted-foreground" />
                </button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="px-6">
        <Button
          type="button"
          variant="ghost"
          onClick={() =>
            feesField.append({
              name: "",
              price: 0,
              qty: 1,
            })
          }
        >
          Add Fee
        </Button>
      </div>
    </div>
  );
}
