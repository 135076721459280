import { Project } from "avail-types";
import { Progress } from "@/components/ui/progress";

export default function ProjectProgressBar({
  project,
  className,
}: {
  project: Project;
  className: string;
}) {
  const { orders } = project;
  return (
    <Progress
      className={className}
      value={Math.max(
        (orders.filter((o) => o.status === "Complete").length / orders.length) *
          100,
        5,
      )}
    />
  );
}
