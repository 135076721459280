import { Key, MutableRefObject, useRef, useState } from "react";
import TablePagination from "@/components/ui/pagination";
import "swiper/css";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperInstance } from "swiper/types";

export default function ImageCarousel({
  children,
  renderChild,
}: {
  children: string[];
  renderChild: (child: string) => JSX.Element;
}) {
  const swiperRef = useRef<SwiperInstance | null>(
    null,
  ) as MutableRefObject<SwiperInstance | null>;
  const [page, setPage] = useState<number>(0);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    swiperRef.current?.slideTo(newPage);
  };

  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        scrollbar={{ draggable: true }}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        onSlideChange={(swiper) => setPage(swiper.activeIndex)}
      >
        {children.map((child: any, index: Key | null | undefined) => (
          <SwiperSlide key={index}>{renderChild(child)}</SwiperSlide>
        ))}
      </Swiper>
      {children.length > 1 ? (
        <TablePagination
          pageIndex={page}
          totalPages={children.length}
          canGetPrevious={page > 0}
          onPrevious={() => handleChangePage(page - 1)}
          canGetNextPage={page < children.length - 1}
          onNext={() => handleChangePage(page + 1)}
        />
      ) : null}
    </>
  );
}
