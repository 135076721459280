import { useAppContext } from "@/context/AppContext";
import AuthRedirect from "@/components/auth/AuthRedirect";
import { ReactNode } from "react";
import BusinessContext from "@/context/BusinessContext";

export default function Authenticated({ children }: { children: ReactNode }) {
  const context = useAppContext();

  if (!context.user) {
    return <AuthRedirect to="/login" />;
  }
  if (context.user.email_verified_at === null) {
    return <AuthRedirect to="/verify-email" />;
  }
  if (!context.membership) {
    return <AuthRedirect to="/onboarding" />;
  }

  return (
    <BusinessContext.Provider value={{ business: context.business }}>
      {children}
    </BusinessContext.Provider>
  );
}
