import AppLayout from "@/layouts/AppLayout";
import { Outlet, useMatches } from "react-router-dom";
import Authenticated from "@/layouts/Authenticated";

export default function AppRoot() {
  const isCollapsed = useMatches().some((match) => {
    // @ts-ignore
    return match.handle?.collapsed;
  });
  return (
    <Authenticated>
      <AppLayout isCollapsed={isCollapsed}>
        <Outlet />
      </AppLayout>
    </Authenticated>
  );
}
