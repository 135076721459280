import { Button } from "@/components/ui/button";
import { CartProduct } from "avail-types";
import { useAddItem, useCartPreview } from "@/api/cart";
import { Input } from "@/components/ui/input";
import { useParams } from "react-router-dom";
import EmptyState from "@/components/ui/empty-state";
import { CircleOffIcon } from "lucide-react";
import AddToCartPreview from "@/components/cart/AddToCartPreview";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import pick from "lodash/pick";
import DecorationFields from "@/components/products/DecorationFields";

const formSchema = z.object({
  location: z.string(),
  decoration: z.string(),
  complexity: z.coerce.number().int().min(1),
  qty: z.coerce.number().int().min(1),
});

export default function AdSpecialityAddToCartForm({
  product,
}: {
  product: CartProduct;
}) {
  const variantId = product.variants.filter(
    (v) => v.vendor_inventory_qty === null || v.vendor_inventory_qty > 0,
  )[0]?.id;
  const minQty = product.min_qty || 1;
  const productDecorations = product.decorations || [];

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      complexity: 1,
      location:
        productDecorations.length === 1 ? productDecorations[0]!.location : "",
      decoration:
        productDecorations.length === 1
          ? productDecorations[0]!.decoration
          : "",
    },
  });

  const addToCartRequest = useAddItem();
  const { collection } = useParams<{ collection: string }>();
  const values = form.watch();

  const valuesToPayload = (values: z.infer<typeof formSchema>) => ({
    variants: [
      {
        variant_id: variantId!,
        qty: values.qty,
      },
    ],
    collection,
    product_id: product.id,
    decoration: pick(values, ["location", "decoration", "complexity"]),
  });

  const previewQuery = useCartPreview(
    valuesToPayload(values),
    form.formState.isValid && values.qty >= minQty,
  );

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    addToCartRequest.mutateAsync(valuesToPayload(values)).then(() => {
      form.reset();
    });
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="mt-4 max-w-lg space-y-4"
      >
        <DecorationFields product={product} />

        {!variantId ? (
          <EmptyState
            Icon={CircleOffIcon}
            title="Out of Stock"
            description="This product is currently out of stock. Please check back later."
          />
        ) : (
          <>
            <FormField
              name="qty"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Quantity</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      value={field.value || ""}
                      type="number"
                      inputMode="numeric"
                      min={minQty}
                      step={1}
                      onFocus={(e) => e.target.select()}
                    />
                  </FormControl>

                  {minQty > 1 && (
                    <FormDescription>
                      Minimum Quantity: {minQty}
                    </FormDescription>
                  )}
                </FormItem>
              )}
            />

            <AddToCartPreview previewQuery={previewQuery} />
          </>
        )}

        {variantId && (
          <Button
            isLoading={addToCartRequest.isLoading}
            type="submit"
            className="w-full"
            disabled={!form.formState.isValid}
          >
            Add to Cart
          </Button>
        )}
      </form>
    </Form>
  );
}
