import { ReactNode } from "react";
import { Badge } from "@/components/ui/badge";
import EnhancedText from "@/components/ui/enhanced-text";
import Title, { SectionTitle } from "@/components/ui/title";
import PriceDetails from "@/components/products/PriceDetails";
import ProductImages from "@/components/products/ProductImages";
import { ProjectItem, ProjectOption } from "avail-types";
import ProjectOptionTags from "@/components/projects/ProjectOptionTags";
import { getItemName } from "@/lib/projects";

export default function ProjectOptionDetails({
  children,
  option,
  item,
}: {
  children: ReactNode;
  option: ProjectOption;
  item?: ProjectItem;
}) {
  if (!option) {
    return null;
  }

  return (
    <div className="grid grid-cols-2">
      <div className="pr-12">
        <ProductImages key={option.id} images={option.images} />
        <div className="mt-6">
          <SectionTitle>Product Description</SectionTitle>
          <EnhancedText className="text-sm text-muted-foreground">
            {option.description}
          </EnhancedText>
        </div>
      </div>
      <div className="flex flex-col items-start gap-1">
        <div className="flex gap-1">
          {item ? <Badge>{getItemName(item)}</Badge> : null}
          <ProjectOptionTags option={option} expanded />
        </div>
        <Title className="mt-1 text-2xl">{option.name}</Title>
        <PriceDetails
          unitPrice={option.price}
          numUnits={option.price_qty}
          isStartingAt={option.price_qty === option.min_qty}
          fees={option.fees}
          size="lg"
        />
        {children}
      </div>
    </div>
  );
}
