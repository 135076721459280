import { HTMLAttributes } from "react";
import PrimaryNavigation from "@/components/navigation/PrimaryNavigation";
import { cn } from "@/lib/utils";
import { CONTAINER_PADDING } from "@/components/ui/container";

export default function AppLayout({
  children,
  className,
  isCollapsed,
  ...props
}: HTMLAttributes<HTMLDivElement> & { isCollapsed?: boolean }) {
  return (
    <main>
      <PrimaryNavigation isCollapsed={isCollapsed} />
      <div
        className={cn(
          "relative py-8 pt-20 lg:pt-6 print:pt-4",
          CONTAINER_PADDING,
          className,
          isCollapsed ? "lg:ml-14" : "lg:ml-[17rem]",
        )}
        {...props}
      >
        {children}
      </div>
    </main>
  );
}
