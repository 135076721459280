import { useState } from "react";
import { useAppContext } from "@/context/AppContext";
import ExistingCustomer from "@/components/onboarding/ExistingCustomer";
import AuthRedirect from "@/components/auth/AuthRedirect";
import AlreadyLoggedInRedirect from "@/components/auth/AlreadyLoggedInRedirect";
import { useTitle } from "@/lib/title";
import { ALLOWS_SIGNUP } from "@/constants/services";
import SignupNotAllowed from "@/components/onboarding/SignupNotAllowed";
import NewCustomer from "@/components/onboarding/NewCustomer";

export default function Onboarding() {
  useTitle("New User Onboarding");

  const context = useAppContext();
  const { user, membership } = context;
  const [bypassExistingCustomer, setBypassExistingCustomer] = useState(false);

  if (!user) {
    return <AuthRedirect to="/login" />;
  }

  if (membership) {
    return <AlreadyLoggedInRedirect data={context} />;
  }

  if (context.existing_customer && !bypassExistingCustomer) {
    return (
      <ExistingCustomer
        existingCustomer={context.existing_customer}
        onBypass={() => setBypassExistingCustomer(true)}
      />
    );
  }

  if (!ALLOWS_SIGNUP) {
    return <SignupNotAllowed />;
  }

  return <NewCustomer user={user} />;
}
