import EmptyState from "@/components/ui/empty-state";
import { FolderPlus, PlusCircle } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

export default function ProjectsEmptyState() {
  return (
    <EmptyState
      title="No projects"
      description="Get started by creating a new project."
      Icon={FolderPlus}
    >
      <Button asChild>
        <Link to="/create-project">
          <PlusCircle className="mr-2 size-4" />
          New Project
        </Link>
      </Button>
    </EmptyState>
  );
}
