import Logo from "@/components/auth/Logo";
import { Card, CardHeader } from "@/components/ui/card";
import { ReactNode } from "react";

export default function CardPage({
  title,
  description,
  children,
}: {
  title: string;
  description?: string;
  children?: ReactNode;
}) {
  return (
    <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Logo className="mx-auto -mt-10 mb-6 h-12 w-auto" />

        <Card>
          <CardHeader className="space-y-3 text-center">
            <h2 className="text-2xl font-semibold leading-9 tracking-tight text-slate-900">
              {title}
            </h2>
            {description && (
              <p className="max-w text-center text-sm leading-5 text-slate-600">
                {description}
              </p>
            )}
          </CardHeader>
          {children}
        </Card>
      </div>
    </div>
  );
}
