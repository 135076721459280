export const APP_ENV =
  import.meta.env.VITE_ENV || (import.meta.env.DEV ? "local" : "production");

export const API_BASE_URL =
  import.meta.env.VITE_BASE_URL || "http://avail.test/";

export const MICROSOFT_CLIENT_ID = "8171964a-b89c-47d8-9eac-6e84b115773f";

export const GOOGLE_CLIENT_ID =
  "944741344614-o51br5nmah6uc6k07r00i3onlukjvn4b.apps.googleusercontent.com";

export const GOOGLE_API_KEY = "AIzaSyBS51vgSjGL5IwmTIuQmnwVxO4HxdHft5Q";

export const ALLOWS_SIGNUP = import.meta.env.VITE_ALLOWS_SIGNUP === "true";
