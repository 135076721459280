import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppContext } from "@/context/AppContext";
import GuestLayout from "@/layouts/GuestLayout";
import Container from "@/components/ui/container";

export default function GuestRoot() {
  const context = useAppContext();
  const { pathname } = useLocation();

  if (context.user && context.membership) {
    return <Navigate to={pathname.replace("/guest", "")} replace />;
  }

  return (
    <GuestLayout>
      <Container className="pb-20">
        <Outlet />
      </Container>
    </GuestLayout>
  );
}
