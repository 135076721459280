import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { HTMLAttributes } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Project } from "avail-types";
import { PieChart } from "react-minimal-pie-chart";
import groupBy from "lodash/groupBy";
import { Badge } from "@/components/ui/badge";
import ProjectsEmptyState from "@/components/projects/ProjectsEmptyState";
import { useNavigate } from "react-router-dom";
import {
  getProjectStatusHexColor,
  getProjectStatusLabel,
  PROJECT_STATUS_COLORS,
} from "@/lib/projects";

export default function ProjectsSummaryCard(
  props: HTMLAttributes<HTMLDivElement>,
) {
  const navigate = useNavigate();
  const { data: projects } = useQuery(["projectsSummary"], () =>
    axios
      .get<{ data: Project[] }>("/projects", {
        params: {
          open_status: "not_cancelled",
          count: 1000,
        },
      })
      .then(({ data }) => data.data),
  );

  const projectEntries = Object.entries(
    groupBy(projects, (p) => getProjectStatusLabel(p)),
  );
  return (
    <Card {...props}>
      <CardHeader>
        <CardTitle>Projects</CardTitle>
        <CardDescription>Here's a snapshot of your projects</CardDescription>
      </CardHeader>

      <CardContent>
        {projects && projects.length ? (
          <div className="flex gap-6">
            <div>
              <PieChart
                radius={40}
                animate
                onClick={() => navigate("/projects")}
                segmentsStyle={{ transition: "stroke .3s", cursor: "pointer" }}
                segmentsShift={1}
                data={projectEntries.map(
                  ([statusLabel, projectsForStatus]) => ({
                    title: statusLabel,
                    value: projectsForStatus.length / projects.length,
                    color: getProjectStatusHexColor(
                      statusLabel as keyof typeof PROJECT_STATUS_COLORS,
                    ),
                  }),
                )}
              />
            </div>
            <div className="flex flex-col justify-center gap-3">
              {projectEntries.map(([statusLabel, projectsForStatus]) => (
                <div
                  className="flex items-center whitespace-nowrap"
                  key={statusLabel}
                >
                  <div
                    className="mr-2 size-3 rounded-full"
                    style={{
                      backgroundColor: getProjectStatusHexColor(
                        statusLabel as keyof typeof PROJECT_STATUS_COLORS,
                      ),
                    }}
                  ></div>
                  <div className="flex items-center space-x-2 text-sm font-medium">
                    <span>{statusLabel}</span>
                    <Badge variant="outline">{projectsForStatus.length}</Badge>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}

        {projects && projects.length === 0 ? <ProjectsEmptyState /> : null}
      </CardContent>
    </Card>
  );
}
