import ProjectPayButton from "@/components/projects/ProjectPayButton";
import { Button } from "@/components/ui/button";
import { FileText } from "lucide-react";
import { useProject } from "@/context/ProjectContext";
import { useDownloadProject } from "@/api/project";

export default function ProjectInvoiceActions() {
  const project = useProject();
  const downloadRequest = useDownloadProject(project.id);

  return (
    <div className="flex gap-2">
      <ProjectPayButton project={project} />
      <Button
        variant="outline"
        onClick={() => downloadRequest.mutate()}
        isLoading={downloadRequest.isLoading}
      >
        <FileText className="mr-2 size-4" /> Invoice
      </Button>
    </div>
  );
}
