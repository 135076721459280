import { useState } from "react";
import { XIcon } from "lucide-react";
import { CustomerUserInvite } from "avail-types";
import { useCanInvite } from "@/hooks/useCanInvite";
import { useGetInvites, useRemoveInviteRequest } from "@/api/invites";
import InviteTeamMemberForm from "@/components/account/InviteTeamMemberForm";
import InviteActions from "@/components/account/InviteActions";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { PaginateList } from "@/components/ui/paginate-list";
import EmptyState from "@/components/ui/empty-state";

export default function InvitesCard() {
  const [nameFilter, setNameFilter] = useState<string>("");
  const { data: invites = [] } = useGetInvites();
  const removeInviteRequest = useRemoveInviteRequest();

  const handleDeleteInvite = (i: CustomerUserInvite) => () => {
    if (confirm("Are you sure?")) {
      removeInviteRequest.mutate(i.id);
    }
  };

  const canInvite = useCanInvite();

  const filterItem = (invite: CustomerUserInvite, query: string) => {
    return invite.email.toLowerCase().includes(query.toLowerCase());
  };

  function renderChildren(invite: CustomerUserInvite): JSX.Element {
    return (
      <li key={invite.id} className="flex items-center space-x-3 px-5 py-3">
        <div className="flex-grow flex-col">
          <div>{invite.email}</div>
          <div className="text-sm text-muted-foreground">Invite Sent</div>
        </div>
        <div className="ml-auto flex items-center space-x-2">
          <Badge variant="gray">{invite.role}</Badge>
          {canInvite && (
            <button type="button" onClick={handleDeleteInvite(invite)}>
              <XIcon className="size-4 text-slate-500" />
            </button>
          )}
        </div>
      </li>
    );
  }

  return (
    <Card>
      <CardHeader className="flex flex-row items-center gap-4 space-y-0">
        <CardTitle className="flex-grow">Invitations To Team Members</CardTitle>
        {invites.length > 6 && (
          <Input
            type="search"
            value={nameFilter}
            placeholder="Search by email"
            className="flex-0 w-64"
            onChange={(e) => setNameFilter(e.target.value)}
          />
        )}
      </CardHeader>
      <CardContent>
        {canInvite && (
          <div className="flex items-center gap-2">
            <div className="flex-grow">
              <InviteTeamMemberForm />
            </div>
            <InviteActions />
          </div>
        )}
        {invites.length > 0 ? (
          <PaginateList
            filterItem={filterItem}
            items={invites}
            itemsPerPage={10}
            query={nameFilter}
            renderChildren={renderChildren}
          />
        ) : (
          <EmptyState
            title="No outstanding invites"
            description="Invite team members to join your account above."
          />
        )}
      </CardContent>
    </Card>
  );
}
