import { imageUrl } from "@/lib/image";
import ImageAnchor from "@/components/ui/image-anchor";
import { Proof } from "avail-types";
import { HTMLAttributes } from "react";
import ImageCarousel from "@/components/proofs/ImageCarousel";

interface ProofImageProps extends HTMLAttributes<HTMLAnchorElement> {
  proof: Proof;
}

export default function ProofImage({ proof, ...other }: ProofImageProps) {
  return (
    <div className="max-w-2xl">
      <ImageCarousel
        children={proof.images}
        renderChild={(image: string) => {
          return (
            <ImageAnchor href={proof.pdf || image} {...other}>
              <img
                src={imageUrl(image, { w: 600 })}
                alt={proof.file_name}
                className="w-full"
              />
            </ImageAnchor>
          );
        }}
      />
    </div>
  );
}
