import { useEffect, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "@/components/ui/data-table";
import {
  Address,
  CustomerFulfillmentOrder,
  FulfillmentOrderItem,
} from "avail-types";
import {
  useExport,
  useManualTable,
  useManualTableQuery,
  useManualTableState,
} from "@/lib/table";
import FulfillmentOrderStatus from "@/components/fulfillmentOrders/FulfillmentOrderStatus";
import { ArrowRight, ExternalLink } from "lucide-react";
import { Link } from "react-router-dom";
import { DateRange } from "react-day-picker";
import { DataTableActions } from "@/components/ui/data-table-actions";
import { formatDate } from "@/lib/dates";

const COLUMNS: ColumnDef<CustomerFulfillmentOrder>[] = [
  {
    accessorKey: "number",
    header: "Order",
    cell: ({ getValue, row }) => (
      <Link
        to={`/fulfillment-orders/${row.original.id}`}
        className="font-medium"
      >
        {getValue<number>()}
      </Link>
    ),
  },
  {
    accessorKey: "date",
    header: "Date Ordered",
    cell: ({ getValue }) => <span>{formatDate(getValue<string>())}</span>,
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ getValue }) => {
      return (
        <FulfillmentOrderStatus
          status={getValue<CustomerFulfillmentOrder["status"]>()}
        />
      );
    },
  },
  {
    accessorKey: "address",
    header: "Recipient",
    cell: ({ getValue, row }) => {
      const address = getValue<Address | null>();
      if (!address) {
        return null;
      }
      return (
        <div className="flex gap-4">
          <div>
            <div>{address.name}</div>
            <div className="text-xs text-muted-foreground">
              {address.city}, {address.state} {address.zip}
            </div>
          </div>
          {row.original.shipments?.[0] && (
            <a
              href={row.original.shipments[0].tracking_url || "#"}
              target="_blank"
              className="inline-flex items-center text-xs font-medium text-primary"
            >
              Track
              <ExternalLink className="ml-2 size-4" />
            </a>
          )}
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: "items",
    header: "Items",
    cell: ({ getValue }) => {
      const items = getValue<FulfillmentOrderItem[]>();
      return (
        <div className="text-xs text-muted-foreground">
          {items.map((i) => (
            <div key={i.id}>
              {i.description} x {i.qty}
            </div>
          ))}
        </div>
      );
    },
    enableSorting: false,
  },
  {
    accessorKey: "customer_po",
    header: "Customer PO",
    cell: ({ getValue }) => <span>{getValue<string>()}</span>,
  },
  {
    accessorKey: "user.name",
    header: "User",
  },
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => (
      <div className="space-x-2">
        <Link to={`/fulfillment-orders/${row.original.id}`}>
          <ArrowRight className="size-5 text-muted-foreground" />
        </Link>
      </div>
    ),
  },
];

export default function FulfillmentOrderTable() {
  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const tableState = useManualTableState(
    [
      {
        id: "created_at",
        desc: true,
      },
    ],
    {
      filter: {
        date: {
          gte: dateRange?.from,
          lte: dateRange?.to,
        },
      },
    },
  );

  useEffect(() => {
    tableState.setFilters((prev) => ({
      ...prev,
      filter: {
        date: {
          gte: dateRange?.from,
          lte: dateRange?.to,
        },
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const query = useManualTableQuery<CustomerFulfillmentOrder>(
    "/fulfillment-orders",
    tableState,
  );

  const table = useManualTable(tableState, query, COLUMNS, {
    customer_po: false,
    user_name: false,
  });
  const downloadRequest = useExport("/fulfillment-orders", tableState);

  return (
    <DataTable
      table={table}
      isLoading={query.isLoading}
      header={
        <DataTableActions
          canFilterByWebstore
          dateRange={dateRange}
          setDateRange={setDateRange}
          filters={tableState.filters}
          setFilters={tableState.setFilters}
          placeholder="Search by Order Number or Recipient"
          downloadRequest={downloadRequest}
          setQuery={tableState.setQuery}
          table={table}
        />
      }
    />
  );
}
