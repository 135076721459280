import { HTMLAttributes, useEffect, useRef, useState } from "react";
import debounce from "lodash/debounce";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { ChevronDown } from "lucide-react";

interface ClampedTextProps extends HTMLAttributes<HTMLDivElement> {
  clampedClass: string;
}
export default function ClampedText({
  clampedClass,
  className,
  ...props
}: ClampedTextProps) {
  const [clamped, setClamped] = useState(true);
  const [showButton, setShowButton] = useState(true);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const hasClamping = (el: HTMLDivElement) => {
      const { clientHeight, scrollHeight } = el;
      return clientHeight !== scrollHeight;
    };

    const checkButtonAvailability = () => {
      if (!containerRef.current) {
        return;
      }
      // Save current state to reapply later if necessary.
      const hadClampClass =
        containerRef.current.classList.contains(clampedClass);
      // Make sure that CSS clamping is applied if aplicable.
      if (!hadClampClass) {
        containerRef.current.classList.add(clampedClass);
      }
      // Check for clamping and show or hide button accordingly.
      setShowButton(hasClamping(containerRef.current));
      // Sync clamping with local state.
      if (!hadClampClass) containerRef.current.classList.remove(clampedClass);
    };

    const debouncedCheck = debounce(checkButtonAvailability, 50);

    checkButtonAvailability();
    window.addEventListener("resize", debouncedCheck);

    return () => {
      window.removeEventListener("resize", debouncedCheck);
    };
  }, [containerRef]);

  return (
    <div className="space-y-3">
      <div
        ref={containerRef}
        className={cn(className, clamped ? clampedClass : "")}
        {...props}
      />
      {showButton && (
        <Button
          size="xs"
          variant="outline"
          className="transition-transform"
          onClick={() => setClamped((prev) => !prev)}
        >
          Read {clamped ? "more" : "less"}
          <ChevronDown
            className={cn("ml-1.5 size-4 transform duration-300", {
              "rotate-180": !clamped,
            })}
            size={16}
          />
        </Button>
      )}
    </div>
  );
}
