import { CustomerOrderApproval } from "avail-types";

export default function Disclaimers({
  approval,
}: {
  approval: CustomerOrderApproval;
}) {
  const availAndAff = `Avail, ${approval.business_name}, and affiliates`;
  const availOrAff = `Avail, ${approval.business_name}, or affiliates`;

  return (
    <div className="disclaimers text-xs italic text-slate-800">
      <p className="mb-3">
        By approving this order you are accepting all of the terms of this
        disclaimer notice, including exclusions and limitations of liability.
      </p>
      <p className="mb-3">
        This {approval.label} may contain licensed, copyrighted and or
        trademarked images, logos or artwork infringing on copyright and or
        trademark laws. We make no efforts to ensure that provided or requested
        artwork is licensed or free of nay copyright and or trademark
        protection. Art proofs are provided on an “as is” basis, without
        assurances of any kind. {availAndAff} disclaim all assurances, express
        or implied, with respect to the artwork and its contents, including,
        without limitations, non-infringement, title licensing, copyrights, and
        or trademarks. {availAndAff} do not ensure that the provided or
        requested artwork, image, or logo are non-infringement properties and
        you accept that it is your responsibility to obtain rights, permission,
        and make adequate provision for protection against such risks.
      </p>
      <p className="mb-3">
        In no event will {availOrAff} be liable for any incidental, indirect,
        consequential, or special damages of any kind, or any legal damages
        whatsoever, including, without limitation, those resulting from loss of
        profits, income, contracts or business relationships, whether or not{" "}
        {availAndAff} have been advised of the possibility of such damage,
        arising out of or in connection with the use of the content provided or
        requested by you.
      </p>
    </div>
  );
}
