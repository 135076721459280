import { CardContent, CardFooter } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Alert } from "@/components/ui/alert";
import CardPage from "@/layouts/CardPage";

export default function ExistingCustomer({
  existingCustomer,
  onBypass,
}: {
  existingCustomer: { id: number; admin_email: string };
  onBypass: () => void;
}) {
  const requestToJoin = useMutation(() =>
    axios.post("invites/request", {
      customer_id: existingCustomer.id,
    }),
  );

  return (
    <CardPage
      title="Existing Company"
      description={`It looks like there is already a company associated with your email
            domain. You can send a request to ${existingCustomer.admin_email} to
            join the existing company or continue with creating a new one.`}
    >
      {requestToJoin.status === "success" && (
        <CardContent>
          <Alert variant="success" className="mb-4">
            Your request to join has been sent to {existingCustomer.admin_email}
            .
          </Alert>
        </CardContent>
      )}

      <CardFooter className="space-x-2">
        <Button
          onClick={() => requestToJoin.mutate()}
          isLoading={requestToJoin.isLoading}
        >
          Request to join existing company
        </Button>
        <Button variant="ghost" onClick={onBypass}>
          Create new company
        </Button>
      </CardFooter>
    </CardPage>
  );
}
