import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { Link } from "react-router-dom";
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import { Coffee } from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { CustomerOrderApproval } from "avail-types";
import EmptyState from "@/components/ui/empty-state";
import { formatDateTime, formatRelative } from "@/lib/dates";

const getImage = (a: CustomerOrderApproval) =>
  a.order.layouts[0]?.proof_images[0];

export default function ApprovalsCard() {
  const { data: approvals = [] } = useQuery(["approvals"], () =>
    axios
      .get<{ data: CustomerOrderApproval[] }>(`/approvals`)
      .then(({ data }) => data.data),
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle>Pending Approvals</CardTitle>
      </CardHeader>
      {approvals.length > 0 ? (
        <div className="divide-y divide-muted">
          {approvals.map((a) => (
            <Link
              className="flex w-full items-center space-x-4 px-4 py-3 transition-colors hover:bg-slate-50"
              key={a.id}
              to={`/approvals/${a.code}`}
            >
              <Avatar className="flex-0 size-10">
                {getImage(a) && <AvatarImage src={getImage(a)} />}
              </Avatar>
              <div className="flex flex-grow flex-col items-start">
                <div className="font-medium">
                  {a.label} {a.order.increment_id}
                </div>
                <div className="text-sm">{a.order.description}</div>
              </div>
              <time
                className="flex-0 text-xs text-muted-foreground"
                dateTime={a.created_at}
                title={formatDateTime(a.created_at)}
              >
                {formatRelative(a.created_at, { style: "short" })}
              </time>
            </Link>
          ))}
        </div>
      ) : (
        <EmptyState
          title="No pending approvals"
          description="You have no approvals to review at this time."
          Icon={Coffee}
          className="mt-10"
        />
      )}
    </Card>
  );
}
