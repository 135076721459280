import { useState } from "react";
import { useUpdateProject } from "@/api/project";
import { canEdit } from "@/lib/projects";
import { useProject } from "@/context/ProjectContext";
import { Input } from "@/components/ui/input";
import Title from "@/components/ui/title";

export default function ProjectTitle() {
  const project = useProject();
  const projectName = project.name;
  const updateProject = useUpdateProject(project.id);
  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
  const [name, setName] = useState<string>("");

  const handleBlur = () => {
    if (name !== projectName) {
      updateProject.mutateAsync({ name });
    }
    setIsEditingTitle(false);
  };

  const handleEdit = () => {
    setName(projectName);
    setIsEditingTitle(true);
  };

  if (!canEdit(project)) {
    return <Title>{projectName}</Title>;
  }

  if (isEditingTitle) {
    return (
      <Input
        autoFocus
        className="max-w-3xl text-2xl font-bold tracking-tight md:text-3xl"
        type="text"
        value={name}
        onBlur={handleBlur}
        onChange={(e) => setName(e.target.value)}
      />
    );
  }

  return <Title onClick={handleEdit}>{projectName}</Title>;
}
