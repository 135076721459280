import { HTMLAttributes } from "react";
import { cn } from "@/lib/utils";
import Linkify from "react-linkify";
import Link from "@/components/ui/link";

interface EnhancedTextProps extends HTMLAttributes<HTMLDivElement> {
  linkClassName?: string;
}

export default function EnhancedText({
  children,
  className,
  linkClassName,
  ...props
}: EnhancedTextProps) {
  return (
    <div {...props} className={cn("whitespace-pre-line", className)}>
      <Linkify
        componentDecorator={(href, text, key) => (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            title={text}
            className={linkClassName}
          >
            {text}
          </Link>
        )}
      >
        {children}
      </Linkify>
    </div>
  );
}
