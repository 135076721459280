import React from "react";
import { Input, InputProps } from "@/components/ui/input";
import { cn } from "@/lib/utils";

const CurrencyInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => {
    return (
      <div className="relative">
        <Input
          {...props}
          type="number"
          inputMode="decimal"
          step={0.01}
          ref={ref}
          className={cn("pl-7", className)}
        />
        <span className="absolute inset-y-0 left-3 flex items-center text-muted-foreground">
          $
        </span>
      </div>
    );
  },
);

CurrencyInput.displayName = "CurrencyInput";

export default CurrencyInput;
