import { SyntheticEvent, useState } from "react";
import { CustomerSku } from "avail-types";
import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Repeat2 } from "lucide-react";
import pluralize from "pluralize";
import { useReorder } from "@/api/orders";
import { useToast } from "@/components/ui/use-toast";
import ReorderForm, { SkuQty } from "./ReorderForm";

export default function ReorderSheet({ skus }: { skus: CustomerSku[] }) {
  const [open, setOpen] = useState(false);
  const reorder = useReorder();
  const { toast } = useToast();

  const onSubmit = async (e: SyntheticEvent, skus: SkuQty[]) => {
    e.preventDefault();

    reorder
      .mutateAsync({ items: skus.map((sku) => ({ id: sku.id, qty: sku.qty })) })
      .then(() => {
        setOpen(false);
        toast({ title: "Project Created", variant: "success" });
      });
  };

  const skusWithQty = skus.map((sku) => ({
    ...sku,
    qty: Math.max((sku.par_level || 0) - sku.inventory_qty, 0),
  }));

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button variant="outline">
          <Repeat2 className="mr-2 size-5" />
          Restock SKU{skus.length !== 1 && "s"}
        </Button>
      </SheetTrigger>
      <SheetContent className="overflow-auto">
        <SheetHeader>
          <SheetTitle>Restock SKUs</SheetTitle>
          <SheetDescription>
            Update {pluralize("quantity", skus.length, false)} below
          </SheetDescription>
        </SheetHeader>
        <ReorderForm
          skus={skusWithQty}
          onSubmit={onSubmit}
          isLoading={reorder.isLoading}
        />
      </SheetContent>
    </Sheet>
  );
}
