import SkuSelect from "@/components/selects/SkuSelect";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { SyntheticEvent, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function AddFulfillmentOrderItemForm({
  onSubmit,
}: {
  onSubmit: (variantId: number, qty: number) => void;
}) {
  const [searchParams] = useSearchParams();
  const [variantId, setVariantId] = useState<number | null>(
    searchParams.has("variant") ? Number(searchParams.get("variant")) : null,
  );
  const [qty, setQty] = useState<string>("1");

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (variantId && qty) {
      onSubmit(variantId, Number(qty));
      setVariantId(null);
      setQty("1");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex items-center gap-x-3">
        <div className="flex-grow">
          <SkuSelect value={variantId} onChange={setVariantId} />
        </div>
        <Input
          type="number"
          inputMode="numeric"
          step={1}
          value={qty}
          onChange={(e) => setQty(e.target.value)}
          placeholder="Quantity"
          onFocus={(e) => e.target.select()}
        />
        <Button type="submit">Add to Order</Button>
      </div>
    </form>
  );
}
