import { HTMLAttributes } from "react";
import { cn } from "@/lib/utils";

export default function BrandCardLabel({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        "absolute bottom-0 left-0 right-0 border-t bg-card px-3 py-2 text-center text-sm",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}
