import {
  useProjectOptions,
  useRestoreProjectOption,
} from "@/api/project-options";
import { Button } from "@/components/ui/button";
import { Trash } from "lucide-react";
import pluralize from "pluralize";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

export default function TrashedOptions() {
  const { data } = useProjectOptions();
  const restoreOption = useRestoreProjectOption();
  const trashed = data?.trashed || [];

  if (trashed.length === 0) {
    return null;
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="secondary">
          <Trash className="mr-2 size-5" />
          {pluralize("option", trashed.length, true)} in trash
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Trashed Options</DialogTitle>
        </DialogHeader>
        <ul className="space-y-2">
          {trashed.map((option) => (
            <li key={option.id}>
              {option.name}{" "}
              <Button
                variant="link"
                disabled={restoreOption.isLoading}
                onClick={() => restoreOption.mutate(option.id)}
              >
                Restore
              </Button>
            </li>
          ))}
        </ul>
      </DialogContent>
    </Dialog>
  );
}
