import ProjectOrderCard from "@/components/projects/ProjectOrderCard";
import { useProject } from "@/context/ProjectContext";
import { useProjectSelections } from "@/api/project-selections";
import ProjectProgressBar from "@/components/projects/ProjectProgressBar";
import { curr } from "@/lib/curr";
import ProjectInvoiceActions from "@/components/projects/ProjectInvoiceActions";
import ProjectDueBadge from "@/components/projects/ProjectDueBadge";
import ProjectCardGrid from "@/components/projects/ProjectCardGrid";
import Toolbar from "@/components/ui/toolbar";

export default function OrderedStatus() {
  const project = useProject();
  const { data } = useProjectSelections();
  const selections = data?.data || [];

  return (
    <div className="space-y-6">
      <Toolbar actions={<ProjectInvoiceActions />}>
        <p className="mb-2">
          {`There ${
            project.orders.length === 1
              ? "is 1 order"
              : `are ${project.orders.length} orders`
          } totaling ${curr(project.total)} for this project.`}
        </p>
        {project.status === "complete" ? (
          <ProjectDueBadge project={project} />
        ) : (
          <ProjectProgressBar project={project} className="w-80 flex-grow-0" />
        )}
      </Toolbar>

      <ProjectCardGrid>
        {project.orders.map((order) => (
          <ProjectOrderCard
            key={order.id}
            order={order}
            option={
              selections.find((s) => s.option.order_id === order.id)?.option
            }
          />
        ))}
      </ProjectCardGrid>
    </div>
  );
}
