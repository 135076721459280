import { useMutation } from "@tanstack/react-query";
import axios from "axios";

export function useUploadRequest() {
  return useMutation((file: Blob) => {
    const formData = new FormData();
    formData.append("file", file);
    return axios
      .post<{ file: string; url: string }>("upload", formData)
      .then(({ data }) => data);
  });
}
