import { useEffect, useState } from "react";
import { Loader } from "lucide-react";
import { curr } from "@/lib/curr";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Card } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { projectOptionToPayload } from "@/lib/projects";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import EmptyState from "@/components/ui/empty-state";
import ProjectOptionDetails from "@/components/projects/ProjectOptionDetails";
import ProjectOptionSearchCard from "@/components/projects/ProjectOptionSearchCard";
import { ProjectOption, ProjectOptionPayload } from "avail-types";
import { usePreviousProjectOptions } from "@/api/project-options";

export default function SearchUserOptionsDialog({
  onSelect,
}: {
  onSelect: (payload: ProjectOptionPayload) => void;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const [option, setOption] = useState<ProjectOption | null>(null);
  const [pinned, setPinned] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const { data: optionsRes = [], isLoading } = usePreviousProjectOptions(
    query,
    pinned,
  );

  useEffect(() => {
    setQuery("");
    setPinned(false);
    setOption(null);
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button variant="link" size="xs">
          Search Past Options
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-7xl p-0">
        <div className="flex h-[calc(100vh-2rem)] flex-col gap-4 p-6">
          <DialogHeader>
            <DialogTitle>Search Options Previously Provided</DialogTitle>
          </DialogHeader>
          {!option ? (
            <div className="grid gap-4 overflow-hidden p-1 lg:grid-cols-4">
              <div>
                <Input
                  type="search"
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder="e.g. Yeti Mug"
                />
                <div className="mt-6 flex">
                  <Label className=" mr-4 flex items-center">
                    Search Favorites
                  </Label>
                  <Switch
                    id="is_pinned"
                    checked={pinned}
                    onCheckedChange={setPinned}
                  />
                </div>
              </div>
              <div className="col-span-3 overflow-y-auto">
                <div className="flex items-center justify-center">
                  {isLoading ? (
                    <Loader className="size-10 animate-spin" />
                  ) : optionsRes.length ? (
                    <div className="grid gap-4 lg:grid-cols-3">
                      {optionsRes.map((option: ProjectOption) => {
                        return (
                          <ProjectOptionSearchCard
                            key={option.id}
                            onClick={() => setOption(option)}
                            product={{
                              name: option.name,
                              pic: option.images[0] || "",
                              price: curr(option.price),
                            }}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <EmptyState
                      title="No Prior Options Found"
                      description="You have no prior options that match your search criteria"
                    />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex min-h-0 flex-col items-start gap-2">
              <Button variant="link" onClick={() => setOption(null)}>
                &larr; Back to Search
              </Button>
              <ProjectOptionDetails
                option={option}
                children={
                  <div className="grid grid-cols-2">
                    <Card className="mt-4">
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead>Size</TableHead>
                            <TableHead>Your Price</TableHead>
                            <TableHead>Cost</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {option.variants.map((variant, idx) => (
                            <TableRow key={`${variant.size}-${idx}`}>
                              <TableCell>{variant.size}</TableCell>
                              <TableCell>{curr(variant.price)}</TableCell>
                              <TableCell>{curr(variant.cost)}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Card>
                    <Button
                      type="button"
                      className="mb-6 ml-6 mt-6 w-48"
                      onClick={() => {
                        onSelect(projectOptionToPayload(option));
                        setOpen(false);
                      }}
                    >
                      Choose This Product
                    </Button>
                  </div>
                }
              />
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
