import { CustomerSku } from "avail-types";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogTrigger,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useState, ReactNode } from "react";
import { Alert } from "@/components/ui/alert";
import { useToast } from "@/components/ui/use-toast";
import pluralize from "pluralize";

export default function ArchiveSkuButton({
  children,
  disabled,
  onArchive,
  skus,
  title,
}: {
  children: ReactNode;
  disabled?: boolean;
  onArchive?: () => void;
  skus: CustomerSku[];
  title: string;
}) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string>();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const ids = skus.map((sku) => sku.id).join(",");

  const { isLoading, mutate } = useMutation(() =>
    axios
      .delete(`/variants/${ids}`)
      .then(() => {
        queryClient.invalidateQueries(["/variants"]);
        setOpen(false);
        toast({
          title: `${pluralize("SKU", ids.length)} Successfully Archived`,
          variant: "success",
        });
        if (onArchive) {
          onArchive();
        }
      })
      .catch((e) => {
        setError(
          e.response.data.message ||
            "An error occurred. Please try again later.",
        );
      }),
  );

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger disabled={disabled} asChild>
        {children}
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone.
          </AlertDialogDescription>
          {error && <Alert variant="destructive">{error}</Alert>}
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            variant="destructive"
            isLoading={isLoading}
            onClick={() => mutate()}
          >
            Archive
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
