import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";
import { CheckCircle, InfoIcon } from "lucide-react";
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

const alertVariants = cva(
  "text-sm relative w-full rounded-lg border px-5 py-4 [&:has(svg)]:pl-11 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-3 [&>svg]:top-4",
  {
    variants: {
      variant: {
        primary: "bg-primary/10 border-primary",
        default: "bg-background",
        success: "bg-green-50 dark:bg-green-900/50 border-green-600/50",
        warning: "bg-amber-50 dark:bg-amber-400/30 border-amber-300/50",
        destructive: "bg-red-50 dark:bg-red-900/50 border-red-600/50",
        info: "bg-blue-50 dark:bg-blue-900/50 border-blue-500/50",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, variant, children, ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={cn(alertVariants({ variant }), className)}
    {...props}
  >
    {variant === "info" && <InfoIcon className="size-5 text-blue-500" />}
    {variant === "destructive" && (
      <ExclamationCircleIcon className="size-5 text-red-500" />
    )}
    {variant === "warning" && (
      <ExclamationTriangleIcon className="size-5 text-yellow-500" />
    )}
    {variant === "success" && <CheckCircle className="size-5 text-green-500" />}

    {children}
  </div>
));
Alert.displayName = "Alert";

const AlertTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h5
    ref={ref}
    className={cn(
      "mb-1 font-medium leading-none tracking-tight text-foreground",
      className,
    )}
    {...props}
  />
));
AlertTitle.displayName = "AlertTitle";

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "text-sm text-slate-300 dark:text-slate-700 [&_p]:leading-relaxed",
      className,
    )}
    {...props}
  />
));
AlertDescription.displayName = "AlertDescription";

export { Alert, AlertTitle, AlertDescription };
