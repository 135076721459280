import { HTMLAttributes } from "react";
import { cn } from "@/lib/utils";

export default function ProjectCardGrid({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        "grid grid-cols-1 gap-4 sm:grid-cols-2 2xl:grid-cols-3 print:grid-cols-1",
        className,
      )}
      {...props}
    />
  );
}
