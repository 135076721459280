import { Loader } from "lucide-react";
import { useGetCart } from "@/api/cart";
import CartItems from "@/components/cart/CartItems";
import { CardTitle } from "@/components/ui/card";
import { Alert } from "@/components/ui/alert";
import CartActions from "@/components/cart/CartActions";

export default function CartSidebar({ checkoutUrl }: { checkoutUrl: string }) {
  const { data: cart, isLoading, isError } = useGetCart();

  return (
    <div className="flex h-full flex-col">
      <CardTitle>Cart</CardTitle>

      {cart && <CartItems items={cart.items} />}

      {isLoading && (
        <Loader className="size-6 animate-spin text-muted-foreground" />
      )}

      {isError && (
        <Alert variant="destructive">
          An error occurred while loading your cart.
        </Alert>
      )}

      {cart && <CartActions cart={cart} checkoutUrl={checkoutUrl} />}
    </div>
  );
}
