import { GripVertical, Loader, PencilIcon, Upload } from "lucide-react";
import { useUploadRequest } from "@/api/upload";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { cn } from "@/lib/utils";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export default function ImageUploadField({
  image,
  onChange,
  onDelete,
  onEdit,
  sortable,
  accept = {},
}: {
  image: string | null | undefined;
  onChange?: (url: string, name: string) => Promise<any>;
  onDelete?: () => void;
  onEdit?: () => void;
  sortable?: boolean;
  accept?: Record<string, string[]>;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: image || "none", disabled: !sortable });

  const uploadRequest = useUploadRequest();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const [file] = acceptedFiles;
      if (file) {
        uploadRequest.mutateAsync(file).then((data) => {
          setIsSubmitting(true);
          onChange!(data.url, file.name).finally(() => {
            setIsSubmitting(false);
          });
        });
      }
    },
    [uploadRequest, onChange],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".png", ".svg", ".jpg", ".jpeg", ".gif", ".webp"],
      ...accept,
    },
    disabled: !onChange,
  });

  const style = sortable
    ? {
        transform: CSS.Transform.toString(transform),
        transition,
      }
    : undefined;

  return (
    <div
      {...getRootProps()}
      className={cn(
        "group relative flex aspect-square w-full items-center justify-center rounded border bg-muted p-2",
        {
          "border-primary": isDragActive,
        },
      )}
      ref={setNodeRef}
      style={style}
    >
      {uploadRequest.isLoading || isSubmitting ? (
        <Loader className="size-6 animate-spin" />
      ) : image ? (
        <img alt="" src={image} className="h-full w-full object-contain" />
      ) : (
        <Upload className="size-6" />
      )}
      <input {...getInputProps()} />

      {sortable && (
        <button
          type="button"
          className="invisible absolute left-2 top-2 cursor-grab rounded-full bg-white p-1 shadow group-hover:visible"
          {...attributes}
          {...listeners}
        >
          <GripVertical className="size-4" />
        </button>
      )}

      <div
        className="absolute right-2 top-2 hidden gap-2 group-hover:flex"
        onClick={(e) => e.stopPropagation()}
      >
        {onEdit && (
          <button
            type="button"
            onClick={onEdit}
            className="rounded-full bg-white p-1 shadow"
          >
            <PencilIcon className="size-4" />
          </button>
        )}
        {onDelete && (
          <button
            type="button"
            onClick={onDelete}
            className=" rounded-full bg-white p-1 shadow"
          >
            <XMarkIcon className="size-4" />
          </button>
        )}
      </div>
    </div>
  );
}
