import { imageUrl } from "@/lib/image";
import { useState } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Tags } from "lucide-react";
import ProductImageTiles from "@/components/products/ProductImageTiles";

interface ProductImagesProps extends React.HTMLAttributes<HTMLDivElement> {
  images: string[];
}

export default function ProductImages({
  images,
  ...props
}: ProductImagesProps) {
  const [mainImage, setMainImage] = useState<string | undefined>(images[0]);

  return (
    <div {...props}>
      <Avatar className="mb-6 block h-[50vh] min-h-[500px] w-full rounded-none">
        {mainImage && <AvatarImage src={imageUrl(mainImage, { w: 500 })} />}
        <AvatarFallback className="rounded-none">
          <Tags className="size-8" />
        </AvatarFallback>
      </Avatar>

      <ProductImageTiles
        images={images}
        selectedImage={mainImage}
        onImageSelected={(image, _) => setMainImage(image)}
      />
    </div>
  );
}
