import AuthLayout from "@/layouts/AuthLayout";
import ForgotPasswordForm from "@/components/auth/ForgotPasswordForm";
import { useTitle } from "@/lib/title";
import { useAppContext } from "@/context/AppContext";
import AlreadyLoggedInRedirect from "@/components/auth/AlreadyLoggedInRedirect";

export default function ForgotPassword() {
  useTitle("Forgot Password");

  const context = useAppContext();
  if (context.user) {
    return <AlreadyLoggedInRedirect data={context} />;
  }

  return (
    <AuthLayout
      title="Forgot your password?"
      description="Enter your email below and we'll send you a reset link."
    >
      <ForgotPasswordForm />
    </AuthLayout>
  );
}
