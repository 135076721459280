import { FormControl } from "@/components/ui/form";
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import { ChangeEvent, ForwardedRef, forwardRef, useState } from "react";
import { imageUrl } from "@/lib/image";

function AvatarField(
  {
    name,
    value,
  }: {
    name: string;
    value: string | null | undefined;
  },
  ref: ForwardedRef<HTMLInputElement>,
) {
  const [preview, setPreview] = useState<string>();

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const imageToShow = preview || imageUrl(value, { w: 250 });
  return (
    <div>
      {imageToShow && (
        <Avatar className="mb-3 h-36 w-36 rounded">
          <AvatarImage className="object-cover" src={imageToShow} />
        </Avatar>
      )}
      <FormControl>
        <input type="file" name={name} onChange={handleFileUpload} ref={ref} />
      </FormControl>
    </div>
  );
}

export default forwardRef(AvatarField);
