import { Button } from "@/components/ui/button";
import { ComponentPropsWithoutRef, useEffect } from "react";
import { cn } from "@/lib/utils";
import { ArrowRight } from "lucide-react";

function displayBeacon(display: string) {
  const el = document.getElementById("beacon-container");
  if (el) {
    el.style.display = display;
  }
}

export default function WizardSubmitButton({
  children,
  className,
  type = "submit",
  forceMobile,
  ...props
}: ComponentPropsWithoutRef<typeof Button> & { forceMobile?: boolean }) {
  // Ensure beacon is hidden to not overlap
  useEffect(() => {
    displayBeacon("none");
    setTimeout(() => {
      displayBeacon("none");
    }, 100);
    return () => {
      displayBeacon("block");
    };
  }, []);

  return (
    <Button
      type={type}
      className={cn(
        "fixed bottom-10 right-4 z-10 shadow-md md:bottom-6 md:right-6 lg:right-8",
        !forceMobile && "sm:static sm:shadow-none",
        className,
      )}
      {...props}
    >
      {children}
      <ArrowRight className="ml-2 size-4" />
    </Button>
  );
}
