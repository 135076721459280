import { useAppState } from "@/context/AppContext";
import { Navigate } from "react-router-dom";
import { JSX } from "react";

export default function RedirectIfGuest({
  children,
}: {
  children: JSX.Element;
}) {
  const { membership } = useAppState();

  if (membership.role === "guest") {
    return <Navigate to="/" replace />;
  }

  return children;
}
