import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";

export function PaginateList<T>({
  filterItem,
  items,
  itemsPerPage,
  query,
  renderChildren,
}: {
  filterItem?: (item: T, query: string) => boolean;
  items: T[];
  itemsPerPage: number;
  query?: string;
  renderChildren: (data: T) => JSX.Element;
}) {
  const [page, setPage] = useState<number>(1);
  const filteredList =
    query && filterItem
      ? items.filter((item) => filterItem(item, query))
      : items;
  const hasNextPage = filteredList.length > page * itemsPerPage;
  const itemsOnCurrentPage = filteredList.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage,
  );

  useEffect(() => {
    setPage(1);
  }, [query]);

  return (
    <>
      <Card className="mt-4">
        <ul className="divide-y divide-slate-200">
          {itemsOnCurrentPage.map(renderChildren)}
        </ul>
      </Card>
      {items.length > itemsPerPage && (
        <div className="flex items-center justify-end space-x-2 py-4">
          <div className="space-x-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => setPage((prev) => prev - 1)}
              disabled={page === 1}
            >
              Previous
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={() => setPage((prev) => prev + 1)}
              disabled={!hasNextPage}
            >
              Next
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
