import { Link } from "react-router-dom";
import { useTitle } from "@/lib/title";

export default function ClientError() {
  useTitle("Error");

  return (
    <main className="mx-auto flex w-full max-w-7xl flex-auto flex-col justify-center px-6 py-24 sm:py-64 lg:px-8">
      <p className="text-base font-semibold leading-8 text-primary">ERROR</p>
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-5xl">
        Something went wrong
      </h1>
      <p className="mt-6 text-base leading-7 text-muted-foreground">
        We've been notified of the issue and are working on a fix. Thanks for
        your patience.
      </p>
      <div className="mt-10">
        <Link to="/" className="text-sm font-semibold leading-7 text-primary">
          <span aria-hidden="true">&larr;</span> Back to home
        </Link>
      </div>
    </main>
  );
}
