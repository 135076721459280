import { useParams } from "react-router-dom";
import { useGetApproval } from "@/api/approval";

export default function ApprovalBreadcrumb() {
  const { code } = useParams<{ code: string }>();
  const { data } = useGetApproval(code!);

  if (data) {
    return `${data.label} ${data.order.increment_id}`;
  }
  return "Approval";
}
