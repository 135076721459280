import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";

export default function ProductFilter({
  label,
  keyName,
  options,
  selected,
  handleSelect,
}: {
  label: string;
  keyName: string;
  options: Record<string, number>;
  selected: string[];
  handleSelect: (keyName: string, option: string, checked: boolean) => void;
}) {
  if (Object.keys(options).length === 0) {
    return null;
  }
  return (
    <Popover
      as="div"
      id={`filter-${keyName}`}
      className="relative inline-block text-left"
    >
      <div>
        <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-slate-700 hover:text-slate-900">
          <span>{label}</span>
          {selected.length > 0 ? (
            <span className="ml-1.5 rounded bg-slate-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-slate-700">
              {selected.length}
            </span>
          ) : null}
          <ChevronDownIcon
            className="-mr-1 ml-1 size-5 flex-shrink-0 text-slate-400 group-hover:text-slate-500"
            aria-hidden="true"
          />
        </Popover.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className="absolute right-0 z-10 mt-2 max-h-[30rem] origin-top-right overflow-y-auto rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
          <form className="space-y-4">
            {Object.entries(options).map(([option, optionCount], optionIdx) => (
              <div key={option} className="flex items-center">
                <input
                  id={`filter-mobile-${keyName}-${optionIdx}`}
                  name={`${keyName}[]`}
                  checked={selected.includes(option)}
                  onChange={(e) =>
                    handleSelect(keyName, option, e.target.checked)
                  }
                  type="checkbox"
                  className="size-4 rounded border-slate-300 text-indigo-600 focus:ring-indigo-500"
                />
                <label
                  htmlFor={`filter-mobile-${keyName}-${optionIdx}`}
                  className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-slate-900"
                >
                  {option} ({optionCount})
                </label>
              </div>
            ))}
          </form>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
