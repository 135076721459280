import ProjectName from "@/components/projects/create/ProjectName";
import ProjectBudget from "@/components/projects/create/ProjectBudget";
import ProjectDelivery from "@/components/projects/create/ProjectDelivery";
import ProjectReview from "@/components/projects/create/ProjectReview";
import ProjectItems from "@/components/projects/create/ProjectItems";
import startCase from "lodash/startCase";
import { curr } from "@/lib/curr";
import pluralize from "pluralize";
import { useCreateProject } from "@/api/project";
import { useAppContext, useSettings } from "@/context/AppContext";
import ProjectCustomFields from "@/components/projects/create/ProjectCustomFields";
import { useTitle } from "@/lib/title";
import WizardPage, { WizardStep } from "@/components/wizard/WizardPage";
import { useNavigate } from "react-router-dom";
import { CREATE_PROJECT_STORAGE_KEY } from "@/constants/project";
import { ProjectCreatePayload } from "avail-types";
import { formatDate } from "@/lib/dates";

export default function ProjectCreate() {
  useTitle("Create Project");

  const createProjectRequest = useCreateProject();
  const { membership } = useAppContext();
  const settings = useSettings();
  const navigate = useNavigate();

  const handleSubmit = (values: ProjectCreatePayload) => {
    if (membership) {
      return createProjectRequest.mutateAsync(values);
    }
    localStorage.setItem(CREATE_PROJECT_STORAGE_KEY, JSON.stringify(values));
    const searchParams = new URLSearchParams();
    searchParams.set("redirect_to", "/projects/submit");
    navigate("/register?" + searchParams.toString());
    return Promise.resolve();
  };

  const steps: WizardStep<ProjectCreatePayload>[] = [
    {
      name: "Items",
      description: (v) =>
        v.items && v.items.length > 0
          ? pluralize("item", v.items.length, true)
          : "What items would you like to include?",
      isComplete: (v) => Boolean(v.items && v.items.length > 0),
      component: ProjectItems,
    },
    {
      name: "Project Name",
      description: (v) => v.name || "What should we call this project?",
      isComplete: (v) => !!v.name,
      component: ProjectName,
    },
    {
      name: "Budget",
      description: (v) =>
        v.budget_type
          ? `${curr(v.budget_amount)} ${startCase(v.budget_type)}`
          : "How much do you want to spend?",
      isComplete: (v) => Boolean(v.budget_qty && v.budget_amount),
      component: ProjectBudget,
    },
    {
      name: "Shipping & Delivery",
      description: (v) =>
        v.in_hands_date
          ? formatDate(v.in_hands_date)
          : "How and when would you like your project delivered?",
      isComplete: (v) => Boolean(v.shipping_mode),
      component: ProjectDelivery,
    },

    {
      name: "Review & Submit",
      description: "What else should we know?",
      isComplete: (v) => Boolean(v.description),
      component: ProjectReview,
    },
  ];

  if (settings.project_fields.length > 0) {
    steps.splice(2, 0, {
      name: "Additional Information",
      description:
        "Your company requires us to collect additional information.",
      isComplete: (v) =>
        settings.project_fields.every(
          (pf) => v.fields?.find((f) => f.customer_project_field_id === pf.id),
        ),
      component: ProjectCustomFields,
    });
  }

  return (
    <WizardPage
      title="Create a Project"
      sessionStorageKey="project-create"
      isLoading={createProjectRequest.isLoading}
      onSubmit={handleSubmit}
      defaultValues={{
        type: "project",
        attachments: [],
        items: [],
        fields: settings.project_fields.map((field) => ({
          customer_project_field_id: field.id,
          value: "",
        })),
      }}
      steps={steps}
    />
  );
}
