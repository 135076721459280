import { HTMLAttributes } from "react";
import { cn } from "@/lib/utils";

export default function Description({
  children,
  className,
  ...other
}: HTMLAttributes<HTMLParagraphElement>) {
  return (
    <p
      className={cn(
        "mt-2 text-sm text-muted-foreground md:text-base",
        className,
      )}
      {...other}
    >
      {children}
    </p>
  );
}
