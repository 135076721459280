import { ReactNode, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useForm } from "react-hook-form";
import { Form, FormField, FormItem } from "@/components/ui/form";
import { Button } from "../ui/button";
import { useUpdateCustomerBrandColor } from "@/api/brand";
import { SketchPicker } from "react-color";
import { CustomerBrandColor } from "avail-types";

const formSchema = z.object({
  hex: z.string(),
});

export default function UpdateColorDialog({
  color,
  children,
}: {
  color: CustomerBrandColor;
  children?: ReactNode;
}) {
  const createColor = useUpdateCustomerBrandColor(color);
  const [open, setOpen] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      hex: color.hex || "",
    },
  });

  const handleUpdate = (values: z.infer<typeof formSchema>) =>
    createColor.mutateAsync(values).then((data) => {
      form.reset({ hex: data.hex || "" });
      setOpen(false);
    });

  return (
    <Dialog open={open} onOpenChange={() => setOpen(!open)}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="max-w-sm">
        <DialogHeader>
          <DialogTitle>Update Color</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleUpdate)}
            className="space-y-5"
          >
            <FormField
              control={form.control}
              name="hex"
              render={({ field }) => (
                <FormItem>
                  <SketchPicker
                    color={field.value}
                    onChange={(color) => field.onChange(color.hex)}
                  />
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button type="submit" isLoading={form.formState.isSubmitting}>
                Update
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
