import { CartProduct } from "avail-types";
import { Button } from "@/components/ui/button";
import { useShopContext } from "@/context/ShopContext";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { curr } from "@/lib/curr";

export default function ChooseProductButton({
  product,
}: {
  product: CartProduct;
}) {
  const { updateShopState } = useShopContext();

  return (
    <div>
      <Table className="my-4 rounded border">
        <TableHeader>
          <TableRow>
            <TableHead>Size</TableHead>
            <TableHead>Your Price</TableHead>
            <TableHead>Inventory</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {product.variants.map((variant) => (
            <TableRow key={variant.id}>
              <TableCell>{variant.size}</TableCell>
              <TableCell>{curr(variant.price)}</TableCell>
              <TableCell>
                <div title={`Last Updated ${variant.stock_updated_at}`}>
                  {variant.vendor_inventory_qty?.toLocaleString()}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {updateShopState && (
        <Button onClick={() => updateShopState({ product })}>
          Choose Product
        </Button>
      )}
    </div>
  );
}
