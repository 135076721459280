import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import axios from "axios";
import { handleLaravelErrors, maybeRenderRootError } from "@/lib/form";
import { useState } from "react";
import { Alert } from "@/components/ui/alert";

const formSchema = z.object({
  email: z.string().email(),
});

export default function ForgotPasswordForm() {
  const [success, setSuccess] = useState("");
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    return axios
      .post<{ status: string }>(`/forgot-password`, values)
      .then(({ data }) => {
        form.reset();
        setSuccess(data.status);
      })
      .catch(handleLaravelErrors(form));
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        {success && <Alert variant="success">{success}</Alert>}
        {maybeRenderRootError(form)}

        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input type="email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button
          type="submit"
          isLoading={form.formState.isSubmitting}
          className="w-full"
        >
          Submit
        </Button>
      </form>
    </Form>
  );
}
