import { cn } from "@/lib/utils";
import { HTMLAttributes } from "react";

export default function Grid({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        "grid grid-cols-2 gap-2 md:gap-4 lg:grid-cols-3 2xl:grid-cols-4",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}
