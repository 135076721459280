import { Navigate } from "react-router-dom";
import { AppState, NoCustomerState } from "@/context/AppContext";
import { API_BASE_URL } from "@/constants/services";

function getUrlAfterVerifyingAuth(data: AppState | NoCustomerState) {
  const { user, membership } = data;
  const searchParams = new URLSearchParams(window.location.search);
  const redirectTo = searchParams.get("redirect_to") || "/";

  if (redirectTo.match(/^https?:\/\//)) {
    if (redirectTo.includes("customer-api") || redirectTo.includes("oauth/")) {
      // So this customer-api is a special case where we are redirecting back to the API.
      // This is generally used for email verification when someone clicks on the link in their email, but isn't logged in.
      // In this case, we need to redirect to the API now that they are logged in, which will then redirect back to the frontend.
      // However, there's a chance it's the wrong API base url, so we need to replace it with the frontend base url.
      const url = new URL(redirectTo);
      const baseUrl = new URL(API_BASE_URL);
      url.hostname = baseUrl.hostname;
      return url.toString();
    }
    return redirectTo;
  }
  if (user.email_verified_at === null) {
    return `/verify-email?${searchParams.toString()}`;
  }
  if (!membership) {
    return `/onboarding?${searchParams.toString()}`;
  }
  return redirectTo;
}

export default function AlreadyLoggedInRedirect({
  data,
}: {
  data: AppState | NoCustomerState;
}) {
  const redirectTo = getUrlAfterVerifyingAuth(data);

  if (redirectTo.match(/^https?:\/\//)) {
    window.location.href = redirectTo;
    return null;
  }

  return <Navigate to={getUrlAfterVerifyingAuth(data)} replace />;
}
