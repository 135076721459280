import EmptyState from "@/components/ui/empty-state";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useGetAddresses } from "@/api/addresses";
import AddressUpdateDialog from "@/components/addresses/AddressUpdateDialog";
import AddressDeleteDialog from "@/components/addresses/AddressDeleteDialog";
import AddressNewDialog from "@/components/addresses/AddressNewDialog";
import AddressBlock from "@/components/addresses/AddressBlock";
import { Loader } from "lucide-react";

export default function AddressesCard() {
  const { data: addresses = [], isLoading } = useGetAddresses();

  return (
    <Card>
      <CardHeader className="flex flex-row items-center">
        <CardTitle>Addresses</CardTitle>
        <div className="ml-auto">
          <AddressNewDialog />
        </div>
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <Loader />
        ) : addresses.length > 0 ? (
          <Card className="mt-4">
            <ul className="divide-y divide-slate-200">
              {addresses.map((a) => (
                <li
                  className="flex items-center space-x-3 px-5 py-3"
                  key={a.id}
                >
                  <AddressBlock
                    address={a}
                    className="flex-grow"
                    labelClassName="font-semibold"
                  />
                  <div className="ml-auto flex items-center space-x-3">
                    <AddressUpdateDialog address={a} />
                    <AddressDeleteDialog address={a} />
                  </div>
                </li>
              ))}
            </ul>
          </Card>
        ) : (
          <EmptyState
            title="No saved addresses"
            description="Get started by adding your first address"
          >
            <AddressNewDialog />
          </EmptyState>
        )}
      </CardContent>
    </Card>
  );
}
