import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { CustomerFulfillmentOrder } from "avail-types";

export function useGetFulfillmentOrder(id: number) {
  return useQuery(["fulfillmentOrder", id], () =>
    axios
      .get<CustomerFulfillmentOrder>(`/fulfillment-orders/${id}`)
      .then(({ data }) => data),
  );
}
