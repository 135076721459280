import { ReactNode, useState } from "react";
import { useProject } from "@/context/ProjectContext";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useUpdateProject } from "@/api/project";
import { canEdit } from "@/lib/projects";
import EditableField from "@/components/projects/EditableField";
import { ProjectUpdatePayload } from "avail-types";

export default function ProjectEditableField({
  label,
  formLabel,
  children,
  getForm,
  allowEditAfterProofsRequested = false,
}: {
  label?: string;
  formLabel?: string;
  children: ReactNode;
  getForm: (
    onSubmit: (values: ProjectUpdatePayload) => Promise<void>,
  ) => ReactNode;
  allowEditAfterProofsRequested?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const project = useProject();
  const updateRequest = useUpdateProject(project.id);

  const handleSubmit = (values: ProjectUpdatePayload) =>
    updateRequest.mutateAsync(values).then(() => {
      setOpen(false);
    });

  return (
    <>
      <EditableField
        label={label}
        onEdit={() => setOpen(true)}
        disabled={!canEdit(project, allowEditAfterProofsRequested)}
      >
        {children}
      </EditableField>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Update {formLabel || label || "Project"}</DialogTitle>
          </DialogHeader>
          {getForm(handleSubmit)}
        </DialogContent>
      </Dialog>
    </>
  );
}
