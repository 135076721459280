import { useGetProjectUsers } from "@/api/project-users";
import { useState } from "react";
import { useProject } from "@/context/ProjectContext";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";

export default function ChooseUserForm({
  onSubmit,
}: {
  onSubmit: (v: { user_id: number }) => Promise<unknown>;
}) {
  const project = useProject();
  const { data: users = [] } = useGetProjectUsers();
  const [userId, setUserId] = useState(project.user?.id);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsLoading(true);
        onSubmit({ user_id: userId! }).finally(() => {
          setIsLoading(false);
        });
      }}
    >
      <Select
        value={String(userId)}
        onValueChange={(v) => setUserId(Number(v))}
      >
        <SelectTrigger className="mt-4 w-[280px]">
          <SelectValue placeholder="Select a user" />
        </SelectTrigger>
        <SelectContent>
          {users.map((u) => (
            <SelectItem key={u.id} value={String(u.id)}>
              {u.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <div className="mt-6">
        <Button type="submit" isLoading={isLoading}>
          Save
        </Button>
      </div>
    </form>
  );
}
