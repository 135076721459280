import { useMatches } from "react-router-dom";
import Breadcrumbs from "@/components/ui/breadcrumbs";

export default function BreadcrumbsFromRoutes({
  homeHref = "/",
}: {
  homeHref?: string;
}) {
  const matches = useMatches();
  // @ts-ignore
  const crumbs = matches.filter((match) => Boolean(match.handle?.crumb));

  if (crumbs.length > 0) {
    return (
      <Breadcrumbs
        homeHref={homeHref}
        className="mb-4"
        crumbs={crumbs.map((crumb) => ({
          // @ts-ignore
          title: crumb.handle?.crumb,
          href: crumb.pathname,
        }))}
      />
    );
  }
  return null;
}
