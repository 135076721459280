import Toolbar from "@/components/ui/toolbar";
import Title from "@/components/ui/title";
import ChangePasswordCard from "@/components/account/ChangePasswordCard";
import AccountDetailsCard from "@/components/account/AccountDetailsCard";
import TeamMembersCard from "@/components/account/TeamMembersCard";
import Gate from "@/components/roles/Gate";
import DomainsCard from "@/components/account/DomainsCard";
import { useTitle } from "@/lib/title";
import InvitesCard from "@/components/account/InvitesCard";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import AddressesCard from "@/components/addresses/AddressesCard";

export default function Account() {
  useTitle("Account");

  const [tab, setTab] = useQueryParam(
    "tab",
    withDefault(StringParam, "account"),
  );

  return (
    <div>
      <Toolbar actions={null}>
        <Title>Account</Title>
      </Toolbar>

      <Tabs value={tab} onValueChange={setTab} className="mt-6 max-w-4xl">
        <TabsList className="mb-4">
          <TabsTrigger value="account">Account</TabsTrigger>
          <TabsTrigger value="password">Password</TabsTrigger>
          <Gate role={["admin", "avail", "avail_incognito"]}>
            <TabsTrigger value="domains">Domains</TabsTrigger>
          </Gate>
          <TabsTrigger value="team">Team</TabsTrigger>
          <Gate role={["admin", "avail", "avail_incognito"]}>
            <TabsTrigger value="invites">Invites</TabsTrigger>
          </Gate>
          <TabsTrigger value="addresses">Addresses</TabsTrigger>
        </TabsList>

        <TabsContent value="account">
          <AccountDetailsCard />
        </TabsContent>

        <TabsContent value="password">
          <ChangePasswordCard />
        </TabsContent>

        <TabsContent value="domains">
          <Gate role={["admin", "avail", "avail_incognito"]}>
            <DomainsCard />
          </Gate>
        </TabsContent>

        <TabsContent value="team">
          <TeamMembersCard />
        </TabsContent>

        <TabsContent value="invites">
          <Gate role={["admin", "avail", "avail_incognito"]}>
            <InvitesCard />
          </Gate>
        </TabsContent>

        <TabsContent value="addresses">
          <AddressesCard />
        </TabsContent>
      </Tabs>
    </div>
  );
}
