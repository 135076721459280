import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import {
  CustomerBrandColor,
  CustomerBrandFile,
  CustomerBrandColorPayload,
  CustomerBrandFilePayload,
} from "avail-types";
import { useAppContext } from "@/context/AppContext";

export function useGetCustomerBrandFiles() {
  const { membership } = useAppContext();
  return useQuery(
    ["brandFiles"],
    () =>
      axios
        .get<{ data: CustomerBrandFile[] }>("brand-files")
        .then(({ data }) => data.data),
    {
      staleTime: 1000 * 60 * 5,
      enabled: Boolean(membership),
    },
  );
}

export function useCreateCustomerBrandFile(type: "logo" | "font") {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: Omit<CustomerBrandFilePayload, "type">) =>
      axios
        .post<CustomerBrandFile>("brand-files", { ...payload, type })
        .then(({ data }) => data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<CustomerBrandFile[]>(
          ["brandFiles"],
          (prev) => {
            if (!prev) return prev;
            return [...prev, data];
          },
        );
      },
    },
  );
}

export function useUpdateCustomerBrandFile(file: CustomerBrandFile) {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: Partial<CustomerBrandFilePayload>) =>
      axios
        .put<CustomerBrandFile>(`brand-files/${file.id}`, payload)
        .then(({ data }) => data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<CustomerBrandFile[]>(
          ["brandFiles"],
          (prev) => {
            if (!prev) return prev;
            return prev.map((file) => {
              if (file.id === data.id) {
                return data;
              }
              return file;
            });
          },
        );
      },
    },
  );
}

export function useDeleteCustomerBrandFile() {
  const queryClient = useQueryClient();

  return useMutation((id: number) => axios.delete(`brand-files/${id}`), {
    onSuccess: (_, id) => {
      queryClient.setQueryData<CustomerBrandFile[]>(["brandFiles"], (prev) => {
        if (!prev) return prev;
        return prev.filter((file) => file.id !== id);
      });
    },
  });
}

export function useGetCustomerBrandColors() {
  return useQuery(["brandColors"], () =>
    axios
      .get<{ data: CustomerBrandColor[] }>("brand-colors")
      .then(({ data }) => data.data),
  );
}

export function useCreateCustomerBrandColor() {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CustomerBrandColorPayload) =>
      axios
        .post<CustomerBrandColor>("brand-colors", payload)
        .then(({ data }) => data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<CustomerBrandColor[]>(
          ["brandColors"],
          (prev) => {
            if (!prev) return prev;
            return [...prev, data];
          },
        );
      },
    },
  );
}

export function useUpdateCustomerBrandColor(color: CustomerBrandColor) {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CustomerBrandColorPayload) =>
      axios
        .put<CustomerBrandColor>(`brand-colors/${color.id}`, payload)
        .then(({ data }) => data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<CustomerBrandColor[]>(
          ["brandColors"],
          (prev) => {
            if (!prev) return prev;
            return prev.map((color) => {
              if (color.id === data.id) {
                return data;
              }
              return color;
            });
          },
        );
      },
    },
  );
}

export function useDeleteCustomerBrandColor() {
  const queryClient = useQueryClient();

  return useMutation((id: number) => axios.delete(`brand-colors/${id}`), {
    onSuccess: (_, id) => {
      queryClient.setQueryData<CustomerBrandColor[]>(
        ["brandColors"],
        (prev) => {
          if (!prev) return prev;
          return prev.filter((color) => color.id !== id);
        },
      );
    },
  });
}
