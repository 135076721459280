import { PurchaseOrderStatus } from "avail-types";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { formatDate } from "@/lib/dates";

export default function VendorStatusTable({
  statuses,
}: {
  statuses: PurchaseOrderStatus[];
}) {
  if (statuses.length === 0) {
    return null;
  }
  return (
    <Card>
      <CardHeader className="border-b">
        <CardTitle className="text-lg">Statuses from Vendor</CardTitle>
      </CardHeader>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Order Number</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Expected Ship Date</TableHead>
            <TableHead>Last Updated</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {statuses.map((status) => (
            <TableRow key={status.id}>
              <TableCell>{status.factory_order_number}</TableCell>
              <TableCell>
                <Badge>{status.status}</Badge>
              </TableCell>
              <TableCell>{formatDate(status.expected_ship_date)}</TableCell>
              <TableCell>{formatDate(status.last_updated_at)}</TableCell>
            </TableRow>
          ))}
          {statuses.length === 0 && (
            <TableRow>
              <TableCell colSpan={0}>
                There are no statuses from vendors for this order.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Card>
  );
}
