import Title from "@/components/ui/title";
import Toolbar from "@/components/ui/toolbar";
import Description from "@/components/ui/description";
import { useTitle } from "@/lib/title";
import BrandColors from "@/components/brand/BrandColors";
import BrandLogos from "@/components/brand/BrandLogos";

export default function Brand() {
  useTitle("Brand");

  return (
    <div className="flex-1 space-y-6">
      <Toolbar className="mb-8">
        <Title>Brand</Title>
        <Description>Manage your brand colors and logos.</Description>
      </Toolbar>

      <BrandLogos />
      <BrandColors />
    </div>
  );
}
