import { cn } from "@/lib/utils";
import { HTMLAttributes } from "react";
import { Separator } from "@/components/ui/separator";

export default function OrDivider({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn("my-8 flex w-full items-center", className)} {...props}>
      <Separator className="w-auto flex-grow" />
      <span className="px-4 text-sm text-muted-foreground">or</span>
      <Separator className="w-auto flex-grow" />
    </div>
  );
}
