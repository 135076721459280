import ProjectOptionCard, {
  ProjectOptionCardProps,
} from "@/components/projects/ProjectOptionCard";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { MoveIcon } from "lucide-react";
import ProjectOptionMoveButton from "@/components/projects/ProjectOptionMoveButton";
import ProjectOptionDuplicateButton from "@/components/projects/ProjectOptionDuplicateButton";
import ProjectOptionArchiveButton from "@/components/projects/ProjectOptionArchiveButton";

export default function SortableProjectOptionCard({
  option,
  onClick,
}: ProjectOptionCardProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: option.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <ProjectOptionCard
      option={option}
      onClick={onClick}
      ref={setNodeRef}
      style={style}
    >
      <div
        className="invisible absolute right-3 top-3 flex gap-3 group-hover:visible"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          type="button"
          className="cursor-grab"
          {...attributes}
          {...listeners}
        >
          <MoveIcon className="size-5 text-muted-foreground" />
        </button>
        <ProjectOptionMoveButton option={option} />
        <ProjectOptionDuplicateButton option={option} />
        <ProjectOptionArchiveButton option={option} />
      </div>
    </ProjectOptionCard>
  );
}
