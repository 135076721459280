import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useCountries } from "@/context/AppContext";
import { UseFormReturn } from "react-hook-form";
import { useEffect } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { GOOGLE_API_KEY } from "@/constants/services";
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;
import { AddressPayload } from "avail-types";
import { Checkbox } from "@/components/ui/checkbox";

const componentForm: Record<string, "short_name" | "long_name"> = {
  street_number: "short_name",
  route: "long_name",
  locality: "long_name",
  administrative_area_level_1: "short_name",
  administrative_area_level_2: "long_name",
  country: "short_name",
  postal_code: "short_name",
};

const getFromComponents = (
  components: GeocoderAddressComponent[],
  types: string[],
) =>
  components
    .filter((i) => i.types.some((type) => types.includes(type)))
    .map((i) => i[componentForm[i.types[0]!]!])
    .join(" ");

export default function AddressFields({
  form,
}: {
  form: UseFormReturn<
    {
      address: AddressPayload;
    } & any
  >;
}) {
  const { ref: addressRef } = usePlacesWidget({
    apiKey: GOOGLE_API_KEY,
    options: {
      types: ["address"],
    },
    onPlaceSelected: (place) => {
      const components = place.address_components;
      if (components) {
        form.setValue(
          "address.address_1",
          getFromComponents(components, ["street_number", "route"]),
        );
        form.setValue(
          "address.city",
          getFromComponents(components, ["locality"]),
        );
        form.setValue(
          "address.state",
          getFromComponents(components, ["administrative_area_level_1"]),
        );
        form.setValue(
          "address.zip",
          getFromComponents(components, ["postal_code"]),
        );
        form.setValue(
          "address.country",
          getFromComponents(components, ["country"]),
        );
      }
    },
  });

  useEffect(() => {
    if (!form.getValues().address?.country) {
      form.setValue("address.country", "US");
    }
  }, []);

  useEffect(() => {
    setTimeout(() => (document.body.style.pointerEvents = ""), 0);
  });

  const countries = useCountries();
  const country = form.watch("address.country");
  const states = countries.find((c) => c.id === country)?.states || [];
  const { ref, ...addressFieldProps } = form.register("address.address_1");
  const addressValue = form.watch("address.address_1");

  return (
    <div className="space-y-3">
      <FormField
        control={form.control}
        name="address.name"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Name</FormLabel>
            <FormControl>
              <Input type="text" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="address.company"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Company</FormLabel>
            <FormControl>
              <Input type="text" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormItem>
        <FormLabel>Address Line 1</FormLabel>
        <FormControl>
          <Input
            type="text"
            {...addressFieldProps}
            value={addressValue}
            ref={(e) => {
              ref(e);
              // @ts-ignore
              addressRef.current = e;
            }}
          />
        </FormControl>
        <FormMessage />
      </FormItem>

      <FormField
        control={form.control}
        name="address.address_2"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Address Line 2</FormLabel>
            <FormControl>
              <Input type="text" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="address.country"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Country</FormLabel>
            <Select value={field.value} onValueChange={field.onChange}>
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="Select a country" />
                </SelectTrigger>
              </FormControl>
              <SelectContent className="max-h-[20rem] overflow-y-auto">
                {countries.map((country) => (
                  <SelectItem key={country.id} value={country.id}>
                    {country.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
        <FormField
          control={form.control}
          name="address.city"
          render={({ field }) => (
            <FormItem>
              <FormLabel>City</FormLabel>
              <FormControl>
                <Input type="text" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {states.length > 0 && (
          <FormField
            control={form.control}
            name="address.state"
            render={({ field }) => (
              <FormItem>
                <FormLabel>State</FormLabel>
                <Select value={field.value} onValueChange={field.onChange}>
                  <FormControl>
                    <SelectTrigger className="[&>span]:truncate">
                      <SelectValue placeholder="Select a state" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent className="max-h-[20rem] overflow-y-auto">
                    {states.map((state) => (
                      <SelectItem key={state.code} value={state.code}>
                        {state.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        <FormField
          control={form.control}
          name="address.zip"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Zip Code</FormLabel>
              <FormControl>
                <Input type="text" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <div className="!mt-5 space-y-3">
        <FormField
          control={form.control}
          name="address.is_residential"
          render={({ field }) => (
            <FormItem className="flex items-center space-x-3 space-y-0 whitespace-nowrap">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <FormLabel>Residential Address</FormLabel>
            </FormItem>
          )}
        />

        {form.formState.errors.root?.message?.includes("Invalid") && (
          <FormField
            control={form.control}
            name="address.bypass_validation"
            render={({ field }) => (
              <FormItem className="flex items-center space-x-3 space-y-0 whitespace-nowrap">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <FormLabel>Bypass Validation</FormLabel>
              </FormItem>
            )}
          />
        )}
      </div>
    </div>
  );
}
