import { InventoryEntry } from "avail-types";
import { HTMLAttributes } from "react";
import { cn } from "@/lib/utils";
import { Badge } from "@/components/ui/badge";
import { formatDateShort } from "@/lib/dates";

interface InventoryEntryListItemProps extends HTMLAttributes<HTMLLIElement> {
  entry: InventoryEntry;
}

export default function InventoryEntryListItem({
  entry,
  className,
  ...other
}: InventoryEntryListItemProps) {
  return (
    <li className={cn("flex items-center text-sm", className)} {...other}>
      <div>{entry.description}</div>
      <div className="ml-auto space-x-3">
        <Badge variant="gray">{entry.balance}</Badge>
        <span className="text-xs text-muted-foreground">
          {formatDateShort(entry.created_at)}
        </span>
      </div>
    </li>
  );
}
