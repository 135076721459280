import Toolbar from "@/components/ui/toolbar";
import { Card, CardDescription, CardTitle } from "@/components/ui/card";
import Markdown from "@/components/ui/markdown";
import PurchaseOrderForm from "@/components/orders/PurchaseOrderForm";
import { useGetString } from "@/context/AppContext";
import { UploadPurchaseOrderPayload } from "@/api/project";

export default function PurchaseOrderCard({
  onSubmit,
  actions,
}: {
  onSubmit: (v: UploadPurchaseOrderPayload) => Promise<unknown>;
  actions?: React.ReactNode;
}) {
  const getString = useGetString();
  return (
    <Card className="p-5 shadow-none">
      <Toolbar actions={actions}>
        <CardTitle className="text-xl">Upload Your Purchase Order</CardTitle>
      </Toolbar>
      <CardDescription className="mb-6 mt-2">
        <Markdown>
          {getString(
            "po_instructions",
            "In order to ensure we get paid, a purchase order is required to proceed.",
          )}
        </Markdown>
      </CardDescription>
      <PurchaseOrderForm onSubmit={onSubmit} />
    </Card>
  );
}
