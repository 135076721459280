import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import { Attachment, CustomerDesignLayout } from "avail-types";

export function useInfiniteDesignLayouts(query: string) {
  return useInfiniteQuery(
    ["layouts", query],
    ({ pageParam }) =>
      axios
        .get<{
          data: CustomerDesignLayout[];
          meta: { current_page: number; last_page: number };
        }>("/design-layouts", {
          params: {
            page: pageParam,
            query,
            count: 12,
          },
        })
        .then(({ data }) => data),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.meta.current_page < lastPage.meta.last_page) {
          return lastPage.meta.current_page + 1;
        }
        return undefined;
      },
    },
  );
}

export function useSearchDesignLayouts(query: string) {
  const queryClient = useQueryClient();

  return useQuery(["layouts", query], () =>
    axios
      .get<{ data: CustomerDesignLayout[] }>(`/design-layouts?query=${query}`)
      .then((res) => {
        return res.data.data.map((layout) => {
          queryClient.setQueryData(["layout", layout.id], layout);
          return layout;
        });
      }),
  );
}

export function useDesignLayout(id: number | null | undefined) {
  return useQuery(
    ["layout", id],
    () =>
      axios
        .get<CustomerDesignLayout & { attachments: Attachment[] }>(
          `/design-layouts/${id}`,
        )
        .then((res) => res.data),
    {
      enabled: id != null,
    },
  );
}
