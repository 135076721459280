import ProjectActivitySection from "@/components/projects/activity/ProjectActivitySection";
import ProjectStatuses from "@/components/projects/ProjectStatuses";
import ProjectDetails from "@/components/projects/ProjectDetails";
import ProjectHeader from "@/components/projects/ProjectHeader";

export default function ProjectDetail() {
  return (
    <div className="relative flex-1 space-y-4 print:space-y-0">
      <ProjectHeader />

      <div className="grid gap-5 pb-16 xl:grid-cols-3">
        <div className="xl:col-span-2">
          <div className="sticky top-0 space-y-4">
            <ProjectStatuses />
          </div>
        </div>
        <div className="space-y-4">
          <ProjectDetails />
          <ProjectActivitySection />
        </div>
      </div>
    </div>
  );
}
