import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { CustomerUserInvite } from "avail-types";

export function useGetInvites() {
  return useQuery(["invites"], () =>
    axios
      .get<{
        data: CustomerUserInvite[];
      }>("/invites")
      .then(({ data }) => data.data),
  );
}

export function useRemoveInviteRequest() {
  const queryClient = useQueryClient();
  return useMutation((id: number) => axios.delete(`/invites/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(["invites"]);
    },
  });
}
