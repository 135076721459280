import { FieldErrors, UseFormReturn } from "react-hook-form";
import { Alert } from "@/components/ui/alert";

function getMessageArray(
  errors: FieldErrors,
  path = "",
): { path: string; message: string }[] {
  return Object.entries(errors).flatMap(([key, value]) => {
    if (!value) {
      return [];
    }
    const thisPath = path ? `${path}.${key}` : key;
    if (typeof value.message === "string") {
      return [{ path: thisPath, message: value.message }];
    }
    return getMessageArray(value as FieldErrors, thisPath);
  });
}

function Error({ message, path }: { message: string; path: string }) {
  const hidePath = path === "root" || message.includes("This field");
  return (
    <span>
      {message.replace("This field", path)} {hidePath ? "" : `(${path})`}
    </span>
  );
}

export function FormErrors({ form }: { form: UseFormReturn<any> }) {
  const { errors } = form.formState;

  if (Object.keys(errors).length === 0) {
    return null;
  }

  return (
    <Alert variant="destructive" className="my-4">
      <p className="mb-2">Please fix the following errors before submitting:</p>

      <ul className="list-inside list-disc">
        {getMessageArray(errors).map((error) => (
          <li key={error.path}>
            <Error {...error} />
          </li>
        ))}
      </ul>
    </Alert>
  );
}
