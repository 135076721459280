import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { handleLaravelErrors, maybeRenderRootError } from "@/lib/form";
import { CustomerOrder } from "avail-types";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { CardContent } from "@/components/ui/card";
import { useTitle } from "@/lib/title";
import BusinessContext from "@/context/BusinessContext";
import CardPage from "@/layouts/CardPage";

const formSchema = z.object({
  email: z.string().email(),
  number: z.string(),
});

export default function FindOrder() {
  useTitle("Find Order");

  const { business: slug } = useParams<{ business: string }>();
  const { data: business } = useQuery(["business", slug], () =>
    axios.get(`/businesses/${slug}`).then(({ data }) => data),
  );
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    return axios
      .post<CustomerOrder>(`/businesses/${slug}/find-order`, values)
      .then(({ data }) => {
        navigate(`/guest/orders/${data.code}`);
      })
      .catch(handleLaravelErrors(form));
  }

  return (
    <BusinessContext.Provider value={{ business }}>
      <CardPage title="Find your order">
        <CardContent>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="mt-8 space-y-4"
            >
              {maybeRenderRootError(form)}

              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input type="email" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="number"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Order Number</FormLabel>
                    <FormControl>
                      <Input type="text" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button
                className="w-full"
                type="submit"
                isLoading={form.formState.isSubmitting}
              >
                Submit
              </Button>
            </form>
          </Form>
        </CardContent>
      </CardPage>
    </BusinessContext.Provider>
  );
}
