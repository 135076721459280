import { AxiosError } from "axios";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { Alert } from "@/components/ui/alert";

export const handleLaravelErrors =
  <T extends FieldValues>(form: UseFormReturn<T>, prefix?: string) =>
  (
    error: AxiosError<{ message?: string; errors?: Record<string, string[]> }>,
  ) => {
    const data = error.response?.data;
    if (!data) {
      return;
    }
    if (data.message) {
      form.setError("root", {
        type: "server",
        message: data.message,
      });
    }
    if (data.errors) {
      for (const [key, value] of Object.entries(data.errors)) {
        // @ts-ignore
        form.setError(prefix ? `${prefix}.${key}` : key, {
          type: "server",
          message: value[0],
        });
      }
    }
  };

export function maybeRenderRootError<T extends FieldValues>(
  form: UseFormReturn<T>,
) {
  if (form.formState.errors.root) {
    return (
      <Alert variant="destructive">{form.formState.errors.root.message}</Alert>
    );
  }
  return null;
}
