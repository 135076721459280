import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { CustomerMembership } from "avail-types";

export function useMemberships() {
  return useQuery(["memberships"], () =>
    axios
      .get<{
        data: CustomerMembership[];
      }>("/memberships", {
        params: {
          sort: "role,user.name",
        },
      })
      .then(({ data }) => data.data),
  );
}

export function useRemoveMembershipRequest() {
  const queryClient = useQueryClient();
  return useMutation((id: number) => axios.delete(`/memberships/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(["memberships"]);
    },
  });
}

export function useUpdateMembershipRequest() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, role }: { id: number; role: string }) =>
      axios
        .put<CustomerMembership>(`/memberships/${id}`, { role })
        .then(({ data }) => data),
    {
      onSuccess: (data) => {
        queryClient.setQueryData<CustomerMembership[]>(
          ["memberships"],
          (prev) => {
            if (!prev) return prev;
            return prev.map((p) => {
              if (p.id === data.id) {
                return { ...p, ...data };
              }
              return p;
            });
          },
        );
      },
    },
  );
}
