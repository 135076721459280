import { Outlet, useParams, useSearchParams } from "react-router-dom";
import { useCallback, useState } from "react";
import { useGetProject } from "@/api/project";
import { useTitle } from "@/lib/title";
import { Skeleton } from "@/components/ui/skeleton";
import Lottie from "lottie-react";
import confetti from "@/assets/lottie/confetti2.json";
import { ProjectContext } from "@/context/ProjectContext";

export default function ProjectLayout() {
  const { id } = useParams<{ id: string }>();
  const [showConfetti, setShowConfetti] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const onShowConfetti = useCallback(() => {
    setShowConfetti(true);
  }, [setShowConfetti]);

  const { data, isLoading } = useGetProject(Number(id), (p) => {
    if (searchParams.has("created") && p.status === "options_requested") {
      onShowConfetti();
      setSearchParams((prev) => {
        prev.delete("created");
        return prev;
      });
    }
  });

  useTitle(data?.name, "Project");

  if (isLoading)
    return (
      <div>
        <Skeleton className="h-6 w-1/2" />
      </div>
    );

  if (!data) {
    return null;
  }

  return (
    <ProjectContext.Provider
      value={{
        project: data,
        onShowConfetti,
      }}
    >
      <Outlet />
      {showConfetti && (
        <div className="pointer-events-none absolute inset-0">
          <Lottie
            animationData={confetti}
            className="h-full w-full"
            onLoopComplete={() => setShowConfetti(false)}
          />
        </div>
      )}
    </ProjectContext.Provider>
  );
}
