import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import axios from "axios";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { Input } from "@/components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Alert } from "@/components/ui/alert";
import Link from "@/components/ui/link";
import { Upload } from "lucide-react";

export default function BulkAddFulfillmentOrderForm() {
  const navigate = useNavigate();
  const [error, setError] = useState<string>("");
  const [file, setFile] = useState<File | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const f = e.target.files?.[0];
    setError("");
    if (!f) return;
    setFile(f);
  };

  const resetForm = () => {
    setFile(null);
    setLoading(false);
    setError("");
    setOpen(false);
  };

  function onSubmit(e: SyntheticEvent) {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    if (!file) {
      setError("Please select a file");
      setLoading(false);
      return;
    }

    formData.append("file", file);

    return axios
      .post(`/fulfillment-orders/bulk-request`, formData)
      .then(() => {
        resetForm();
        navigate("/fulfillment-orders");
      })
      .catch((error) => {
        setLoading(false);
        setError(
          error.response.data.message ||
            "An error occurred. Please try again later.",
        );
      });
  }

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button type="button">
          <Upload className="mr-2 size-4" />
          Bulk Upload
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Upload Fulfillment Orders</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          You can bulk upload fulfillment orders with an excel sheet. Please
          download the template below to get started.
        </DialogDescription>
        <Link
          href="/bulk_fulfillment_template.xlsx"
          download="bulk_fulfillment_template"
          target="blank"
        >
          Download Template
        </Link>
        <form onSubmit={onSubmit} className="space-y-4">
          <Input
            type="file"
            onChange={handleFileUpload}
            accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
          {error && <Alert variant="destructive">{error}</Alert>}
          <DialogFooter>
            <Button variant="outline" onClick={() => resetForm()}>
              Cancel
            </Button>
            <Button isLoading={loading} type="submit">
              Upload
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
