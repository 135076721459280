import { Card, CardFooter } from "@/components/ui/card";

export default function ProjectOptionSearchCard({
  onClick,
  product,
}: {
  onClick: () => void;
  product: { name: string; pic: string; price: string };
}) {
  return (
    <Card
      className="cursor-pointer p-1 hover:shadow-md"
      onClick={() => onClick()}
    >
      <div className="block aspect-square flex-grow p-4">
        <img
          src={product.pic}
          alt={product.name}
          className="h-full w-full object-cover"
        />
      </div>
      <CardFooter className="mt-2 flex-col items-start">
        <div className="text-sm font-medium">{product.name}</div>
        <div className="mt-1 text-sm">{product.price}</div>
      </CardFooter>
    </Card>
  );
}
