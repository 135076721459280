import { CustomerOrder, ProjectOption } from "avail-types";
import { Link } from "react-router-dom";
import { PackagePlus } from "lucide-react";
import { curr } from "@/lib/curr";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import OrderTrackingNumbers from "@/components/orders/OrderTrackingNumbers";

export default function ProjectOrderCard({
  order,
  option,
}: {
  order: CustomerOrder;
  option: ProjectOption | undefined;
}) {
  const approval = order.outstanding_approvals?.[0];
  const title = option ? option.name : order.description;

  const getAction = () => {
    if (approval) {
      return (
        <Button asChild color="default" className="w-full">
          <Link to={`./approvals/${approval.code}`}>Review Approval</Link>
        </Button>
      );
    }

    return (
      <Button
        disabled
        variant={order.status === "Complete" ? "success" : "secondary"}
        className="w-full"
      >
        {order.status === "Order Received" ? "Proof Pending" : order.status}
      </Button>
    );
  };

  return (
    <Card className="relative flex h-full w-full flex-col items-center px-4 py-3 text-center shadow-none">
      <Link to={`./orders/${order.code}`} className="h-full w-full">
        <Avatar className="h-48 w-full rounded-none">
          {option?.images[0] && <AvatarImage src={option.images[0]} />}
          <AvatarFallback className="w-full rounded-none">
            <PackagePlus className="size-12 xl:h-16 xl:w-16" />
          </AvatarFallback>
        </Avatar>
      </Link>

      <Link
        to={`./orders/${order.code}`}
        className="mt-5 line-clamp-2 min-h-[3rem] text-base font-semibold"
        title={title}
      >
        {title}
      </Link>
      <div className="mt-1 text-muted-foreground">{curr(order.total)}</div>

      <div className="mt-3 flex w-full">{getAction()}</div>

      {order.status === "Complete" && <OrderTrackingNumbers order={order} />}
    </Card>
  );
}
