import Title from "@/components/ui/title";
import Toolbar from "@/components/ui/toolbar";
import Description from "@/components/ui/description";
import SearchDesignLayouts from "@/components/designLayouts/SearchDesignLayouts";
import { useSettings } from "@/context/AppContext";
import { useTitle } from "@/lib/title";

export default function Closet() {
  const settings = useSettings();
  useTitle("Closet");

  return (
    <div className="flex-1 space-y-4">
      <Toolbar className="mb-8">
        <Title>Closet</Title>
        <Description>
          Your closet is a collection of your previous designs and{" "}
          {settings.projects_enabled ? "projects" : "orders"}. Use this library
          as inspiration or to jump start a{" "}
          {settings.projects_enabled ? "project" : "order"}.
        </Description>
      </Toolbar>

      <SearchDesignLayouts />
    </div>
  );
}
