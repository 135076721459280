import { HTMLAttributes } from "react";
import { cn } from "@/lib/utils";

export default function BrandCardActions({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        "absolute right-2 top-2 hidden space-x-2 group-hover:flex",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}
