import Toolbar from "@/components/ui/toolbar";
import Title from "@/components/ui/title";
import InventoryReturnTable from "@/components/inventory/InventoryReturnTable";
import { useTitle } from "@/lib/title";

export default function InventoryReturns() {
  useTitle("Inventory Returns");

  return (
    <div className="flex-1 space-y-6">
      <Toolbar>
        <Title>Inventory Returns</Title>
      </Toolbar>

      <InventoryReturnTable />
    </div>
  );
}
