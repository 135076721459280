import { Link } from "react-router-dom";
import { Notification } from "avail-types";
import { cn } from "@/lib/utils";
import {
  useNotificationMarkRead,
  useNotificationMarkUnread,
} from "@/api/notifications";
import UserAvatar from "@/components/account/UserAvatar";
import { Check } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { SyntheticEvent } from "react";
import { formatDateTimeShort } from "@/lib/dates";

export default function NotificationListItem({
  notification: n,
}: {
  notification: Notification;
}) {
  const markReadRequest = useNotificationMarkRead();
  const markUnreadRequest = useNotificationMarkUnread();

  const toggleRead = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    (n.read_at ? markUnreadRequest : markReadRequest).mutate([n.id]);
  };

  return (
    <li>
      <Link
        to={n.details.href}
        className="group flex w-full items-center px-5 py-3 text-left hover:bg-muted"
      >
        <UserAvatar
          className="mr-4"
          user={{ avatar: n.details.avatar, name: n.details.user }}
        />
        <div>
          <p className="mb-1 leading-snug">
            <span className="font-medium">{n.details.user}</span>
            <span> {n.details.action}</span>
          </p>

          <p className="space-x-1 text-xs text-muted-foreground">
            <span>{formatDateTimeShort(n.created_at)}</span>
            <span>-</span>
            <span className="font-medium text-primary">{n.details.label}</span>
          </p>
        </div>
        <div className="ml-auto flex items-center space-x-1">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <button
                  type="button"
                  className="invisible p-2 group-hover:visible"
                  onClick={toggleRead}
                >
                  <Check className="size-5" />
                </button>
              </TooltipTrigger>
              <TooltipContent>
                Mark {n.read_at ? "unread" : "read"}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <div
            className={cn(
              "size-2 shrink-0 rounded-full",
              n.read_at ? "bg-transparent" : "bg-primary",
            )}
          />
        </div>
      </Link>
    </li>
  );
}
