import { CartProduct, ProjectOption } from "avail-types";
import { imageUrl } from "@/lib/image";
import { Button } from "@/components/ui/button";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useDuplicateColorOption } from "@/api/project-options";

export default function ProjectOptionDuplicateColorDialog({
  option,
  onSuccess,
  selected,
  onClose,
}: {
  option: ProjectOption;
  onSuccess: (option: ProjectOption) => void;
  selected: CartProduct;
  onClose: () => void;
}) {
  const duplicateColorOption = useDuplicateColorOption();

  return (
    <AlertDialog open>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Choose Color: {selected.color}</AlertDialogTitle>
          <AlertDialogDescription>
            This color was not explicitly added as an option by our team, but it
            is available in the selected product. Would you like to create this
            color as an option?
            <img
              src={imageUrl(selected.image!, {
                w: 100,
                h: 100,
                fit: "contain",
              })}
              alt=""
              className="h-64 p-3"
            />
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onClose}>Cancel</AlertDialogCancel>
          <Button
            variant="default"
            isLoading={duplicateColorOption.isLoading}
            onClick={() => {
              if (selected) {
                duplicateColorOption
                  .mutateAsync({
                    optionId: option.id,
                    product_id: selected.id,
                  })
                  .then((option) => {
                    onClose();
                    onSuccess(option);
                  });
              }
            }}
          >
            Create
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
