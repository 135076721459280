import { HTMLAttributes } from "react";
import { cn } from "@/lib/utils";

export default function WizardStep({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn("space-y-4 pb-[5.4rem] md:space-y-6 md:pb-6", className)}
      {...props}
    >
      {children}
    </div>
  );
}
