import { CheckIcon } from "@heroicons/react/20/solid";

export default function SelectedProductIcon({ qty }: { qty: number }) {
  return (
    <div className="absolute bottom-4 right-4 flex h-7 items-center justify-center gap-x-1 rounded-full bg-green-600 px-3 text-xs font-medium leading-none text-white">
      <CheckIcon className="size-5" />
      <span>x</span>
      {qty}
    </div>
  );
}
