import { Input } from "@/components/ui/input";
import { useEffect, useRef, useState } from "react";
import useDebounce from "@/hooks/useDebounce";

export default function SearchInput({
  onChange,
  defaultValue = "",
  placeholder = "Search",
  className = "w-72",
}: {
  onChange: (s: string) => void;
  defaultValue?: string;
  placeholder?: string;
  className?: string;
}) {
  const [value, setValue] = useState(defaultValue);
  const debouncedValue = useDebounce(value, 500);
  const mounted = useRef<boolean>(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }

    onChange(debouncedValue);
  }, [debouncedValue]);

  return (
    <Input
      type="search"
      name="search"
      className={className}
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}
