import ProjectOrderCard from "@/components/projects/ProjectOrderCard";
import { useProject } from "@/context/ProjectContext";
import { useProjectSelections } from "@/api/project-selections";
import { Alert } from "@/components/ui/alert";
import FloatingBar from "@/components/ui/floating-bar";
import pluralize from "pluralize";
import { CheckIcon } from "@heroicons/react/20/solid";
import ProjectPayButton from "@/components/projects/ProjectPayButton";
import { useDownloadProject, useUploadPurchaseOrder } from "@/api/project";
import { Card } from "@/components/ui/card";
import { AlertTriangle, FileText } from "lucide-react";
import { Button } from "@/components/ui/button";
import ProjectCardGrid from "@/components/projects/ProjectCardGrid";
import {
  hasProofsToReview,
  isWaitingOnPayment,
  isWaitingOnPO,
} from "@/lib/projects";
import PurchaseOrderCard from "@/components/orders/PurchaseOrderCard";

export default function ProofsRequestedStatus() {
  const project = useProject();
  const uploadPoRequest = useUploadPurchaseOrder(project.id);
  const downloadRequest = useDownloadProject(project.id);
  const { data } = useProjectSelections();
  const selections = data?.data || [];

  return (
    <div className="space-y-6">
      {hasProofsToReview(project) ? (
        <Alert variant="info">
          Please review the following art and order approvals below. Be sure to
          double check your artwork and sizing. Once approved, your order will
          go into production.
        </Alert>
      ) : isWaitingOnPO(project) ? (
        <Alert variant="warning">
          In order to proceed with your project, we need to receive a Purchase
          Order from you. Please upload your PO below.
        </Alert>
      ) : isWaitingOnPayment(project) ? (
        <Card className="flex items-center justify-between px-4 py-2">
          <div className="flex items-center">
            <AlertTriangle className="mr-2 size-5 text-yellow-500" />
            Payment is required upfront for this project. Your project will
            remain on hold until payment is received.
          </div>
          <ProjectPayButton project={project} />
        </Card>
      ) : (
        <Alert variant="info">
          Our art team is hard at work on your approvals. Hang tight - we'll let
          you know when they are ready to review!
        </Alert>
      )}

      {isWaitingOnPO(project) && (
        <PurchaseOrderCard
          onSubmit={(v) => uploadPoRequest.mutateAsync(v)}
          actions={
            <Button
              variant="outline"
              onClick={() => downloadRequest.mutate()}
              isLoading={downloadRequest.isLoading}
            >
              <FileText className="mr-2 size-4" /> Quote
            </Button>
          }
        />
      )}

      <ProjectCardGrid>
        {project.orders.map((order) => (
          <ProjectOrderCard
            key={order.id}
            order={order}
            option={
              selections.find((s) => s.option.order_id === order.id)?.option
            }
          />
        ))}
      </ProjectCardGrid>

      {project.orders.some((o) => o.status !== "Order Received") && (
        <FloatingBar>
          <div className="flex gap-1 font-medium">
            <span className="mr-2 flex size-6 flex-shrink-0 items-center justify-center rounded-full bg-green-600">
              <CheckIcon className="size-4 text-white" />
            </span>
            <span>
              {
                project.orders.filter((o) => o.status !== "Order Received")
                  .length
              }
            </span>
            <span>/</span>
            <span>
              {pluralize("item", project.orders.length, true)} approved
            </span>
          </div>
        </FloatingBar>
      )}
    </div>
  );
}
