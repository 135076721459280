import { useNotificationMarkRead, useNotifications } from "@/api/notifications";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Bell } from "lucide-react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ActivityCard from "@/components/dashboard/ActivityCard";
import SidenavItem from "@/components/navigation/SidenavItem";
import trim from "lodash/trim";

export default function ActivityNavItem({
  isCollapsed,
}: {
  isCollapsed?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const { data } = useNotifications();
  const notifications = data ? data.pages.flatMap((p) => p.data) : [];
  const markRead = useNotificationMarkRead();

  useEffect(() => {
    const notificationsToMarkRead = notifications.filter(
      (n) =>
        trim(pathname, "/") === trim(n.details.href.split("?")[0], "/") &&
        !n.read_at,
    );
    if (notificationsToMarkRead.length > 0) {
      markRead.mutate(notificationsToMarkRead.map((n) => n.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, notifications.length]);

  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger className="w-full">
        <SidenavItem
          label="Activity"
          Icon={Bell}
          count={data?.pages[0]?.meta.unread_count}
          isCollapsed={isCollapsed}
        />
      </PopoverTrigger>
      <PopoverContent className="max-h-96 w-[30rem] max-w-full overflow-y-auto border-none p-0">
        <ActivityCard onClose={() => setOpen(false)} />
      </PopoverContent>
    </Popover>
  );
}
