import startCase from "lodash/startCase";
import { curr } from "@/lib/curr";
import ProjectEditableField from "@/components/projects/ProjectEditableField";
import { useProject } from "@/context/ProjectContext";
import AutoForm from "@/components/ui/auto-form";
import ProjectAddressForm from "@/components/projects/ProjectAddressForm";
import { useAppState } from "@/context/AppContext";
import pick from "lodash/pick";
import AddressBlock from "@/components/addresses/AddressBlock";
import ProjectFieldForm from "@/components/projects/ProjectFieldForm";
import { SHIPPING_MODE_LABELS } from "@/constants/project";
import ChooseUserForm from "@/components/projects/ChooseUserForm";
import { getProjectFieldModels } from "@/lib/projects";
import { projectCreatePayloadSchema, ProjectUpdatePayload } from "avail-types";
import { formatDate } from "@/lib/dates";
import Gate from "@/components/roles/Gate";

type SubmitHandler = (v: ProjectUpdatePayload) => Promise<unknown>;

export default function ProjectFields() {
  const project = useProject();
  const { settings } = useAppState();
  const customerProjectFields = getProjectFieldModels(
    settings.project_fields,
    project.fields,
  );

  const getTypeForm = (onSubmit: SubmitHandler) => (
    <AutoForm
      formSchema={projectCreatePayloadSchema.pick({
        type: true,
      })}
      fieldConfig={{
        type: {
          fieldType: "radio",
          optionLabels: {
            project: "Normal Project",
            ["self-service"]: "Self-Service",
            webstore: "Webstore",
            fulfill_store: "Fulfillment Store",
          },
        },
      }}
      defaultValues={pick(project, ["type"])}
      onSubmit={onSubmit}
      submitLabel="Save"
    />
  );

  const getUserForm = (onSubmit: SubmitHandler) => (
    <ChooseUserForm onSubmit={onSubmit} />
  );

  const getBudgetForm = (onSubmit: SubmitHandler) => (
    <AutoForm
      formSchema={projectCreatePayloadSchema.pick({
        budget_type: true,
        budget_amount: true,
        budget_qty: true,
      })}
      fieldConfig={{
        budget_type: {
          fieldType: "radio",
          optionLabels: {
            total: "Total",
            per_person: "Per Person",
          },
        },
        budget_amount: {
          fieldType: "currency",
        },
      }}
      defaultValues={pick(project, [
        "budget_type",
        "budget_amount",
        "budget_qty",
      ])}
      onSubmit={onSubmit}
      submitLabel="Save"
    />
  );

  const getInHandsDateForm = (onSubmit: SubmitHandler) => (
    <AutoForm
      formSchema={projectCreatePayloadSchema.pick({ in_hands_date: true })}
      fieldConfig={{
        in_hands_date: {
          fieldType: "date",
        },
      }}
      defaultValues={{
        in_hands_date: project.in_hands_date,
      }}
      onSubmit={onSubmit}
      submitLabel="Save"
    />
  );

  const getShippingForm = (onSubmit: SubmitHandler) => (
    <AutoForm
      formSchema={projectCreatePayloadSchema.pick({ shipping_mode: true })}
      fieldConfig={{
        shipping_mode: {
          fieldType: "radio",
          optionLabels: SHIPPING_MODE_LABELS,
        },
      }}
      defaultValues={{
        shipping_mode: project.shipping_mode,
      }}
      onSubmit={onSubmit}
      submitLabel="Save"
    />
  );

  if (project.type === "webstore") {
    return (
      <div className="space-y-2">
        <Gate role={["avail", "avail_incognito"]}>
          <ProjectEditableField label="Project Type" getForm={getTypeForm}>
            {startCase(project.type)}
          </ProjectEditableField>
        </Gate>

        <ProjectEditableField
          label="Primary Contact"
          getForm={getUserForm}
          allowEditAfterProofsRequested
        >
          {project.user?.name}
        </ProjectEditableField>

        <ProjectEditableField label="Go-Live Date" getForm={getInHandsDateForm}>
          {formatDate(project.in_hands_date)}
        </ProjectEditableField>
      </div>
    );
  }

  return (
    <div className="space-y-2">
      <Gate role={["avail", "avail_incognito"]}>
        <ProjectEditableField label="Project Type" getForm={getTypeForm}>
          {startCase(project.type)}
        </ProjectEditableField>
      </Gate>

      <ProjectEditableField
        label="Primary Contact"
        getForm={getUserForm}
        allowEditAfterProofsRequested
      >
        {project.user?.name}
      </ProjectEditableField>

      <ProjectEditableField
        label="Budget"
        getForm={getBudgetForm}
        formLabel="Project Budget"
      >
        {curr(project.budget_amount)} {startCase(project.budget_type)}
      </ProjectEditableField>

      <ProjectEditableField
        label={
          project.budget_type === "total"
            ? "Estimated Quantities"
            : "Number of People"
        }
        getForm={getBudgetForm}
        formLabel="Project Budget"
      >
        {project.budget_qty.toLocaleString()}
      </ProjectEditableField>

      <ProjectEditableField label="In-Hands Date" getForm={getInHandsDateForm}>
        {formatDate(project.in_hands_date)}
      </ProjectEditableField>

      <ProjectEditableField label="Shipping Method" getForm={getShippingForm}>
        {SHIPPING_MODE_LABELS[project.shipping_mode]}
      </ProjectEditableField>

      {project.shipping_mode === "bulk" && (
        <ProjectEditableField
          label="Shipping Address"
          getForm={(onSubmit) => <ProjectAddressForm onSubmit={onSubmit} />}
        >
          {project.address ? (
            <AddressBlock address={project.address} />
          ) : (
            <span className="text-muted-foreground">No address provided</span>
          )}
        </ProjectEditableField>
      )}

      {getProjectFieldModels(settings.project_fields, project.fields).map(
        (field) => (
          <ProjectEditableField
            key={field.id}
            label={field.label}
            getForm={(onSubmit) => (
              <ProjectFieldForm
                onSubmit={onSubmit}
                initialFields={project.fields.map((f) => ({
                  customer_project_field_id: f.field.id,
                  value: f.value,
                }))}
                customerProjectFields={customerProjectFields}
                submitLabel="Save"
              />
            )}
            allowEditAfterProofsRequested={true}
          >
            {project.fields.find((f) => f.field.id === field.id)?.value}
          </ProjectEditableField>
        ),
      )}
    </div>
  );
}
