import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ProductCollection, CartProduct } from "avail-types";

export function useGetCollections() {
  return useQuery(["collections"], () =>
    axios
      .get<{
        data: ProductCollection[];
      }>("collections")
      .then(({ data }) => data.data),
  );
}

export function useGetCollection(slug: string) {
  return useQuery(["collection", slug], () =>
    axios
      .get<ProductCollection & { products: CartProduct[] }>(
        `collections/${slug}`,
      )
      .then(({ data }) => data),
  );
}

export function useGetCollectionById(id: number) {
  return useQuery(
    ["collectionById", id],
    () =>
      axios
        .get<ProductCollection>(`collections/by-id/${id}`)
        .then(({ data }) => data),
    {
      refetchOnMount: false,
      staleTime: 60 * 1000 * 15, // 15 minutes
    },
  );
}
