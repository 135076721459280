import { ProjectOption } from "avail-types";
import { useDuplicateProjectOption } from "@/api/project-options";

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogHeader,
  AlertDialogCancel,
  AlertDialogFooter,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Copy } from "lucide-react";
import { useState } from "react";

export default function ProjectOptionDuplicateButton({
  option,
}: {
  option: ProjectOption;
}) {
  const [open, setOpen] = useState(false);
  const duplicateRequest = useDuplicateProjectOption();

  const onSubmit = () => {
    duplicateRequest.mutateAsync(option.id).then(() => {
      setOpen(false);
    });
  };

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger>
        <Copy className="size-5 text-muted-foreground" />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Duplicate Option</AlertDialogTitle>
        </AlertDialogHeader>
        <div className="space-y-3 py-4">
          Are you sure you want to duplicate this option?
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            variant="default"
            isLoading={duplicateRequest.isLoading}
            onClick={onSubmit}
          >
            Duplicate
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
