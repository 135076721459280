import * as React from "react";

import { cn } from "@/lib/utils";

export const TotalTable = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(({ className, children, ...props }, ref) => (
  <table ref={ref} className={cn(className)} {...props}>
    <tbody>{children}</tbody>
  </table>
));
TotalTable.displayName = "TotalTable";

export const TotalTableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
  <tr ref={ref} className={cn(className)} {...props} />
));
TotalTableRow.displayName = "TotalTableRow";

export const TotalTableLabel = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn("py-1 pr-12 text-right font-normal", className)}
    {...props}
  />
));
TotalTableLabel.displayName = "TotalTableLabel";

export const TotalTableValue = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn("py-1 pl-12 text-right font-medium", className)}
    {...props}
  />
));
TotalTableValue.displayName = "TotalTableValue";
