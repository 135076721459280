import { Coffee, LucideIcon } from "lucide-react";
import { ReactNode } from "react";
import { cn } from "@/lib/utils";

export default function EmptyState({
  title,
  description,
  children,
  Icon = Coffee,
  className,
}: {
  title: string;
  description?: string;
  children?: ReactNode;
  Icon?: LucideIcon;
  className?: string;
}) {
  return (
    <div className={cn("py-3 text-center text-base", className)}>
      <Icon className="inline-block size-16 text-slate-400" />
      <h3 className="mt-3 text-lg font-semibold text-slate-900">{title}</h3>
      {description && (
        <p className="mt-1 text-sm text-slate-500">{description}</p>
      )}
      {children && <div className="mt-6">{children}</div>}
    </div>
  );
}
