import { ReactNode } from "react";
import { useAppContext } from "@/context/AppContext";
import GuestLayout from "@/layouts/GuestLayout";
import AppLayout from "@/layouts/AppLayout";

export default function DyanmicLayout({
  children,
  isCollapsed = true,
}: {
  children: ReactNode | ((v: { top: string; left: string }) => ReactNode);
  isCollapsed?: boolean;
}) {
  const { membership } = useAppContext();

  if (!membership) {
    return (
      <GuestLayout>
        {typeof children === "function"
          ? children({
              top: "top-14",
              left: "left-0",
            })
          : children}
      </GuestLayout>
    );
  }

  return (
    <AppLayout isCollapsed={isCollapsed}>
      {typeof children === "function"
        ? children({
            top: "top-0",
            left: isCollapsed ? "left-14" : "left-[17rem]",
          })
        : children}
    </AppLayout>
  );
}
