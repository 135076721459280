import { HTMLAttributes } from "react";
import { cn } from "@/lib/utils";

export default function SidenavCount({
  children,
  className,
  ...other
}: HTMLAttributes<HTMLSpanElement>) {
  return (
    <span
      className={cn(
        "ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-drawer px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-drawer-foreground ring-1 ring-inset ring-drawer-foreground/20",
        className,
      )}
      aria-hidden="true"
      {...other}
    >
      {children}
    </span>
  );
}
