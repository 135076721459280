import { Outlet } from "react-router-dom";
import CartSidebar from "@/components/cart/CartSidebar";
import BreadcrumbsFromRoutes from "@/layouts/BreadcrumbsFromRoutes";
import CollectionVerticalNavigation from "@/components/collections/CollectionVerticalNavigation";
import CartDrawer from "@/components/cart/CartDrawer";
import { cn } from "@/lib/utils";
import { useIsDesktop } from "@/lib/screen";
import DyanmicLayout from "@/layouts/DyanmicLayout";

export default function CollectionWrapper() {
  const isDesktop = useIsDesktop();

  return (
    <DyanmicLayout>
      {({ top, left }) => (
        <>
          <div className={cn("relative flex-1", isDesktop && "ml-64 mr-96")}>
            <BreadcrumbsFromRoutes homeHref="." />
            {!isDesktop && (
              <div className="absolute right-5 top-4">
                <CartDrawer checkoutUrl="/projects/checkout" />
              </div>
            )}
            <Outlet />
          </div>
          {isDesktop && (
            <>
              <div
                className={cn(
                  "flex-0 fixed bottom-0 w-64 overflow-y-auto border-r bg-white px-5 py-6 text-sm",
                  top,
                  left,
                )}
              >
                <CollectionVerticalNavigation />
              </div>

              <div
                className={cn(
                  "fixed bottom-0 right-0 w-96 overflow-y-auto border-l bg-white p-6 pt-5",
                  top,
                )}
              >
                <CartSidebar checkoutUrl="/projects/checkout" />
              </div>
            </>
          )}
        </>
      )}
    </DyanmicLayout>
  );
}
