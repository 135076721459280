import { useState } from "react";
import { z } from "zod";
import AddressFields from "@/components/addresses/AddressFields";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useUpdateAddress } from "@/api/addresses";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "@/components/ui/form";
import { useToast } from "@/components/ui/use-toast";
import { Address, addressPayloadSchema } from "avail-types";
import { PencilIcon } from "lucide-react";
import { handleLaravelErrors } from "@/lib/form";

const formSchema = z.object({
  address: addressPayloadSchema,
});

export default function AddressUpdateDialog({ address }: { address: Address }) {
  const [open, setOpen] = useState(false);
  const { toast } = useToast();
  const updateRequest = useUpdateAddress(address.id);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      address: address,
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    return updateRequest
      .mutateAsync(values.address)
      .then(() => {
        toast({ title: "Address Saved", variant: "success" });
        setOpen(false);
      })
      .catch(handleLaravelErrors(form, "address"));
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="flex-grow flex-col text-sm">
        <PencilIcon className="size-5 text-slate-500" />
      </DialogTrigger>
      <DialogContent className="max-w-2xl">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
            <DialogHeader>
              <DialogTitle>Edit Address</DialogTitle>
            </DialogHeader>
            <AddressFields form={form} />
            <DialogFooter>
              <Button
                type="submit"
                variant="default"
                isLoading={form.formState.isSubmitting}
                disabled={!form.formState.isValid}
              >
                Update
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
