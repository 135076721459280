import mapValues from "lodash/mapValues";

export function imageUrl(
  value: string | undefined | null,
  params: Record<string, string | number> = {},
): string | undefined {
  if (!value) {
    return undefined;
  }
  if (value.startsWith("data:") || !value.includes("files.availerp.com")) {
    return value;
  }
  try {
    const url = new URL(value);
    if (!url.search) {
      url.search = `?${new URLSearchParams(
        mapValues(params, (s) => String(s)),
      ).toString()}`;
    }
    return url.toString();
  } catch (e) {
    return value;
  }
}
