import { HTMLAttributes } from "react";
import ReactMarkdown from "react-markdown";
import { cn } from "@/lib/utils";

export interface ProductDescriptionProps
  extends HTMLAttributes<HTMLDivElement> {
  description: string | null;
}

export default function ProductDescription({
  description,
  className,
  ...props
}: ProductDescriptionProps) {
  if (!description) {
    return null;
  }

  if (description.includes("</")) {
    return (
      <div
        {...props}
        className={cn("text-sm text-slate-600", className)}
        dangerouslySetInnerHTML={{
          __html: description.replace("\n", "<br>"),
        }}
      />
    );
  }

  return (
    <ReactMarkdown className={cn("prose prose-sm text-slate-600", className)}>
      {description}
    </ReactMarkdown>
  );
}
