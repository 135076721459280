import { Link } from "react-router-dom";
import { HTMLAttributes, useState } from "react";
import { Button } from "@/components/ui/button";
import { CustomerBusiness } from "avail-types";
import BusinessContext from "@/context/BusinessContext";
import { getUrlForAuthRedirect } from "@/lib/auth";
import { ALLOWS_SIGNUP } from "@/constants/services";
import Logo from "@/components/auth/Logo";
import { useAppContext } from "@/context/AppContext";
import AccountDropdownMenu from "@/components/navigation/AccountDropdownMenu";
import UserAvatar from "@/components/account/UserAvatar";
import { cn } from "@/lib/utils";
import { CONTAINER_PADDING } from "@/components/ui/container";

export default function GuestLayout({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  const [business, setBusiness] = useState<CustomerBusiness | null>(null);
  const { user } = useAppContext();

  return (
    <BusinessContext.Provider value={{ business, setBusiness }}>
      <div>
        <div className="fixed left-0 right-0 top-0 z-10 flex h-14 items-center gap-5 border-b bg-white/95 px-6 py-2 shadow-sm lg:bg-drawer lg:text-drawer-foreground">
          <div className="h-6 flex-grow">
            <Link to={business ? `/guest/${business.slug}` : "/"}>
              <Logo className="h-full fill-current" />
            </Link>
          </div>

          {ALLOWS_SIGNUP && (
            <>
              {user ? (
                <AccountDropdownMenu>
                  <button type="button">
                    <UserAvatar user={user} className="size-8" />
                  </button>
                </AccountDropdownMenu>
              ) : (
                <div className="hidden md:flex md:items-center">
                  <p className="mr-5">You are using Avail as a guest.</p>
                  <Button asChild size="sm" className="mr-2">
                    <Link to={getUrlForAuthRedirect("/register")}>Sign up</Link>
                  </Button>
                  <Button asChild size="sm" variant="ghost">
                    <Link to={getUrlForAuthRedirect("/login")}>Log in</Link>
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
        <div
          className={cn("py-8 pt-20", CONTAINER_PADDING, className)}
          {...props}
        >
          {children}
        </div>
      </div>
    </BusinessContext.Provider>
  );
}
