import { format, intlFormat, intlFormatDistance } from "date-fns";

export function formatDate(date: string | Date | null): string {
  if (!date) {
    return "-";
  }
  return intlFormat(new Date(date), {
    month: "short",
    year: "numeric",
    day: "numeric",
  });
}

export function formatDateShort(date: string | Date | null): string {
  if (!date) {
    return "-";
  }
  return format(new Date(date), "P");
}

export function formatDateTime(date: string | Date | null): string {
  if (!date) {
    return "-";
  }
  return intlFormat(new Date(date), {
    month: "short",
    year: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
}

export function formatDateTimeShort(date: string | Date | null): string {
  if (!date) {
    return "-";
  }
  return format(new Date(date), "Pp");
}

export function formatRelative(
  date: string | Date | null,
  options?: { style?: string },
) {
  if (!date) {
    return "-";
  }
  return intlFormatDistance(new Date(date), new Date(), options);
}
