import { ProjectItem, ProjectOption, ProjectSelection } from "avail-types";
import { ProjectOptionCardSkeleton } from "@/components/projects/ProjectOptionCard";
import ProjectOptionDialog from "@/components/projects/ProjectOptionDialog";
import { useSearchParams } from "react-router-dom";
import ProjectCardGrid from "@/components/projects/ProjectCardGrid";
import { Fragment, useRef, useState } from "react";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import ProjectOptionForm from "@/components/projects/ProjectOptionForm";
import ProjectOptionGroupForPrint from "@/components/projects/ProjectOptionGroupForPrint";
import { useUpdateProjectOption } from "@/api/project-options";
import { getOptionGroupingKey, projectOptionToPayload } from "@/lib/projects";
import groupBy from "lodash/groupBy";
import ProjectOptionGroup from "@/components/projects/ProjectOptionGroup";

export default function ProjectItemProvidedOptions({
  item,
  options,
  selections,
  optionsLoading,
}: {
  item: ProjectItem;
  options: ProjectOption[];
  selections: ProjectSelection[];
  optionsLoading: boolean;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [editingId, setEditingId] = useState<number>();
  const updateOption = useUpdateProjectOption(Number(editingId));
  const editingOption = options.find((o) => o.id === editingId);
  const viewedOptionId = Number(searchParams.get("option"));
  const groupedOptions = groupBy(options, getOptionGroupingKey);
  const onClose = () => setEditingId(undefined);
  const optionFormRef = useRef<{ isDirty: boolean } | null>(null);
  const viewedOption = options.find((option) => {
    return option.id === viewedOptionId;
  });

  return (
    <ProjectCardGrid>
      {optionsLoading && (
        <>
          <ProjectOptionCardSkeleton />
          <ProjectOptionCardSkeleton />
          <ProjectOptionCardSkeleton />
        </>
      )}

      {Object.entries(groupedOptions).map(([key, options]) => {
        return (
          <Fragment key={key}>
            <div className="print:hidden">
              <ProjectOptionGroup
                options={options}
                selections={selections}
                onSelectOption={(o) =>
                  setSearchParams((prev) => {
                    prev.set("option", String(o.id));
                    return prev;
                  })
                }
                onEditOption={(o) => setEditingId(o.id)}
              />
            </div>
            <div className="hidden print:block">
              <ProjectOptionGroupForPrint options={options} />
            </div>
          </Fragment>
        );
      })}

      <ProjectOptionDialog
        item={item}
        groupedOptions={
          viewedOption
            ? groupedOptions[getOptionGroupingKey(viewedOption)]
            : undefined
        }
        option={viewedOption}
        selection={selections.find((s) => s.option.id === viewedOption?.id)}
      />

      <Sheet
        open={editingId != undefined}
        onOpenChange={() => {
          if (
            optionFormRef.current?.isDirty &&
            !confirm("You have unsaved changed. Are you sure you want to exit?")
          ) {
            return;
          }
          onClose();
        }}
      >
        <SheetContent className="overflow-y-auto sm:max-w-xl">
          <SheetHeader className="mb-2">
            <SheetTitle>Edit Option</SheetTitle>
          </SheetHeader>
          {editingOption && (
            <ProjectOptionForm
              key={editingId}
              ref={optionFormRef}
              onSubmit={(values) =>
                updateOption.mutateAsync(values).then(onClose)
              }
              defaultValues={projectOptionToPayload(editingOption)}
            />
          )}
        </SheetContent>
      </Sheet>
    </ProjectCardGrid>
  );
}
