import Title from "@/components/ui/title";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Copy, ExternalLink, Plus } from "lucide-react";
import Toolbar from "@/components/ui/toolbar";
import { Input } from "@/components/ui/input";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Webstore } from "avail-types";
import { Skeleton } from "@/components/ui/skeleton";
import WebstoreDetails from "@/components/webstores/WebstoreDetails";
import WebstoreStatus from "@/components/webstores/WebstoreStatus";
import SearchInput from "@/components/ui/search-input";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Link, useSearchParams } from "react-router-dom";
import { useTitle } from "@/lib/title";
import { useToast } from "@/components/ui/use-toast";

export default function Webstores() {
  useTitle("Webstores");

  const { toast } = useToast();
  const [query, setQuery] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: webstores = [], isLoading } = useQuery(["webstores"], () =>
    axios.get<{ data: Webstore[] }>("/webstores").then(({ data }) => data.data),
  );

  const bucket = searchParams.get("bucket") || "live";
  const filteredWebstores = webstores
    .filter((w) => w.name.toLowerCase().includes(query.toLowerCase()))
    .filter((w) => {
      if (bucket === "live") {
        return w.status === "live";
      }
      if (bucket === "draft") {
        return w.status === "draft";
      }
      return true;
    });

  const handleCopy = (url: string) => () => {
    navigator.clipboard.writeText(url).then(() => {
      toast({ title: "Copied to clipboard", variant: "success" });
    });
  };

  const actions = (
    <>
      <SearchInput onChange={setQuery} placeholder="Search by name" />
      <Button asChild>
        <Link to="/webstores/create">
          <Plus className="mr-2 size-4" /> Request New
        </Link>
      </Button>
    </>
  );

  return (
    <div className="flex-1 space-y-6">
      <Toolbar actions={actions}>
        <Title>Webstores</Title>
      </Toolbar>

      <Tabs
        value={bucket}
        onValueChange={(v) =>
          setSearchParams((prev) => {
            prev.set("bucket", v);
            return prev;
          })
        }
      >
        <TabsList className="mb-6">
          <TabsTrigger value="live">Live</TabsTrigger>
          <TabsTrigger value="draft">Draft</TabsTrigger>
          <TabsTrigger value="all">All</TabsTrigger>
        </TabsList>
      </Tabs>

      <div className="grid gap-8 md:grid-cols-2 2xl:grid-cols-3">
        {isLoading &&
          Array.from({ length: 3 }).map((_, i) => (
            <Card key={i}>
              <CardHeader className="flex flex-row items-center">
                <Skeleton className="mr-4 size-10 rounded-full" />
                <Skeleton className="h-8 w-[250px]" />
              </CardHeader>
            </Card>
          ))}
        {filteredWebstores.map((store) => (
          <Card key={store.id} className="flex flex-col">
            <div className="flex flex-row items-center pl-6">
              <Avatar>
                <AvatarImage src={store.logo} className="object-cover" />
                <AvatarFallback className="bg-primary">
                  {store.name[0]}
                </AvatarFallback>
              </Avatar>
              <CardHeader className="truncate">
                <CardTitle className="truncate text-xl">{store.name}</CardTitle>
                <WebstoreStatus status={store.status} />
              </CardHeader>
            </div>
            <CardContent className="flex-grow">
              <div className="flex space-x-2">
                <Input value={store.url} readOnly className="flex-grow" />
                <Button variant="secondary" onClick={handleCopy(store.url)}>
                  <Copy className="size-4" />
                </Button>
                <Button variant="secondary" asChild>
                  <a href={store.url} target="_blank">
                    <ExternalLink className="size-4" />
                  </a>
                </Button>
              </div>
            </CardContent>
            <CardFooter>
              <WebstoreDetails webstore={store} />
            </CardFooter>
          </Card>
        ))}

        {!isLoading && filteredWebstores.length === 0 && (
          <p className="text-muted-foreground">
            That are no stores that match your query.
          </p>
        )}
      </div>
    </div>
  );
}
