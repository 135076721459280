import ReactMarkdown from "react-markdown";
import omit from "lodash/omit";

export default function Markdown({ children }: { children: string }) {
  return (
    <ReactMarkdown
      components={{
        a: (props) => (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            className="font-medium text-primary hover:underline"
            target="_blank"
            {...omit(props, "node")}
          />
        ),
      }}
    >
      {children}
    </ReactMarkdown>
  );
}
