import Title from "@/components/ui/title";
import Toolbar from "@/components/ui/toolbar";
import CartDrawer from "@/components/cart/CartDrawer";
import ShopContext, { ShopState } from "@/context/ShopContext";
import { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { usePortalShoppingMode } from "@/context/AppContext";
import { Steps, Step } from "@/components/ui/steps-horizontal";
import { useTitle } from "@/lib/title";

export default function Shop() {
  useTitle("Shop");

  const shoppingMode = usePortalShoppingMode();
  const [shopState, setShopState] = useState<ShopState>(() => {
    const fromSession = sessionStorage.getItem("shopState");
    if (fromSession) {
      return JSON.parse(fromSession);
    }
    return {};
  });
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const STEPS = [
    {
      title: "Find Product",
      href: "/shop/products",
      isComplete: !!shopState.product,
      subtitle: shopState.product?.name,
    },
    {
      title: "Choose Decoration",
      href: "/shop/decoration",
      isComplete: Boolean(shopState.designLayout || shopState.skipDecoration),
      subtitle:
        shopState.designLayout?.name ||
        (shopState.skipDecoration ? "Skip Decoration" : undefined),
    },
    {
      title: "Enter Quantities",
      href: "/shop/variants",
      isComplete: !!shopState.variants,
    },
  ];

  if (shoppingMode === "blank") {
    STEPS.splice(1, 1);
  }

  const currentStepIdx = STEPS.findIndex((s) => pathname.startsWith(s.href));
  const nextStepIdx = currentStepIdx + 1;

  const resetState = () => {
    sessionStorage.removeItem("shopState");
    setShopState({});
  };

  const updateShopState = (nextState: Partial<ShopState>) => {
    if (STEPS[nextStepIdx]) {
      navigate(STEPS[nextStepIdx]!.href);
    }
    setShopState((prev) => {
      const newState = { ...prev, ...nextState };
      sessionStorage.setItem("shopState", JSON.stringify(newState));
      return newState;
    });
  };

  const isCheckout = pathname.endsWith("checkout");

  return (
    <ShopContext.Provider value={{ ...shopState, updateShopState, resetState }}>
      <div className="mb-6 flex-1 space-y-4">
        <Toolbar actions={<CartDrawer checkoutUrl="/shop/checkout" />}>
          <Title>{isCheckout ? "Checkout" : "Shop"}</Title>
        </Toolbar>
      </div>

      {!isCheckout && (
        <Steps className="mb-6">
          {STEPS.map((step, idx) => (
            <Step
              key={idx}
              index={idx}
              title={step.title}
              status={
                step.isComplete
                  ? "complete"
                  : idx === currentStepIdx
                  ? "current"
                  : "upcoming"
              }
              onClick={step.isComplete ? () => navigate(step.href) : undefined}
              subtitle={step.subtitle}
              isLast={idx === STEPS.length - 1}
            />
          ))}
        </Steps>
      )}

      <Outlet />
    </ShopContext.Provider>
  );
}
